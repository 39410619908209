import React from 'react';
import { View } from 'react-big-calendar';

const CalendarContext = React.createContext<{
  setSelectedUsers: React.SetStateAction<any>;
  view: View;
}>({
  setSelectedUsers: null,
  view: null,
});

export const CalendarProvider = CalendarContext.Provider;
export const CalendarConsumer = CalendarContext.Consumer;

export default CalendarContext;
