import { useMediaQuery, useTheme } from '@material-ui/core';

type Breakpoint = number | "xs" | "sm" | "md" | "lg" | "xl"

export default function useIsMobile(breakpoint: Breakpoint = 'sm') {
  
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down(breakpoint));
  return isMobile;
}
