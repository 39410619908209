// https://codesandbox.io/s/material-ui-masonry-grid-9g3um?file=/index.js:158-273
import React, { Children } from 'react';
import Grid, { GridSpacing } from '@material-ui/core/Grid';
import useWidth from './useWidth';

/**
 * Returns an array of size `segmentCount`, where each element is an array
 * that is a subset of `array` obtained by walking `array` and placing each
 * element (shallow), in turn, in the next segment.
 * @example segment([1, 2, 3, 4], 3) => [[1, 4], [2], [3]]
 * @example segment([1, 2, 3, 4, 5], 3) => [[1, 4], [2, 5], [3]]
 * @example segment([1, 2], 3) => [[1], [2], []]
 * @example segment([1, 2], 2) => [[1], [2]]
 * @example segment([1], 1) => [[1]]
 */
const segment = (array: any[], segmentCount: number) => {
  const segmentedArray = [];
  for (let segmentIndex = 0; segmentIndex < segmentCount; segmentIndex += 1) {
    segmentedArray.push(
      array.filter((_, index) => segmentIndex === index % segmentCount)
    );
  }
  return segmentedArray;
};

/**
 * Arranges children into grid, dependent on current screen width
 * Children may be re-mounted when width changes (they're re-parented)
 */
const MasonryGrid = ({
  children,
  screenWidthToColumns,
  spacing = 3
}: {
  children: React.ReactNode;
  screenWidthToColumns: {
    xs: any[];
    sm: any[];
    md: any[];
    lg: any[];
    xl: any[];
  };
  spacing?: GridSpacing;
}) => {
  const width = useWidth();
  const columnWidths = screenWidthToColumns[width];
  const columnsOfGridItems = segment(
    Children.toArray(children),
    columnWidths.length
  );
  return (
    <Grid container spacing={spacing} direction="row">
      {columnsOfGridItems.map((column, columnIndex) => (
        <Grid item xs={columnWidths[columnIndex]} key={columnIndex.toString()}>
          <Grid container spacing={spacing}>
            {column.map((gridItem: React.ReactNode, gridItemIndex: number) => (
              <Grid item xs={12} key={gridItemIndex.toString()}>
                {gridItem}
              </Grid>
            ))}
          </Grid>
        </Grid>
      ))}
    </Grid>
  );
};

export default MasonryGrid;
