import React, { useCallback, useContext } from 'react';
import { useNavigate } from 'react-router';
import { Box, Divider, ListItem, Button } from '@material-ui/core';
import { LinkButton } from './SidebarNav';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import UserContext from 'state/UserContext';

const MobileSection = ({ classes }) => {
  const { user, setUser } = useContext(UserContext);
  const navigate = useNavigate();

  const logout = useCallback(() => {
    localStorage.removeItem('token');
    setUser(null);
    navigate('/login');
  }, [setUser, navigate]);

  return (
    <Box mt={1}>
      <Divider className={classes.mobileSectionDivider} />
      <ListItem className={classes.item} disableGutters>
        <LinkButton
          to={`/users/profile/${user.id}`}
          icon={<AccountCircleIcon />}
          title="Your Profile"
        />
      </ListItem>
      <ListItem className={classes.item} disableGutters>
        <LinkButton
          to={`/contact-support`}
          icon={<SupportAgentIcon />}
          title="Contact Support"
        />
      </ListItem>
      <Button className={classes.button} onClick={logout}>
        <div className={classes.icon}>
          <ExitToAppIcon />
        </div>
        Log out
      </Button>
    </Box>
  );
};

export default MobileSection;
