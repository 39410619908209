import React from 'react';
import { ResponsiveBar } from '@nivo/bar';
import {
  Card,
  CardContent,
  colors,
  LinearProgress,
  Typography,
  Divider,
  useTheme,
  CardMedia,
  Grid,
  makeStyles,
} from '@material-ui/core';
import { StatusValue } from 'api/jobApi';
import useIsMobile from 'theme/useIsMobile';
import './JobsByClientChart.scss';
import { getStatusColor } from 'util/statusColors';

const useStyles = makeStyles(() => ({
  image: {
    height: '60%',
    width: '60%',
    marginTop: '20%',
    // position: 'absolute',
    display: 'block',
    margin: 'auto',
    // marginLeft: 'auto',
    // marginRight: 'auto',
  },
}));

interface Props {
  data: any;
  label?: string;
}

const barColors = {
  [StatusValue.NOT_STARTED]: getStatusColor(StatusValue.NOT_STARTED, 200),
  [StatusValue.IN_PROGRESS]: getStatusColor(StatusValue.IN_PROGRESS, 700),
  [StatusValue.ON_HOLD]: getStatusColor(StatusValue.ON_HOLD, 300),
  [StatusValue.APPROVAL_PENDING]: getStatusColor(
    StatusValue.APPROVAL_PENDING,
    700
  ),
  [StatusValue.TO_BE_INVOICED]: getStatusColor(StatusValue.TO_BE_INVOICED, 600),
  [StatusValue.COMPLETED]: getStatusColor(StatusValue.COMPLETED, 800),
  [StatusValue.CANCELLED]: getStatusColor(StatusValue.CANCELLED, 700),
};

const desktopMargins = { top: 20, right: 50, bottom: 50, left: 140 };
const mobileMargins = { top: 160, right: 10, bottom: 100, left: 40 };

const JobsByClientChart = ({ label, data = [] }: Props) => {
  const classes = useStyles();
  const isMobile = useIsMobile('xs');
  const theme = useTheme();
  const chartMargins = isMobile ? mobileMargins : desktopMargins;
  const responsiveLayout = isMobile ? 'vertical' : 'horizontal';
  const responsiveTickRotation = isMobile ? -90 : 0;
  const jobNums = data?.map((client) => {
    return (
      parseInt(client[StatusValue.NOT_STARTED]) +
      parseInt(client[StatusValue.IN_PROGRESS]) +
      parseInt(client[StatusValue.ON_HOLD]) +
      parseInt(client[StatusValue.APPROVAL_PENDING]) +
      parseInt(client[StatusValue.TO_BE_INVOICED]) +
      parseInt(client[StatusValue.COMPLETED]) +
      parseInt(client[StatusValue.CANCELLED])
    );
  });
  const maxValue = Math.max(...jobNums);

  return (
    <Card
      style={{
        height: isMobile ? '600px' : '500px',
        borderRadius: '12px',
      }}>
      {!data && <LinearProgress />}
      <CardContent style={{ height: '90%' }}>
        <Typography variant="h4" gutterBottom>
          {label ?? 'Jobs by Client'}
        </Typography>
        <Divider />
        {data?.length == 0 ? (
          <img
            alt="blank canvas"
            src="/images/undraw_blank_canvas_3rbb.svg"
            className={classes.image}
          />
        ) : (
          <ResponsiveBar
            data={data}
            keys={[
              'Not started',
              'In progress',
              'On hold',
              'Approval pending',
              'To be invoiced',
              'Completed',
              'Cancelled',
            ]}
            indexBy="companyName"
            layout={responsiveLayout}
            margin={chartMargins}
            padding={0.6}
            innerPadding={2}
            valueScale={{ type: 'linear' }}
            indexScale={{ type: 'band', round: true }}
            colors={(d) => barColors[d.id]}
            fill={[
              {
                match: { id: StatusValue.NOT_STARTED },
                id: 'notStartedLines',
              },
              { match: { id: StatusValue.IN_PROGRESS }, id: 'inProgressLines' },
              {
                match: { id: StatusValue.COMPLETED },
                id: 'completedLines',
              },
              {
                match: { id: StatusValue.ON_HOLD },
                id: 'onHoldLines',
              },
              {
                match: { id: StatusValue.APPROVAL_PENDING },
                id: 'approvalPendingLines',
              },
              {
                match: { id: StatusValue.TO_BE_INVOICED },
                id: 'toBeInvoicedLines',
              },
              {
                match: { id: StatusValue.CANCELLED },
                id: 'cancelledLines',
              },
            ]}
            borderRadius={2}
            borderColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
            axisTop={null}
            axisRight={null}
            axisBottom={{
              tickSize: 10,
              tickPadding: 5,
              tickValues: maxValue,
              tickRotation: responsiveTickRotation,
              legend: 'number of jobs',
              legendPosition: 'middle',
              legendOffset: 40,
            }}
            axisLeft={{
              tickSize: 5,
              tickPadding: 5,
              tickRotation: -40,
              legend: 'client',
              legendPosition: 'middle',
              legendOffset: -110,
            }}
            enableGridY={true}
            enableGridX={!isMobile}
            labelSkipWidth={12}
            labelSkipHeight={12}
            labelTextColor="white"
            legends={
              isMobile
                ? [
                    {
                      dataFrom: 'keys',
                      anchor: 'top-left',
                      direction: 'column',
                      justify: false,
                      translateX: -25,
                      translateY: -130,
                      itemsSpacing: 0,
                      itemWidth: 50,
                      itemHeight: 20,
                      itemDirection: 'left-to-right',
                      itemOpacity: 0.85,
                      symbolSize: 12,
                      symbolShape: ({ x, y, size, fill }) => (
                        <rect
                          x={x}
                          y={y}
                          width={size}
                          height={size}
                          fill={fill}
                          rx={theme.shape.borderRadius}
                          ry={theme.shape.borderRadius}
                        />
                      ),
                      effects: [
                        {
                          on: 'hover',
                          style: {
                            itemOpacity: 1,
                          },
                        },
                      ],
                    },
                  ]
                : []
            }
            animate={true}
            motionStiffness={30}
            motionDamping={5}
          />
        )}
      </CardContent>
    </Card>
  );
};

export default JobsByClientChart;
