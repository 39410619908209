import React from 'react';
import { User } from 'api/userApi';

export interface UserWithLoadingStatus extends User {
  status: 'idle' | 'error' | 'loading' | 'success';
}

interface IUserContext {
  user: UserWithLoadingStatus;
  setUser: React.Dispatch<React.SetStateAction<UserWithLoadingStatus>>;
}

const UserContext = React.createContext<IUserContext>({
  user: {
    status: 'idle',
  },
  setUser: () => null,
});

export default UserContext;
