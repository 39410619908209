import React, { useState, useEffect, useRef, useContext, useMemo } from 'react';
import {
  Calendar as RBCalendar,
  dateFnsLocalizer,
  View,
} from 'react-big-calendar';
import {
  parse,
  format,
  startOfWeek,
  startOfMonth,
  getMonth,
  getDay,
  getYear,
  endOfMonth,
  endOfWeek,
  startOfDay,
  endOfDay,
  parseISO,
  add,
} from 'date-fns';
import { makeStyles, Typography, Box } from '@material-ui/core';
import EventCard, { getEventProps } from './EventCard';
import { SlotInfo } from './interfaces';
import { User } from 'api/userApi';
import { CalendarProvider } from './CalendarContext';
import { CalendarAPI } from 'api';
import { useQuery } from '@tanstack/react-query';
import {
  prefetchAndCacheMonthEvents,
  prefetchAndCacheDayEvents,
  prefetchAndCacheWeekEvents,
} from './calendarCache';
import './Calendar.scss';
import { ScheduledTask } from 'api/jobApi';
import CalendarToolbar from './CalendarToolbar';
import CalendarEventDrawer from './Drawer/CalendarEventDrawer';
import UserAvatar from 'components/Users/UserAvatar';
import UserContext from 'state/UserContext';

const locales = {
  'en-AU': require('date-fns/locale/en-AU'),
};
const localizer = dateFnsLocalizer({
  format,
  parse,
  startOfWeek,
  getDay,
  locales,
});
const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(1),
  },
  avatar: {
    marginRight: theme.spacing(1),
  },
}));

interface Props {
  showToolbar?: boolean;
  defaultView?: View;
  min?: Date;
  max?: Date;
  step?: number;
}

const today = new Date();
const minTime = new Date(
  today.getFullYear(),
  today.getMonth(),
  today.getDate(),
  6
);

export interface CalendarEvent extends Omit<ScheduledTask, 'start' | 'end'> {
  start: Date;
  end: Date;
}

interface DateRange {
  start: string | Date;
  end: string | Date;
}

const Calendar = ({ showToolbar, defaultView, min, max, step }: Props) => {
  const classes = useStyles();
  const originalEvents = useRef([]);
  const { user } = useContext(UserContext);
  const processedEvents = useRef<CalendarEvent[]>();
  const [events, setEvents] = useState<CalendarEvent[]>([]);
  const [view, setView] = useState<View>(defaultView ?? 'week');
  const [drawerState, setDrawerState] = useState<{
    open: boolean;
    slot?: SlotInfo;
    event?: CalendarEvent;
  }>({
    open: false,
  });

  const [range, setRange] = useState<DateRange>(() => {
    let start: Date, end: Date;
    const date = new Date();
    switch (defaultView) {
      case 'day':
        start = startOfDay(date);
        end = endOfDay(date);
        break;
      case 'week':
        start = startOfWeek(date);
        end = endOfWeek(date);
        break;
      case 'month':
        start = startOfWeek(startOfMonth(date));
        end = endOfWeek(endOfMonth(date));
        break;
      default:
        start = date;
        end = date;
        break;
    }
    return { start, end };
  });

  function onRangeChange(newRange: Date[] | DateRange, newView: View) {
    if (newView) {
      setView(newView);
    }
    switch (newView ?? view) {
      case 'day':
        const date = (newRange as Date[])[0];
        setRange({
          start: startOfDay(date),
          end: endOfDay(date),
        });
        prefetchAndCacheDayEvents(date, 2);
        break;

      case 'week':
        setRange({
          start: startOfDay((newRange as Date[])[0]),
          end: endOfDay((newRange as Date[])[(newRange as Date[]).length - 1]),
        });
        prefetchAndCacheWeekEvents((newRange as Date[])[0], 1);
        break;

      case 'month':
        const { start, end } = newRange as any;
        setRange({
          start,
          end,
        });
        const month = getMonth(add(start, { weeks: 1 }));
        prefetchAndCacheMonthEvents(getYear(start), month, 2);
        break;
      case 'agenda':
        setRange({
          start: (newRange as any).start,
          end: (newRange as any).end,
        });
        break;
      default:
        // initial load - use current month
        break;
    }
  }

  const { isFetching, data: tasks } = useQuery(
    ['calendar-events', range.start, range.end],
    () => CalendarAPI.listEvents(range.start, range.end)
  );

  const [selectedUsers, setSelectedUsers] = useState<User[]>();

  const processEventsAssignedToMultipleUsers = (events: CalendarEvent[]) => {
    let newEvents: CalendarEvent[] = [];
    for (let i = 0; i < events.length; i++) {
      const event = events[i];
      if (
        !event.taskAssignments?.length ||
        event.taskAssignments.length === 1
      ) {
        newEvents.push(event);
        continue;
      }

      // Create duplicates to show up on all assignees' calendars
      const dup = event.taskAssignments.map((u) => {
        let e: any = {};
        Object.assign(e, event);
        e.taskAssignments = [];
        e.taskAssignments.push(u);
        return e;
      });
      newEvents.push(...dup);
    }
    processedEvents.current = newEvents;
    return newEvents;
  };

  useEffect(() => {
    if (!tasks) {
      return;
    }

    const eventItems = tasks.map(({ start, end, ...rest }) => ({
      ...rest,
      start: parseISO(start),
      end: parseISO(end),
    }));

    setEvents(eventItems);
    originalEvents.current = eventItems;

    if (!selectedUsers?.length || selectedUsers?.length === 0) {
      return;
    }

    const processedEventsItems =
      processEventsAssignedToMultipleUsers(eventItems);
    if (processedEventsItems && processedEventsItems?.length !== 0) {
      setEvents(processedEventsItems);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedUsers, view, tasks]);

  const handleSlotSelect = (slot: SlotInfo) => {
    setDrawerState({
      open: true,
      slot,
    });
  };

  const handleEventSelect = (event: CalendarEvent, _) => {
    setDrawerState({
      open: true,
      event,
    });
  };

  const renderEvents = useMemo(() => {
    if (selectedUsers?.length > 0) {
      if (view === 'month' || view === 'agenda') {
        const filteredEvents = events.filter((event) => {
          const eventUserIds = event.taskAssignments.map(
            (assignment) => assignment.user.id
          );
          return selectedUsers.some((user) => eventUserIds.includes(user.id));
        });
        return filteredEvents;
      } else {
        return events;
      }
    } else {
      return events;
    }
  }, [selectedUsers, view, events]);

  function getResourceTitle(u: User) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" m={3}>
        <UserAvatar
          user={u}
          size={20}
          fontSize={'10px'}
          className={classes.avatar}
        />
        <Typography variant="body1" component="span">
          {u.firstName} {u.lastName}
        </Typography>
      </Box>
    );
  }

  return (
    <CalendarProvider
      value={{
        setSelectedUsers,
        view,
      }}>
      <div className={!Boolean(tasks) && isFetching ? 'loading' : null}>
        <RBCalendar
          popup
          selectable={user.role !== 'Technician'}
          toolbar={showToolbar ?? true}
          localizer={localizer}
          events={renderEvents}
          resourceIdAccessor={(u: User) => u.id}
          resourceAccessor={(ev: CalendarEvent) => {
            if (ev.taskAssignments.length) {
              return ev.taskAssignments[0].userId;
            }
          }}
          resources={selectedUsers?.length > 0 ? selectedUsers : null}
          resourceTitleAccessor={getResourceTitle}
          onRangeChange={onRangeChange}
          startAccessor="start"
          endAccessor="end"
          scrollToTime={min ?? minTime}
          min={min}
          onView={(view) => setView(view)}
          max={max}
          step={step ?? 30}
          style={{ height: '80vh' }}
          drilldownView="day"
          defaultView={view}
          eventPropGetter={getEventProps}
          onSelectEvent={handleEventSelect}
          onSelectSlot={handleSlotSelect}
          showMultiDayTimes
          formats={{
            agendaHeaderFormat: ({ start, end }) => {
              return `${format(start, 'dd MMM yyyy')} - ${format(
                end,
                'dd MMM yyyy'
              )}`;
            },
            dayRangeHeaderFormat: ({ start, end }) => {
              return `${format(start, 'dd MMM yyyy')} - ${format(
                end,
                'dd MMM yyyy'
              )}`;
            },
            dayHeaderFormat: (date: Date) => {
              return format(date, 'dd MMM yyyy');
            },
          }}
          components={{
            toolbar: CalendarToolbar,
            event: EventCard,
          }}
          messages={{
            noEventsInRange: isFetching
              ? 'Loading...'
              : 'No events in this range',
          }}
        />
      </div>
      <CalendarEventDrawer
        {...drawerState}
        tasks={tasks}
        onClose={() => setDrawerState({ open: false })}
      />
    </CalendarProvider>
  );
};

export default Calendar;
