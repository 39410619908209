export const ACCEPTED_FILE_TYPES = [
  // Images
  'image/gif',
  'image/jpeg',
  'image/tiff',
  'image/png',
  'image/svg+xml',

  // Text
  'text/csv',
  'text/plain',
  'text/calendar',

  // Compressed
  'application/zip',
  'application/x-7z-compressed',
  'application/x-tar',
  'application/vnd.rar',

  'application/pdf',
  'application/json',
  'application/epub+zip',
  
  'application/msword',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'application/vnd.ms-powerpoint',
  'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  'application/vnd.ms-excel',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
];
