import React from 'react';
import clsx from 'clsx';
import DeleteIcon from '@material-ui/icons/Delete';
import { Button, ButtonProps, makeStyles } from '@material-ui/core';

interface Props extends ButtonProps {}

const useStyles = makeStyles((theme) => ({
  root: {
    color: theme.palette.common.white,
    backgroundColor: theme.palette.error.main,
    '&:hover': {
      color: theme.palette.common.white,
      backgroundColor: theme.palette.error.dark,
    },
    '&:disabled': {
      color: theme.palette.common.white,
      backgroundColor: theme.palette.grey[500],
    },
  },
}));

const DeleteButton = (props: Props) => {
  const classes = useStyles();
  return (
    <Button
      {...props}
      endIcon={props.endIcon ?? <DeleteIcon />}
      disableElevation
      className={clsx(props.className, classes.root)}>
      {props.children}
    </Button>
  );
};

export default DeleteButton;
