import Axios from 'axios';
import {
  TimesheetCreateData,
  TimesheetUpdateData,
} from './timesheet.interface';
import { Timesheet } from './timesheet.response';

export async function createTimesheet(requestData: TimesheetCreateData) {
  const res = await Axios.post('/api/v2/timesheets', requestData);

  return res.data as Timesheet;
}

export async function updateTimeSheet(
  requestData: Partial<TimesheetUpdateData>
) {
  if (!requestData.id) {
    throw new Error('No timesheet id provided');
  }
  const res = await Axios.put(
    `/api/v2/timesheets/${requestData.id}`,
    requestData
  );

  return res.data;
}

export async function getTimesheetsByJobIdAndInspectionId(
  jobId: string,
  inspectionId: string
) {
  const res = await Axios.get(
    `/api/v2/timesheets/job/${jobId}/inspection/${inspectionId}`
  );

  return res.data as Timesheet[];
}

export async function deleteTimesheet(timesheetId: string) {
  const res = await Axios.delete(`/api/v2/timesheets/${timesheetId}`);

  return res.data as Timesheet;
}
