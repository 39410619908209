import { colors, createMuiTheme } from '@material-ui/core';
const theme = createMuiTheme();
export default {
  MUIDataTable: {
    paper: {
      [theme.breakpoints.down('sm')]: {
        backgroundColor: 'transparent',
        boxShadow: 'none',
      },
    },
  },
  MUIDataTableToolbar: {
    root: {
      backgroundColor: colors.grey[50],
      [theme.breakpoints.down('md')]: {
        padding: theme.spacing(2),
      },
      [theme.breakpoints.down('sm')]: {
        paddingTop: 0,
        backgroundColor: 'transparent',
      },
    },
  },
  MUIDataTableSearch: {
    searchText: {
      [theme.breakpoints.down('sm')]: {
        flex: '1 0',
      },
    },
  },
  MUIDataTableHeadCell: {
    fixedHeader: {
      backgroundColor: colors.grey[50],
    },
  },
  MUIDataTableSelectCell: {
    headerCell: {
      backgroundColor: colors.grey[50],
    },
  },
  MUIDataTableBodyCell: {
    stackedHeader: {
      [theme.breakpoints.down('sm')]: {
        color: 'gray',
        paddingBottom: theme.spacing(0.1),
      },
    },
    stackedCommon: {
      [theme.breakpoints.down('sm')]: {
        padding: 0,
        width: 'calc(100%)',
      },
      [theme.breakpoints.down('md')]: {
        fontSize: '13px !important',
      },
    },
  },
  MUIDataTableBodyRow: {
    responsiveStacked: {
      [theme.breakpoints.down('sm')]: {
        display: 'block',
        border: 'none !important',
        backgroundColor: theme.palette.background.paper,
        margin: `1rem 5px`,
        padding: theme.spacing(1),
        borderRadius: theme.shape.borderRadius,
        boxShadow: theme.shadows[2],
      },
    },
  },
  MUIDataTablePagination: {
    tableCellContainer: {
      [theme.breakpoints.down('sm')]: {
        borderBottom: 'none',
      },
    },
  },
} as const;
