import React, { useState, useEffect } from 'react';
import { Snackbar } from '@material-ui/core';
import { Button } from '@mui/material';

const OfflineWarning = () => {
  const [showOfflineWarning, setShowOfflineWarning] = useState(false);

  useEffect(() => {
    const handleOnlineStatusChange = () => {
      setShowOfflineWarning(!navigator.onLine);
    };

    // Add event listeners to listen for online/offline status changes
    window.addEventListener('online', handleOnlineStatusChange);
    window.addEventListener('offline', handleOnlineStatusChange);

    console.log('showOfflineWarning', showOfflineWarning);

    return () => {
      // Remove event listeners when the component unmounts
      window.removeEventListener('online', handleOnlineStatusChange);
      window.removeEventListener('offline', handleOnlineStatusChange);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Snackbar
      open={showOfflineWarning}
      message="You are currently offline."
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      action={
        <Button
          color="inherit"
          size="small"
          onClick={() => setShowOfflineWarning(false)}>
          Dismiss
        </Button>
      }
    />
  );
};

export default OfflineWarning;
