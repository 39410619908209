import * as colors from '@material-ui/core/colors';

export default function stringToColor(s: string, strength: number = 100) {
  if (!s) {
    return colors.blue[strength];
  }
  const v =
    Array.from(s)
      .map((c) => c.charCodeAt(0))
      .reduce((a, b) => a + b) % 9;

  switch (v) {
    case 1:
      return colors.red[strength];
    case 2:
      return colors.pink[strength];
    case 3:
      return colors.indigo[strength];
    case 4:
      return colors.purple[strength];
    case 5:
      return colors.teal[strength];
    case 6:
      return colors.green[strength];
    case 7:
      return colors.orange[strength];
    case 8:
      return colors.brown[strength];
    case 9:
      return colors.blueGrey[strength];
    default:
      return colors.blue[strength];
  }
}
