import React from 'react';
import {
  Grid,
  makeStyles,
  useTheme,
  useMediaQuery,
  Card,
  CardContent,
  CardHeader,
  LinearProgress,
} from '@material-ui/core';
import Logo from 'assets/logos/logo-color.png';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundImage: 'url("/images/bg-asset-4.png")',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundAttachment: 'fixed',
    minHeight: 'calc(100vh - 50px)',
    [theme.breakpoints.down('sm')]: {
      backgroundColor: theme.palette.background.default,
    },
  },
  content: {
    overflow: 'auto',
    height: '100%',
  },
  grid: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    paddingBottom: theme.spacing(4),
  },
  card: {
    marginTop: '10vh',
    [theme.breakpoints.down('sm')]: {
      backgroundColor: theme.palette.background.default,
    },
  },
  cardHeader: {
    paddingLeft: 0,
    paddingBottom: theme.spacing(4),
  },
  cardContent: {
    padding: theme.spacing(4),
    paddingTop: 0,
  },
  logo: {
    width: '200px',
    marginTop: theme.spacing(4),
  },
  input: {
    marginTop: theme.spacing(3),
  },
  submitBtn: {
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(2),
  },
  spinner: {
    color: 'inherit',
    marginLeft: theme.spacing(2),
  },
  errorAlert: {
    marginBottom: theme.spacing(3),
  },
}));

interface Props {
  children: React.ReactNode;
  title: string;
  subheader: string;
  isLoading?: boolean;
}

const AuthPageContainer = ({
  title,
  subheader,
  isLoading,
  children,
}: Props) => {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <div className={classes.root}>
      <div className={classes.content}>
        <Grid className={classes.grid} container justify="center">
          <Grid item xs={11} sm={8} md={6} lg={4} xl={3}>
            <Card elevation={isMobile ? 0 : 2} className={classes.card}>
              {!isMobile && isLoading && <LinearProgress />}
              <CardContent className={classes.cardContent}>
                <img className={classes.logo} src={Logo} alt="Restorize Logo" />
                <CardHeader
                  className={classes.cardHeader}
                  titleTypographyProps={{ variant: 'h3' }}
                  title={title}
                  subheader={subheader}
                />
                {children}
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default AuthPageContainer;
