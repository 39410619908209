import React, { useState, useContext, useEffect } from 'react';
import { ToolbarProps, View } from 'react-big-calendar';
import {
  Button,
  Typography,
  Toolbar,
  InputLabel,
  Select,
  MenuItem,
  makeStyles,
  FormControl,
  Box,
  IconButton,
  useTheme,
  useMediaQuery,
} from '@material-ui/core';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { useForm } from 'react-hook-form';
import CalendarContext from './CalendarContext';
import AuthContainer from 'components/AuthContainer/AuthContainer';
import CalendarMultiUserAutocomplete from './CalendarMultiUserAutocomplete';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { CalendarAPI } from 'api';
import { useSnackbar } from 'notistack';
import { userHasAccess } from 'models/Role';
import UserContext from 'state/UserContext';

const useStyles = makeStyles((theme) => ({
  toolbar: {
    marginBottom: theme.spacing(3),
  },
  formControl: {
    minWidth: 120,
    margin: theme.spacing(1),
    marginLeft: 'auto',
  },
  todayBtn: {
    marginRight: theme.spacing(2),
  },
  label: {
    padding: theme.spacing(1),
  },
  userSelectContainer: {
    boxSizing: 'content-box!important' as any,
    width: '100%',
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
  buttonContainer: {
    marginLeft: 10,
  },
}));

const CalendarToolbar = (props: ToolbarProps) => {
  const { user } = useContext(UserContext);
  const useAccess = userHasAccess(user, [
    'Administrator',
    'ProjectManager',
    'Supervisor',
  ]);

  const { data: pinnedUsers } = useQuery(
    ['list-pinned-calendar-users'],
    CalendarAPI.listPinnedCalendarUsers,
    { staleTime: 30 * 60 * 1000, enabled: useAccess }
  );
  const { onNavigate, onView } = props;
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [view, setView] = useState<View>(props.view);
  const [selectedUsers, setSelectedUsers] = useState<any[]>([]);
  const calendarCtx = useContext(CalendarContext);

  const {
    control,
    watch,
    formState: { isDirty },
    reset,
  } = useForm();
  const watchSelectedUsers = watch('selectedUsers');

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    setSelectedUsers(pinnedUsers);
  }, [pinnedUsers]);

  useEffect(() => {
    if (isDirty) {
      calendarCtx.setSelectedUsers(watchSelectedUsers);
    } else {
      calendarCtx.setSelectedUsers(pinnedUsers);
    }
  }, [calendarCtx, pinnedUsers, watchSelectedUsers, isDirty]);

  const queryClient = useQueryClient();

  const { mutate: addOrUpdateMutation } = useMutation(
    CalendarAPI.addOrUpdatePinnedCalendarUsers,
    {
      onSuccess: async (newPinnedUsers) => {
        enqueueSnackbar('Successfully saved users', { variant: 'success' });
        await queryClient.invalidateQueries(['list-pinned-calendar-users']);
        reset({ selectedUsers: newPinnedUsers });
      },
      onError: () => {
        enqueueSnackbar('Failed to save users', { variant: 'error' });
      },
    }
  );

  return (
    <div>
      <Toolbar variant="dense" disableGutters>
        {!isMobile && (
          <Button
            variant="outlined"
            color="primary"
            className={classes.todayBtn}
            onClick={() => onNavigate('TODAY')}>
            TODAY
          </Button>
        )}
        <IconButton size="medium" onClick={() => onNavigate('PREV')}>
          <ChevronLeftIcon />
        </IconButton>
        <Typography variant={isMobile ? 'h6' : 'h4'} className={classes.label}>
          {props?.label}
        </Typography>
        <IconButton size="medium" onClick={() => onNavigate('NEXT')}>
          <ChevronRightIcon />
        </IconButton>
        <FormControl className={classes.formControl}>
          <InputLabel htmlFor="select-calendar-view">View</InputLabel>
          <Select
            id="select-calendar-view"
            value={view}
            label="Select View"
            variant="standard"
            onChange={(event) => {
              const view = event.target.value as View;
              setView(view);
              onView(view);
            }}>
            <MenuItem value={'month'}>Month</MenuItem>
            <MenuItem value={'week'}>Week</MenuItem>
            <MenuItem value={'day'}>Day</MenuItem>
            <MenuItem value={'agenda'}>Agenda</MenuItem>
          </Select>
        </FormControl>
      </Toolbar>
      <AuthContainer
        restrictTo={['Administrator', 'ProjectManager', 'Supervisor']}
        noAccessComponent={() => <Box my={3} />}>
        <Toolbar variant="regular" disableGutters className={classes.toolbar}>
          <Box my={2} className={classes.userSelectContainer}>
            <CalendarMultiUserAutocomplete
              control={control}
              name="selectedUsers"
              rules={{}}
              errors={{}}
              label="View individual/combined calendars"
              onChange={(users) => {
                setSelectedUsers(users);
              }}
              selectedUsers={selectedUsers}
            />
          </Box>
          {isDirty && (
            <div className={classes.buttonContainer}>
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  const userIds = selectedUsers.map((u) => u.id);
                  addOrUpdateMutation(userIds);
                }}>
                Save
              </Button>
            </div>
          )}
        </Toolbar>
      </AuthContainer>
    </div>
  );
};

export default CalendarToolbar;
