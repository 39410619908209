import React from 'react';
import clsx from 'clsx';
import SidebarNav from './SidebarNav';
import { makeStyles } from '@material-ui/core/styles';
import {
  Drawer,
  DrawerProps,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import { sidebarItems } from './SidebarItems';

const useStyles = makeStyles((theme) => ({
  drawer: {
    width: 240,
    [theme.breakpoints.up('lg')]: {
      marginTop: 64,
      height: 'calc(100% - 64px)',
    },
  },
  root: {
    backgroundColor: 'white',
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    paddingLeft: theme.spacing(1.5),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  divider: {
    margin: theme.spacing(2, 0),
  },
  nav: {
    marginBottom: theme.spacing(2),
    overflowY: 'hidden',
  },
}));

interface Props {
  className?: string;
  onClose: () => void;
  open: boolean;
  variant: DrawerProps['variant'];
}

const Sidebar = React.memo((props: Props) => {
  const { open, variant, onClose, className, ...rest } = props;
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'), {
    defaultMatches: true,
  });

  return (
    <Drawer
      anchor={isMobile ? 'right' : 'left'}
      classes={{ paper: classes.drawer }}
      onClose={onClose}
      open={open}
      PaperProps={{ elevation: 2 }}
      variant={variant}>
      <div {...rest} className={clsx(classes.root, className)}>
        <SidebarNav
          className={classes.nav}
          items={sidebarItems}
          onClose={onClose}
        />
      </div>
    </Drawer>
  );
});

export default Sidebar;
