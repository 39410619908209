import React, { useEffect, useState } from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import theme from './theme';
import AppRoutes from './Routes';
import UserContext, { UserWithLoadingStatus } from 'state/UserContext';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from '@material-ui/core/styles';
import { useQuery } from '@tanstack/react-query';
import { currentUserDetails } from 'api/userApi';
import { SnackbarProvider } from 'notistack';
import ErrorBoundary from 'util/ErrorBoundary';

const App = () => {
  const [user, setUser] = useState<UserWithLoadingStatus>({ status: 'idle' });
  const { status, data: userData } = useQuery(
    ['current-user'],
    currentUserDetails,
    { retry: 0 }
  );

  useEffect(() => {
    setUser({ status, ...userData });
  }, [status, userData]);

  return (
    <ErrorBoundary>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <UserContext.Provider value={{ user, setUser }}>
          <BrowserRouter>
            <SnackbarProvider maxSnack={3} autoHideDuration={2500}>
              <AppRoutes />
            </SnackbarProvider>
          </BrowserRouter>
        </UserContext.Provider>
      </ThemeProvider>
    </ErrorBoundary>
  );
};

export default App;
