import React from 'react';
import stringToColor from 'util/stringToColor';
import { Avatar, Tooltip, Typography } from '@material-ui/core';
import { User } from 'api/userApi';

interface Props {
  user: User;
  size: number | string;
  fontSize?: number | string;
  className?: string;
  style?: any;
  disableTooltip?: boolean;
}

const UserAvatar = React.memo(
  ({
    user,
    size,
    fontSize,
    className,
    style,
    disableTooltip = false,
  }: Props) => {
    const body = user?.dp ? (
      <Avatar
        src={user.dp}
        style={{ height: size, width: size }}
        className={className}
      />
    ) : (
      <Avatar
        style={{
          height: size,
          width: size,
          backgroundColor: stringToColor(user?.firstName, 800),
          fontSize: fontSize ?? '1rem',
          ...style,
        }}
        className={className}>
        {user?.firstName[0]?.toUpperCase()}
        {user?.lastName[0]?.toUpperCase()}
      </Avatar>
    );

    if (disableTooltip) {
      return body;
    }

    return (
      <Tooltip
        title={
          <Typography variant="body2" color="inherit">
            {user?.firstName} {user?.lastName}
          </Typography>
        }>
        {body}
      </Tooltip>
    );
  }
);

export default UserAvatar;
