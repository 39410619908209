import React, { useContext } from 'react';
import { LinearProgress, makeStyles } from '@material-ui/core';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { OverviewAPI } from 'api';
import TitleBar from 'components/TitleBar/TitleBar';
import HomeIcon from '@material-ui/icons/Home';
import UserContext from 'state/UserContext';
import AdminGridItems from './RoleBasedGrids/AdminGridItems';
import PMGridItems from './RoleBasedGrids/PMGridItems';
import TechnicianGridItems from './RoleBasedGrids/TechnicianGridItems';
import PageRefreshButton from 'components/common/PageRefreshButton';
import { endOfDay, endOfWeek, startOfDay, startOfWeek } from 'date-fns';
import {
  OrganizationPricing,
  getEstimateBilling,
  getOrgStorageUsage,
  getOrgUsage,
} from 'api/organizationApi';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(5),
    paddingTop: theme.spacing(5),
    [theme.breakpoints.down('sm')]: {
      paddingTop: theme.spacing(3),
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
  },
}));

const breadcrumbs = [
  {
    icon: <HomeIcon />,
    path: '/',
  },
];

const Dashboard = () => {
  const classes = useStyles();
  const { user } = useContext(UserContext);
  const queryClient = useQueryClient();
  const { isFetching, data, refetch } = useQuery(
    ['overview'],
    OverviewAPI.fetchOverview
  );

  //prefetching org usage
  const { data: orgUsageData } = useQuery(['org-usage'], getOrgUsage, {
    staleTime: 0,
  });
  useQuery(['org-storage-usage'], getOrgStorageUsage, { staleTime: 0 });
  useQuery(['estimate-billing'], getEstimateBilling, {
    staleTime: 0,
    enabled:
      orgUsageData?.pricingPlan === OrganizationPricing.Enum.PAYG &&
      user.role === 'Administrator',
  });

  function refreshData() {
    refetch();
    console.log('Yo');
    queryClient.invalidateQueries([
      'calendar-events',
      startOfDay(new Date()),
      endOfDay(new Date()),
    ]);
    queryClient.invalidateQueries([
      'scheduled-tasks',
      {
        tasksForUser: true,
        start: startOfWeek(new Date()).toISOString(),
        end: endOfWeek(new Date()).toISOString(),
      },
    ]);
  }

  function getGridItemsForUser() {
    switch (user.role) {
      case 'Administrator':
        return <AdminGridItems data={data} />;
      case 'ProjectManager':
        return <PMGridItems data={data} />;
      case 'Supervisor':
        return <PMGridItems data={data} />;
      case 'Technician':
        return <TechnicianGridItems overviewData={data} />;
      default:
        return null;
    }
  }

  return (
    <>
      <TitleBar
        breadcrumbs={breadcrumbs}
        title="Your Dashboard"
        overline={`Welcome Back, ${user.firstName}`}
        actions={<PageRefreshButton refresh={refreshData} />}
      />
      {isFetching && <LinearProgress />}
      <div className={classes.root}>{getGridItemsForUser()}</div>
    </>
  );
};

export default Dashboard;
