import React from 'react';
import {
  Card,
  CardContent,
  Typography,
  makeStyles,
  Grid,
  Avatar,
  CircularProgress,
  SvgIconTypeMap,
  Tooltip,
  IconButton,
  colors,
  responsiveFontSizes,
} from '@material-ui/core';
import { OverridableComponent } from '@material-ui/core/OverridableComponent';
// import InfoIcon from '@material-ui/icons/Info';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import theme from 'theme';
// import PeopleIcon from '@material-ui/icons/PeopleOutlined';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
    borderRadius: '12px',
  },

  content: {
    alignItems: 'center',
    display: 'flex',
  },

  title: {
    fontWeight: 700,
    textAlign: 'center',
  },

  avatar: {
    backgroundColor: theme.palette.primary.main,
    height: 56,
    width: 56,
  },

  icon: {
    height: 32,
    width: 32,
  },

  toolTip: {
    // alignItems: 'flex-end',
    // justify: 'flex-end',
    // position: 'static',
    marginTop: theme.spacing(-1.5),
    padding: 10,
    // width: 10
  },

  infoIcon: {
    height: 20,
    width: 20,
  },

  difference: {
    marginTop: theme.spacing(2),
    display: 'flex',
    alignItems: 'center',
  },

  differenceIcon: {
    color: theme.palette.success.dark,
  },

  differenceValue: {
    color: theme.palette.success.dark,
    marginRight: theme.spacing(1),
  },
}));

interface Props {
  metric: number;
  label?: string;
  description?: string;
  icon?: JSX.Element;
}

const SingleMetricCard = ({ metric, label, description, icon }: Props) => {
  const classes = useStyles();

  return (
    <Card className={classes.root}>
      <CardContent>
        <Grid container justify="space-between" direction="row">
          <Grid item>
            <Grid container direction="column" justify="space-evenly">
              <Grid container direction="row" justify="flex-start">
                <Grid item>
                  <Typography
                    className={classes.title}
                    color="textSecondary"
                    gutterBottom
                    variant="body2">
                    {label}
                  </Typography>
                </Grid>
                <Grid item>
                  <Tooltip
                    title={
                      <Typography variant="body1" color="inherit">
                        {description ?? label}
                      </Typography>
                    }
                    className={classes.toolTip}
                    placement="top-start">
                    <IconButton>
                      <InfoOutlinedIcon className={classes.infoIcon} />
                    </IconButton>
                  </Tooltip>
                </Grid>
              </Grid>
              <Grid item>
                <Typography variant="h3" className={classes.content}>
                  {metric ?? <CircularProgress size={20} />}
                </Typography>
              </Grid>
              <Grid item></Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Grid item>
              <Avatar className={classes.avatar}>
                {React.cloneElement(icon, { className: classes.icon })}
              </Avatar>
            </Grid>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default SingleMetricCard;
