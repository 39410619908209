const MB_TO_BYTES = 1024 * 1024;

//Photo notes and inventory
export const MAX_TOTAL_SIZE_ROOM_PHOTO_MB = 200; // 200MB
export const MAX_TOTAL_SIZE_ROOM_PHOTO_IN_BYTES =
  MAX_TOTAL_SIZE_ROOM_PHOTO_MB * 1024 * 1024; // 200MB

//Job uploads
export const MAX_TOTAL_SIZE_JOB_UPLOADS_MB = 50;
export const MAX_TOTAL_SIZE_JOB_UPLOADS_IN_BYTES =
  MAX_TOTAL_SIZE_JOB_UPLOADS_MB * MB_TO_BYTES; // 20MB

//Email attachments
export const MAX_TOTAL_SIZE_EMAIL_ATTACHMENTS_MB = 50;
export const MAX_TOTAL_SIZE_EMAIL_ATTACHMENTS_IN_BYTES =
  MAX_TOTAL_SIZE_EMAIL_ATTACHMENTS_MB * MB_TO_BYTES; // 20MB

//Task attachments
export const MAX_TOTAL_SIZE_TASK_ATTACHMENTS_MB = 50;
export const MAX_TOTAL_SIZE_TASK_ATTACHMENTS_IN_BYTES =
  MAX_TOTAL_SIZE_TASK_ATTACHMENTS_MB * MB_TO_BYTES; // 20MB

// Moisture readings
export const MAX_TOTAL_SIZE_MOISTURE_READINGS_MB = 50;

//Floor plan
export const MAX_TOTAL_SIZE_FLOOR_PLAN_MB = 50;

//Letterhead
export const MAX_TOTAL_SIZE_LETTERHEAD_MB = 1;

export const getFileSize = (selectedFiles: File[]) => {
  const totalSize = selectedFiles.reduce(
    (total, file) => total + file?.size,
    0
  );

  return Number(totalSize / 1024 / 1024).toFixed(2);
};

export const isSizeExceeded = (
  selectedFiles: File[],
  customMaxSizeInMB?: number
) => {
  const totalSize = selectedFiles.reduce(
    (total, file) => total + file?.size,
    0
  );
  if (customMaxSizeInMB) {
    return totalSize > customMaxSizeInMB * MB_TO_BYTES;
  } else {
    return totalSize > MAX_TOTAL_SIZE_ROOM_PHOTO_IN_BYTES;
  }
};
