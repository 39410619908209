export default function combineDateAndTime(
  date: Date,
  time: Date
): Date | null {
  if (!date || !time) {
    return null;
  }
  const timeString = time.getHours() + ':' + time.getMinutes() + ':00';
  const year = date.getFullYear();
  const month = date.getMonth() + 1; // Jan is 0, dec is 11
  const day = date.getDate();
  const dateString = '' + year + '-' + month + '-' + day;
  return new Date(dateString + ' ' + timeString);
}
