import React, { useEffect, useState } from 'react';
import {
  Drawer,
  Box,
  makeStyles,
  Toolbar,
  IconButton,
  Typography,
  Dialog,
  useMediaQuery,
  DialogContent,
  DialogActions,
  Button,
  CircularProgress,
} from '@material-ui/core';
import { SlotInfo } from '../interfaces';
import EventInfo from './EventInfo';
import AddOrEditTask from 'components/ScheduledTasks/AddOrEditScheduledTask';
import AuthContainer from 'components/AuthContainer/AuthContainer';
import CloseIcon from '@material-ui/icons/Close';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import MoveIcon from '@mui/icons-material/CopyAll';
import CopyIcon from '@mui/icons-material/ContentCopy';
import DifferenceIcon from '@mui/icons-material/Difference';
import ConfirmTaskDeleteDialog from 'components/ScheduledTasks/ConfirmTaskDeleteDialog';
import { ScheduledTask } from 'api/jobApi';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import theme from 'theme';
import ExpandMenuButton, {
  MenuOption,
} from 'components/common/ExpandMenuButton';
import { DialogTitle } from '@mui/material';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { copyOrMoveScheduleTask } from 'api/calendarApi';
import { useSnackbar } from 'notistack';

export interface EventDrawerProps {
  open: boolean;
  slot?: SlotInfo;
  event?: any;
  onClose: () => void;
  tasks: ScheduledTask[];
}

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    width: 500,
    [theme.breakpoints.down('sm')]: {
      width: 300,
    },
  },
  menuItem: {
    paddingTop: 1,
    paddingBottom: 1,
    marginTop: 1,
    marginBottom: 1,
  },
  cancelBtn: { color: theme.palette.error.main, marginRight: theme.spacing(2) },
}));

const CalendarEventDrawer = ({
  slot,
  event,
  onClose,
  tasks,
}: EventDrawerProps) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();
  const [calendarOpen, setCalendarOpen] = useState(false);
  const [openDuplicateDialog, setOpenDuplicateDialog] = useState(false);
  const [selectedDate, setSelectedDate] = useState<Date | null>(new Date());
  const [calendarTitle, setCalendarTitle] = useState<'copy' | 'move'>('move');
  const [taskDialogOpen, setTaskDialogOpen] = useState(false);
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [recurringMoveCopyAlert, setRecurringMoveCopyAlert] = useState(false);
  const [originTask, setOriginTask] = useState<ScheduledTask>();
  const [loading, setLoading] = useState(false);
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    setTaskDialogOpen(Boolean(slot));
  }, [slot]);

  useEffect(() => {
    if (tasks && event) {
      const fullTask = tasks.find((task) => task.id === event.id);
      if (fullTask) {
        setOriginTask(fullTask);
      }
    }
  }, [tasks, event]);

  const { mutate: mutateCopyOrMove } = useMutation(copyOrMoveScheduleTask, {
    onSuccess: async () => {
      await queryClient.invalidateQueries(['calendar-events']);
      await queryClient.invalidateQueries(['scheduled-tasks']);
      setLoading(false);
      setCalendarOpen(false);
      setSelectedDate(new Date());
      onClose();
      enqueueSnackbar('Schedule task created successfully', {
        variant: 'success',
      });
    },
    onError: () => {
      setLoading(false);
      enqueueSnackbar('Failed to create scheduled task', {
        variant: 'error',
      });
    },
  });

  function handleMoveTaskClickOpen(open: boolean) {
    setCalendarTitle('move');
    if (event.isRecurring) {
      setRecurringMoveCopyAlert(true);
    } else {
      setCalendarOpen(open);
    }
  }

  function handleCopyTaskClickOpen(open: boolean) {
    setCalendarTitle('copy');
    if (event.isRecurring) {
      setRecurringMoveCopyAlert(true);
    } else {
      setCalendarOpen(open);
    }
  }

  const deleteOption = {
    label: 'Delete task',
    setDialogOpen: setDeleteDialogOpen,
    icon: <DeleteIcon fontSize="small" />,
  };

  const editOption = {
    label: 'Edit task',
    setDialogOpen: setEditDialogOpen,
    icon: <EditIcon fontSize="small" />,
  };

  const moveOption = {
    label: 'Move to',
    setDialogOpen: handleMoveTaskClickOpen,
    icon: <MoveIcon fontSize="small" />,
  };

  const copyOption = {
    label: 'Copy to',
    setDialogOpen: handleCopyTaskClickOpen,
    icon: <CopyIcon fontSize="small" />,
  };

  const duplicateOption = {
    label: 'Duplicate task',
    setDialogOpen: setOpenDuplicateDialog,
    icon: <DifferenceIcon fontSize="small" />,
  };

  const menuOptions = [
    editOption,
    deleteOption,
    moveOption,
    copyOption,
    duplicateOption,
  ] as MenuOption[];

  if (taskDialogOpen) {
    return (
      <AddOrEditTask
        forceCreateMode
        handleClose={() => setTaskDialogOpen(false)}
        editTaskDefaultValues={{
          start: (slot.start as Date).toISOString(),
          end: (slot.end as Date).toISOString(),
        }}
      />
    );
  }

  return (
    <>
      <Drawer
        anchor="right"
        open={Boolean(event)}
        onClose={onClose}
        style={{ maxWidth: '100%' }}>
        <Toolbar
          disableGutters
          variant="dense"
          style={{ margin: 5, marginBottom: 0 }}>
          <Box display="flex" flexGrow={1}>
            <IconButton color="secondary" onClick={onClose}>
              <CloseIcon />
            </IconButton>
          </Box>
          <AuthContainer
            restrictTo={['Administrator', 'ProjectManager', 'Supervisor']}>
            <Box margin={1}>
              <ExpandMenuButton options={menuOptions} />
            </Box>
          </AuthContainer>
        </Toolbar>
        <Box className={classes.root}>
          <Box display="flex" flexGrow={1}>
            <Typography variant="h5" style={{ marginBottom: '1rem' }}>
              Task Details
            </Typography>
          </Box>
          {event && <EventInfo event={event} task={originTask} />}
        </Box>
      </Drawer>
      {editDialogOpen && (
        <AddOrEditTask
          jobId={event.jobId}
          editTaskDefaultValues={{
            ...event,
            taskAssignments: originTask.taskAssignments,
            start: event.start.toISOString(),
            end: event.end.toISOString(),
          }}
          forceCreateMode={false}
          handleClose={() => {
            setEditDialogOpen(false);
            onClose();
          }}
        />
      )}
      {deleteDialogOpen && (
        <ConfirmTaskDeleteDialog
          task={event}
          handleClose={() => {
            setDeleteDialogOpen(false);
            onClose();
          }}
          open={deleteDialogOpen}
        />
      )}
      {openDuplicateDialog && (
        <AddOrEditTask
          jobId={event?.jobId}
          editTaskDefaultValues={{
            ...event,
            taskAssignments: originTask.taskAssignments,
            start: event.start.toISOString(),
            end: event.end.toISOString(),
          }}
          forceCreateMode={true}
          handleClose={() => {
            setOpenDuplicateDialog(false);
            onClose();
          }}
          duplicateTask={true}
        />
      )}
      {calendarOpen && (
        <Dialog fullScreen={isMobile} maxWidth="sm" open={true} scroll="paper">
          <DialogTitle>{`${
            calendarTitle.charAt(0).toUpperCase() + calendarTitle.slice(1)
          } task to`}</DialogTitle>
          <DialogContent>
            <Box>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <DatePicker
                  okLabel={calendarTitle}
                  size="small"
                  fullWidth
                  orientation={isMobile ? 'portrait' : 'landscape'}
                  variant="static"
                  openTo="date"
                  value={selectedDate}
                  onChange={(value) => {
                    const newDate = new Date(event.start).setDate(
                      value.getDate()
                    );
                    setSelectedDate(new Date(newDate));
                  }}
                />
              </MuiPickersUtilsProvider>
            </Box>
          </DialogContent>
          <DialogActions>
            <Box mr={3} mb={2}>
              <Button
                onClick={() => {
                  setCalendarOpen(false);
                  onClose();
                }}
                className={classes.cancelBtn}
                //disabled={loading}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                color="primary"
                disabled={loading}
                endIcon={
                  loading ? (
                    <CircularProgress size={15} />
                  ) : (
                    <ArrowForwardIcon />
                  )
                }
                onClick={async () => {
                  setLoading(true);
                  if (event.isRecurring && calendarTitle === 'move') {
                    const excludeDate = event.start;
                    await mutateCopyOrMove({
                      taskId: event.id,
                      date: selectedDate,
                      action: calendarTitle,
                      excludeDate,
                    });
                  } else {
                    await mutateCopyOrMove({
                      taskId: event.id,
                      date: selectedDate,
                      action: calendarTitle,
                    });
                  }
                }}>
                {`${calendarTitle} task`}
              </Button>
            </Box>
          </DialogActions>
        </Dialog>
      )}
      {recurringMoveCopyAlert && (
        <Dialog fullScreen={isMobile} maxWidth="sm" open={true} scroll="paper">
          <DialogTitle>{`${
            calendarTitle.charAt(0).toUpperCase() + calendarTitle.slice(1)
          } task`}</DialogTitle>
          <DialogContent>
            <Box marginBottom={3}>
              <Typography variant="subtitle2">
                {` This is a recurring event and only this event will ${calendarTitle} to a new task?`}
              </Typography>
            </Box>
          </DialogContent>
          <DialogActions>
            <Box mr={3} mb={2}>
              <Button
                onClick={() => {
                  setRecurringMoveCopyAlert(false);
                  onClose();
                }}
                className={classes.cancelBtn}
                //disabled={loading}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                color="primary"
                disabled={loading}
                endIcon={
                  loading ? (
                    <CircularProgress size={15} />
                  ) : (
                    <ArrowForwardIcon />
                  )
                }
                onClick={() => {
                  setRecurringMoveCopyAlert(false);
                  setCalendarOpen(true);
                }}>
                {`Ok, proceed`}
              </Button>
            </Box>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
};

export default CalendarEventDrawer;
