import { colors } from '@mui/material';
import { StatusValue } from 'api/jobApi';

export function getStatusColor(status: StatusValue, strength: number = 300) {
  const statusColors = {
    [StatusValue.NOT_STARTED]: colors.blueGrey[strength],
    [StatusValue.IN_PROGRESS]: colors.green[strength],
    [StatusValue.ON_HOLD]: colors.amber[strength],
    [StatusValue.APPROVAL_PENDING]: colors.orange[strength],
    [StatusValue.TO_BE_INVOICED]: colors.teal[strength],
    [StatusValue.COMPLETED]: colors.blue[strength],
    [StatusValue.CANCELLED]: colors.red[strength],
  };

  return statusColors[status];
}
