import React from 'react';
import clsx from 'clsx';
import { Link as RouterLink } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { AppBar, Toolbar } from '@material-ui/core';
import Logo from 'assets/logos/logo-white.png';

const useStyles = makeStyles(() => ({
  root: {
    boxShadow: 'none'
  }
}));

interface Props {
  className?: string;
}

const Topbar = (props: Props) => {
  const { className, ...rest } = props;
  const classes = useStyles();

  return (
    <AppBar
      {...rest}
      className={clsx(classes.root, className)}
      color="primary"
      position="fixed">
      <Toolbar>
        <RouterLink to="/">
          <img
            alt="Logo"
            src={Logo}
            style={{ width: '10.5rem' }}
          />
        </RouterLink>
      </Toolbar>
    </AppBar>
  );
};

export default Topbar;
