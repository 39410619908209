import axios from 'axios';
import * as UserAPI from './userApi';
import * as OrganizationAPI from './organizationApi';
import * as ClientAPI from './clientsApi';
import * as JobAPI from './jobApi';
import * as EquipmentAPI from './equipmentApi';
import * as OverviewAPI from './overviewApi';
import * as CalendarAPI from './calendarApi';
import * as UploadsAPI from './uploadsApi';
import * as FormAPI from './formApi';
import * as InspectionsAPI from './inspectionsApi';
import * as ReportsAPI from './reportsApi';
import * as EquipmentInstallationAPI from './inspection/equipmentInstallationApi';
import * as EstimatesAPI from './estimates/estimatesAPI';
import * as TimesheetAPI from './inspection/timesheet/timesheetAPI';
import * as JobReportsAPI from './jobReportsApi';
import * as JobKPIAPI from './jobkpiApi';
import config from 'util/config-loader';

// Add the user token to all outgoing requests
axios.interceptors.request.use(
  function (config) {
    config.baseURL = getBaseUrl();

    const token = `Bearer ${localStorage.getItem('token') || ''}`;
    config.headers.get.Authorization = token;
    config.headers.post.Authorization = token;
    config.headers.put.Authorization = token;
    config.headers.delete.Authorization = token;

    config.validateStatus = (status: number) => status >= 200 && status < 300;

    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

function getBaseUrl(): string {
  return config.API_BASE_URL;
}

axios.interceptors.response.use(
  (response) => response,
  function (error) {
    return Promise.reject(error?.response ?? error);
  }
);

export {
  UserAPI,
  OrganizationAPI,
  ClientAPI,
  JobAPI,
  EquipmentAPI,
  OverviewAPI,
  CalendarAPI,
  UploadsAPI,
  FormAPI,
  InspectionsAPI,
  ReportsAPI,
  EquipmentInstallationAPI,
  EstimatesAPI,
  TimesheetAPI,
  JobReportsAPI,
  JobKPIAPI,
};
