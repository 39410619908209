import React, { useEffect, useState } from 'react';
import { Typography, Box, Divider, Button, Chip } from '@material-ui/core';
import { ScheduledTask } from 'api/jobApi';
import { useNavigate } from 'react-router';
import format from 'date-fns/format';
import formatDistanceToNow from 'date-fns/formatDistanceToNow';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import UserAvatar from 'components/Users/UserAvatar';
import { Grid, LinearProgress } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { JobAPI } from 'api';
import FileChip from '../FileChip';
import theme from 'theme';

const EventInfo = ({
    event,
    task,
}: {
    event: ScheduledTask;
    task: ScheduledTask;
}) => {
    const navigate = useNavigate();

    const start = new Date(event.start);
    const end = new Date(event.end);
    const date = format(start, 'MMM dd yyyy');
    const startTime = format(start, 'hh:mm a');
    const endTime = format(end, 'hh:mm a');

    const { name, description, job, organization, isRecurring } = event;

    const { isFetching: isJobFetching, data: jobData } = useQuery(
        ['job-by-id', job?.id],
        () => JobAPI.getJobById(job?.id),
        { enabled: Boolean(job?.id) }
    );

    const formattedData = description
        ? description.replace(/\r\n/g, '\n')
        : description;

    return (
        <>
            <Box mb={2}>
                <Typography
                    variant="h3"
                    data-cy={'job-detail-name'}
                    gutterBottom>
                    {name}
                </Typography>
            </Box>
            <Box display="flex">
                <Typography variant="h4" color="textSecondary" gutterBottom>
                    {date}
                </Typography>
                {isRecurring && (
                    <Chip
                        size="small"
                        variant="default"
                        style={{
                            borderRadius: theme.shape.borderRadius,
                            marginRight: theme.spacing(1),
                            marginTop: theme.spacing(0.3),
                            marginLeft: theme.spacing(2),
                        }}
                        label={<strong>Recurring Task</strong>}
                    />
                )}
            </Box>
            <Typography variant="h5" gutterBottom>
                {startTime} - {endTime}
            </Typography>
            <Typography variant="body1" gutterBottom>
                ({formatDistanceToNow(start, { addSuffix: true })})
            </Typography>
            <Divider />
            <Typography style={{ marginTop: 10 }} variant="subtitle1">
                Task Description
            </Typography>
            <Box my={2} maxHeight={'30vh'} style={{ overflow: 'auto' }}>
                <Typography variant="body1" style={{ whiteSpace: 'pre-line' }}>
                    {formattedData || 'No description provided.'}
                </Typography>
            </Box>
            <Divider />
            {Boolean(job) && (
                <Box my={2}>
                    <Typography variant="subtitle1" gutterBottom>
                        Job Details
                    </Typography>
                    <Divider />
                    <Grid container>
                        <Grid item xs={12}>
                            <Typography variant="overline">
                                Job Number
                            </Typography>
                            <Typography variant="body1" gutterBottom>
                                <strong>
                                    {organization?.identifier
                                        ? `${organization?.identifier} - ${job?.jobNo}`
                                        : '-'}
                                </strong>
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="overline">
                                Job Address
                            </Typography>
                            <Typography variant="body1" gutterBottom>
                                <strong>{job?.siteAddress}</strong>
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="overline">Job Type</Typography>
                            <Typography variant="body1" gutterBottom>
                                <strong>{job?.type}</strong>
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="overline">
                                Client Name
                            </Typography>
                            <Typography variant="body1" gutterBottom>
                                <strong>{job?.client?.companyName}</strong>
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="overline">
                                Site Contact
                            </Typography>
                            <Typography variant="body1" gutterBottom>
                                <strong>{job?.contactName}</strong>
                            </Typography>
                        </Grid>
                        {job.supervisor && (
                            <Grid item xs={12}>
                                <Typography variant="overline">
                                    Supervisor
                                </Typography>
                                <Typography variant="body1">
                                    <strong>
                                        {`${job?.supervisor?.firstName} ${job?.supervisor?.lastName}`}
                                    </strong>
                                </Typography>
                            </Grid>
                        )}
                        {isJobFetching && <LinearProgress />}
                        {jobData?.projectManager && (
                            <Grid item xs={12}>
                                <Typography variant="overline">
                                    Project Coordinator
                                </Typography>
                                <Typography variant="body1">
                                    <strong>
                                        {`${jobData?.projectManager?.firstName} ${jobData?.projectManager?.lastName}`}
                                    </strong>
                                </Typography>
                            </Grid>
                        )}
                    </Grid>
                    <Button
                        style={{ marginTop: '1rem' }}
                        size="small"
                        variant="outlined"
                        color="primary"
                        onClick={() => navigate(`/jobs/${job?.id}`)}
                        endIcon={<KeyboardArrowRightIcon />}>
                        Open job details
                    </Button>
                </Box>
            )}
            <Divider />
            <Box my={2}>
                <Typography variant="subtitle1" component="div">
                    Task Assignees
                </Typography>
                {task?.taskAssignments?.map(({ user }, i) => (
                    <Box m={1}>
                        <Chip
                            key={i}
                            size="medium"
                            variant="outlined"
                            avatar={
                                <UserAvatar
                                    user={user}
                                    size={24}
                                    fontSize={10}
                                    style={{ color: 'white' }}
                                />
                            }
                            label={`${user.firstName} ${user.lastName}`}
                        />
                    </Box>
                ))}
            </Box>
            <Divider />
            <Box my={2}>
                <Typography variant="overline" component="div">
                    Attachments
                </Typography>
                {event.attachments?.length === 0 ? (
                    <Typography variant="body1">
                        No attachments added
                    </Typography>
                ) : (
                    <>
                        {event.attachments?.map((item) => (
                            <FileChip fileData={item} key={item.id} />
                        ))}
                    </>
                )}
            </Box>
            <Divider />
            <Box>
                <Typography style={{ marginTop: 10 }} variant="body2">
                    {`Created By: ${event.createdByUser?.firstName} ${event.createdByUser?.lastName}`}
                </Typography>
                {event.updatedOn && (
                    <Typography style={{ marginTop: 10 }} variant="body2">
                        {`Last Updated On ${format(
                            new Date(event.updatedOn),
                            'MMM dd yyyy, hh:mm a'
                        )} `}
                        {event.updatedByUser &&
                            `by ${event.updatedByUser?.firstName} ${event.updatedByUser?.lastName}`}
                    </Typography>
                )}
            </Box>
        </>
    );
};

export default EventInfo;
