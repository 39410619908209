import React from 'react';
import {
  Grid,
  LinearProgress,
  CardContent,
  Typography,
  Card,
  Button,
  Box,
} from '@material-ui/core';
import { useIsFetching } from '@tanstack/react-query';
import { useNavigate } from 'react-router';
import Calendar from 'components/Calendar/Calendar';
import PlaceholderCard from 'components/common/Placeholder';
import JobsByStatus from 'components/Jobs/Graphs/StatusPieChart';
import JobsByClientBarChart from 'components/Jobs/Graphs/JobsByClientChart';
import SingleMetricCard from 'components/common/SingleMetricCard';
import AssignmentIcon from '@material-ui/icons/Assignment';
import HourglassEmptyIcon from '@material-ui/icons/HourglassEmpty';
import DetailedMetricCard from 'components/common/DetailedMetricCard';

const PMGridItems = ({ data }) => {
  const navigate = useNavigate();
  const isFetchingCalendar = useIsFetching(['calendar-events', 'today']);
  const pendingRatio: number =
    data?.userActiveJobCount == 0
      ? 0
      : data?.userPendingJobCount / data?.userActiveJobCount;
  const pendingDetails: any =
    data?.userActiveJobCount == 0
      ? [{ subMetric: '-', label: 'You currently have 0 pending jobs' }]
      : [{ subMetric: pendingRatio, label: 'of Your Jobs Are Pending' }];

  return (
    <>
      <Grid container spacing={3}>
        {/* <Grid item xs={12} md={6} lg={3}>
          <SingleMetricCard
            metric={data?.userActiveJobCount}
            label="ACTIVE JOBS"
            icon={<AssignmentIcon />}
          />
        </Grid>
        <Grid item xs={12} md={6} lg={3}>
          <DetailedMetricCard
            metric={0}
            details={pendingDetails}
            label="PENDING JOBS"
            icon={<HourglassEmptyIcon />}
          />
        </Grid>
        <Grid item xs={12} md={6} lg={3}>
          <PlaceholderCard />
        </Grid>
        <Grid item xs={12} md={6} lg={3}>
          <PlaceholderCard />
        </Grid> */}
        <Grid item xs={12} md={6} xl={3}>
          <JobsByStatus label="Your Jobs by Status" />
        </Grid>
        <Grid item xs={12} md={6} xl={6}>
          <JobsByClientBarChart
            data={data?.userJobsByClientAndStatus}
            label="Your Jobs by Client"
          />
        </Grid>
        <Grid item xs={12} md={6} lg={6} xl={3}>
          <Card>
            {isFetchingCalendar > 0 && <LinearProgress />}
            <CardContent>
              <Box mb={2}>
                <Typography variant="overline">Your day at a glance</Typography>
                <Typography variant="h4" gutterBottom>
                  Scheduled tasks for Today
                </Typography>
              </Box>
              <Calendar
                showToolbar={false}
                defaultView={'day'}
                step={30}
                // min={new Date('January 1, 2020 8:00:00')}
                // max={new Date('January 1, 2020 17:00:00')}
              />
              <Button
                variant="outlined"
                style={{ marginTop: '2rem' }}
                size="small"
                color="primary"
                onClick={() => navigate('/calendar')}>
                Open calendar
              </Button>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </>
  );
};

export default PMGridItems;
