import { PaymentMethod } from '@stripe/stripe-js';
import axios from 'axios';
import { z } from 'zod';
import { User } from './userApi';

export const OrganizationPricing = z.enum(['FREE', 'PAYG', 'UNRESTRICTED']);
export interface Organization {
  id: number;
  location: string;
  name: string;
  supportEmail: string;
  identifier: string;
  defaultForwardReplyToEmail: string;
  pricingPlan: OrganizationPricingPlan;
  stripeCustomerId: string;
  cardAddedOn: string;
  cardHolderName: string;
}
export type OrganizationPricingPlan = z.infer<typeof OrganizationPricing>;

export const OrganizationUsageSchema = z.object({
  pricingPlan: OrganizationPricing,
  remainingUsers: z.number().optional(),
  userCount: z.number(),
  remainingJobs: z.number().optional(),
  jobCount: z.number(),
});

export const OrganizationStorageUsageSchema = z.object({
  pricingPlan: OrganizationPricing,
  remainingStorage: z.number().optional(),
  cloudUsage: z.number(),
});

export async function getUserOrg(): Promise<Organization> {
  const { data } = await axios.get('/api/v1/organization');
  return data;
}

export async function updateOrg(org: Organization): Promise<any> {
  const { data } = await axios.put(`/api/v1/organization/${org.id}`, org);
  return data.payload;
}

export async function getOrgUsage() {
  const { data } = await axios.get('/api/v1/organization/usage');
  return OrganizationUsageSchema.parse(data);
}

export async function getOrgStorageUsage() {
  const { data } = await axios.get('/api/v1/organization/storage-usage');
  return OrganizationStorageUsageSchema.parse(data);
}

export async function upgradeToPayg({
  cardHolderName,
  paymentMethodId,
}: {
  cardHolderName: string;
  paymentMethodId: string | PaymentMethod;
}) {
  const { data } = await axios.post('/api/v1/organization/upgrade-to-payg', {
    cardHolderName,
    paymentMethodId,
  });
  return data;
}

export async function downgradeToFree() {
  const { data } = await axios.post('/api/v1/organization/downgrade-to-free');
  return data;
}

export async function getEstimateBilling() {
  const res = await axios.get('/api/v1/organization/billing-estimate');
  return res.data;
}

export async function deleteOrg(): Promise<any> {
  const res = await axios.delete(`/api/v1/organization`);
  return res.data;
}

export async function contactSupport({
  category,
  subject,
  message,
}: {
  category: string;
  subject: string;
  message: string;
}) {
  const res = await axios.post('/api/v1/organization/contact-support', {
    category,
    subject,
    message,
  });
  return res.data;
}
