import React from 'react';
import {
  Grid,
  LinearProgress,
  CardContent,
  Typography,
  Card,
  Button,
  Box,
} from '@material-ui/core';
import { useIsFetching } from '@tanstack/react-query';
import { useNavigate } from 'react-router';
import Calendar from 'components/Calendar/Calendar';
import SingleMetricCard from 'components/common/SingleMetricCard';
import DetailedMetricCard from 'components/common/DetailedMetricCard';
import OverallJobProgressPieChart from 'components/Jobs/Graphs/StatusPieChart';
import JobsByClientBarChart from 'components/Jobs/Graphs/JobsByClientChart';
import PeopleIcon from '@material-ui/icons/PeopleOutlined';
import AssignmentIcon from '@material-ui/icons/Assignment';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import JobsByCoordinatorChart from 'components/Jobs/Graphs/JobsByCoordinatorChart';

const AdminGridItems = ({ data }) => {
  const navigate = useNavigate();

  const isFetchingCalendar = useIsFetching(['calendar-events', 'today']);
  const activeUsersDetails = [
    { subMetric: data?.projectCoordinatorCount, label: 'Project Coordinators' },
    { subMetric: data?.technicianCount, label: 'Technicians' },
  ];
  const CoordinatorWorkloadDetails = [
    { subMetric: data?.projectCoordinatorCount, label: 'Project Coordinators' },
    { subMetric: data?.activeJobCount, label: 'Active Jobs' },
  ];
  const technicianUtilizationDetails = [
    { subMetric: data?.technicianCount, label: 'Technicians' },
    {
      subMetric: data?.technicianUtilization,
      label: 'Assigned to Tasks Today',
    },
  ];

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12} md={6} lg={3}>
          <DetailedMetricCard
            metric={data?.userCount}
            label="ACTIVE USERS"
            // description="The number of Restorize users in your organization"
            details={activeUsersDetails}
            icon={<PeopleIcon />}
          />
        </Grid>
        <Grid item xs={12} md={6} lg={3}>
          <SingleMetricCard
            metric={data?.activeJobCount}
            label="ACTIVE JOBS"
            description="The number of jobs that are in progress, on hold, approval pending or to be invoiced. Jobs that have not started, canceled and completed are excluded from this count."
            icon={<AssignmentIcon />}
          />
        </Grid>
        <Grid item xs={12} md={6} lg={3}>
          <DetailedMetricCard
            metric={data?.jobsPerCoordinator}
            label="ACTIVE JOBS PER COORDINATOR"
            // description="The average number of jobs per project coordinator. i.e. The active job count divided by the number of project coordinators."
            details={CoordinatorWorkloadDetails}
            icon={<AssignmentIndIcon />}
          />
        </Grid>
        <Grid item xs={12} md={6} lg={3}>
          <DetailedMetricCard
            metric={data?.technicianUtilizationRate}
            percentage={true}
            label="TECHNICIAN UTILIZATION"
            // description="The proportion of technicians assigned to tasks today. i.e. The number of technicians assigned to tasks taking place today divided by the total number of technicians."
            details={technicianUtilizationDetails}
            icon={<PeopleIcon />}
          />
        </Grid>{' '}
        <Grid item xs={12} md={12} lg={12}>
          {/* <JobsByCoordinator data={data?.jobCountByCoordinator} /> */}
          <JobsByCoordinatorChart />
        </Grid>
        <Grid item xs={12} md={6} lg={3}>
          <OverallJobProgressPieChart
          //  data={data?.jobsByStatus}
          />
        </Grid>
        <Grid item xs={12} md={6} lg={6}>
          <JobsByClientBarChart data={data?.activeJobsByClientAndStatus} />
        </Grid>
        <Grid item xs={12} md={6} lg={6} xl={3}>
          <Card style={{ borderRadius: '12px' }}>
            {isFetchingCalendar > 0 && <LinearProgress />}
            <CardContent>
              <Box mb={2}>
                <Typography variant="overline">Your day at a glance</Typography>
                <Typography variant="h4" gutterBottom>
                  Scheduled tasks for Today
                </Typography>
              </Box>
              <Calendar
                showToolbar={false}
                defaultView={'day'}
                step={30}
                // min={new Date('January 1, 2020 8:00:00')}
                // max={new Date('January 1, 2020 17:00:00')}
              />
              <Button
                variant="outlined"
                style={{ marginTop: '2rem' }}
                size="small"
                color="primary"
                onClick={() => navigate('/calendar')}>
                Open calendar
              </Button>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </>
  );
};

export default AdminGridItems;
