import React, { useEffect } from 'react';
import { ResponsiveBar } from '@nivo/bar';
import {
  Card,
  CardContent,
  LinearProgress,
  Typography,
  Divider,
  Checkbox,
  FormControl,
  Grid,
  MenuItem,
  ListItemText,
  InputLabel,
  OutlinedInput,
  Select,
  SelectChangeEvent,
  TextField,
  Chip,
  Button,
} from '@mui/material';
import { StatusValue } from 'api/jobApi';
import useIsMobile from 'theme/useIsMobile';
import { getStatusColor } from 'util/statusColors';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import CancelIcon from '@mui/icons-material/Cancel';
import {
  format,
  startOfISOWeek,
  startOfMonth,
  startOfQuarter,
  startOfYear,
  startOfDay,
  endOfDay,
} from 'date-fns';
import { grey } from '@mui/material/colors';
import { OverviewAPI } from 'api';
import { useQuery } from '@tanstack/react-query';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { JobsByCoordinator } from 'api/overviewApi';

const desktopMargins = { top: 40, right: 50, bottom: 150, left: 40 };
const mobileMargins = { top: 160, right: 10, bottom: 150, left: 40 };

const allStatus = [
  {
    label: StatusValue.NOT_STARTED,
    color: getStatusColor(StatusValue.NOT_STARTED, 200),
  },
  {
    label: StatusValue.IN_PROGRESS,
    color: getStatusColor(StatusValue.IN_PROGRESS, 700),
  },
  {
    label: StatusValue.ON_HOLD,
    color: getStatusColor(StatusValue.ON_HOLD, 300),
  },
  {
    label: StatusValue.APPROVAL_PENDING,
    color: getStatusColor(StatusValue.APPROVAL_PENDING, 700),
  },
  {
    label: StatusValue.TO_BE_INVOICED,
    color: getStatusColor(StatusValue.TO_BE_INVOICED, 600),
  },
  {
    label: StatusValue.COMPLETED,
    color: getStatusColor(StatusValue.COMPLETED, 800),
  },
  {
    label: StatusValue.CANCELLED,
    color: getStatusColor(StatusValue.CANCELLED, 700),
  },
];

export enum DatePeriods {
  ALL = 'All Records',
  TODAY = 'Today',
  THIS_WEEK = 'This Week',
  THIS_MONTH = 'This Month',
  THIS_QUARTER = 'This Quarter',
  YTD = 'Year To Date',
  CUSTOM = 'Custom',
}

const dateRange = [
  { name: DatePeriods.ALL, value: '' },
  { name: DatePeriods.TODAY, value: format(new Date(), 'MMM dd, yyyy') },
  {
    name: DatePeriods.THIS_WEEK,
    value: `${format(startOfISOWeek(new Date()), 'MMM dd')} - ${format(
      new Date(),
      'MMM dd, yyyy'
    )}`,
  },
  {
    name: DatePeriods.THIS_MONTH,
    value: `${format(startOfMonth(new Date()), 'MMM dd')} - ${format(
      new Date(),
      'MMM dd, yyyy'
    )}`,
  },
  {
    name: DatePeriods.THIS_QUARTER,
    value: `${format(startOfQuarter(new Date()), 'MMM dd')} - ${format(
      new Date(),
      'MMM dd, yyyy'
    )}`,
  },
  {
    name: DatePeriods.YTD,
    value: `${format(startOfYear(new Date()), 'MMM dd')} - ${format(
      new Date(),
      'MMM dd, yyyy'
    )}`,
  },
  { name: DatePeriods.CUSTOM, value: 'Select Date Range' },
];

const JobsByCoordinatorChart = () => {
  const isMobile = useIsMobile();
  const chartMargins = isMobile ? mobileMargins : desktopMargins;

  const [selectedDateRangePeriod, setSelectedDateRangePeriod] =
    React.useState<DatePeriods>(DatePeriods.ALL);
  const [startDate, setStartDate] = React.useState<string>(DatePeriods.ALL);
  const [endDate, setEndDate] = React.useState<string>(
    endOfDay(new Date()).toISOString()
  );

  const [customStartDate, setCustomStartDate] = React.useState<string>(
    new Date().toISOString()
  );
  const [customEndDate, setCustomEndDate] = React.useState<string>(
    new Date().toISOString()
  );

  const { isFetching, data } = useQuery(
    ['coordinator-job-count', startDate, endDate],
    () => OverviewAPI.fetchJobCountByCoordinator(startDate, endDate)
  );

  //selected status, in progress is by default selected
  const [selectedStatus, setSelectedStatus] = React.useState([
    {
      label: StatusValue.IN_PROGRESS,
      color: getStatusColor(StatusValue.IN_PROGRESS, 700),
    },
  ]);
  const [selectedCoordinator, setSelectedCoordinator] = React.useState<
    JobsByCoordinator[]
  >(data ?? []);

  useEffect(() => {
    setSelectedCoordinator(data);
  }, [isFetching, data]);

  const handleChange = (event: SelectChangeEvent<string[]>) => {
    const {
      target: { value },
    } = event;

    if (value) {
      setSelectedStatus(allStatus.filter((ob) => value?.includes(ob.label)));
    } else {
      setSelectedStatus([]);
    }
  };
  const handleCoordinatorChange = (event: SelectChangeEvent<string[]>) => {
    const {
      target: { value },
    } = event;

    if (value) {
      setSelectedCoordinator(
        data?.filter((ob: JobsByCoordinator) =>
          value?.includes(`${ob.firstName} ${ob.lastName}`)
        )
      );
    } else {
      setSelectedCoordinator(data);
    }
  };

  const handleDelete = (e: React.MouseEvent, value: string) => {
    e.preventDefault();
    setSelectedStatus((prev) => prev.filter((ob) => ob.label != value));
  };

  const handleDateChange = (event: SelectChangeEvent) => {
    const selectedDateRangePeriod = event.target.value as DatePeriods;
    setSelectedDateRangePeriod(selectedDateRangePeriod);
    setStartDateAndEndDate(selectedDateRangePeriod);
  };

  const setStartDateAndEndDate = (datePeriod: DatePeriods) => {
    switch (datePeriod) {
      case DatePeriods.ALL:
        setStartDate(DatePeriods.ALL);
        break;
      case DatePeriods.TODAY:
        setStartDate(startOfDay(new Date()).toISOString());
        break;
      case DatePeriods.THIS_WEEK:
        setStartDate(startOfDay(startOfISOWeek(new Date())).toISOString());
        break;
      case DatePeriods.THIS_MONTH:
        setStartDate(startOfDay(startOfMonth(new Date())).toISOString());
        break;
      case DatePeriods.THIS_QUARTER:
        setStartDate(startOfDay(startOfQuarter(new Date())).toISOString());
        break;
      case DatePeriods.YTD:
        setStartDate(startOfDay(startOfYear(new Date())).toISOString());
        break;
      default:
        setStartDate(DatePeriods.ALL);
        break;
    }
  };

  // const markers: CartesianMarkerProps[] = selectedCoordinator?.map((v) => ({
  //   axis: 'x',
  //   value: `${v.firstName} ${v.lastName}`,
  //   lineStyle: { stroke: 'black', strokeWidth: 2, strokeDasharray: '3, 6' },
  // }));

  return (
    <Card style={{ height: '800px' }}>
      {Boolean(isFetching) && <LinearProgress />}
      <CardContent style={{ height: '85%' }}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'start',
          }}>
          <Typography variant="h6" gutterBottom>
            {'Jobs by Coordinator'}
          </Typography>
          <div
            style={{
              display: 'flex',
              justifyContent: 'start',
              alignItems: 'center',
              gap: 10,
            }}>
            <Grid>
              <FormControl sx={{ my: 1, width: 300, minWidth: 300 }}>
                <InputLabel>Job Status</InputLabel>
                <Select
                  multiple
                  value={selectedStatus?.map((ob) => ob.label)}
                  onChange={handleChange}
                  input={<OutlinedInput label="Job Status" />}
                  renderValue={(selected) => selected.join(', ')}>
                  {allStatus?.map((status) => (
                    <MenuItem key={status.label} value={status.label}>
                      <Checkbox
                        checked={
                          selectedStatus
                            ?.map((ob) => ob.label)
                            .indexOf(status.label) > -1
                        }
                      />
                      <ListItemText primary={status.label} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            {!isFetching && (
              <Grid>
                <FormControl sx={{ my: 1, width: 300, minWidth: 300 }}>
                  <InputLabel>Coordinator</InputLabel>
                  <Select
                    multiple
                    value={
                      selectedCoordinator?.map(
                        (ob) => `${ob.firstName} ${ob.lastName}`
                      ) ?? []
                    }
                    onChange={handleCoordinatorChange}
                    input={<OutlinedInput label="Coordinator" />}
                    renderValue={(selected) => selected.join(', ')}>
                    {data?.map((obj) => (
                      <MenuItem
                        key={`${obj.firstName} ${obj.lastName}`}
                        value={`${obj.firstName} ${obj.lastName}`}>
                        <Checkbox
                          checked={
                            selectedCoordinator
                              ?.map((ob) => `${ob.firstName} ${ob.lastName}`)
                              .indexOf(`${obj.firstName} ${obj.lastName}`) > -1
                          }
                        />
                        <ListItemText
                          primary={`${obj.firstName} ${obj.lastName}`}
                        />
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            )}
            <Grid>
              <FormControl sx={{ my: 1, width: 'auto', minWidth: 300 }}>
                <InputLabel>Date Range</InputLabel>
                <Select
                  value={selectedDateRangePeriod}
                  SelectDisplayProps={
                    selectedDateRangePeriod != DatePeriods.ALL
                      ? {
                          style: { paddingTop: 6, paddingBottom: 6 },
                        }
                      : null
                  }
                  input={<OutlinedInput label="Date Range" />}
                  onChange={handleDateChange}
                  label={'Job Range'}>
                  {dateRange.map((range) => {
                    return (
                      <MenuItem key={range.name} value={range.name}>
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'start',
                          }}>
                          <span style={{ fontWeight: 500, fontSize: 14 }}>
                            {range.name}
                          </span>
                          <span style={{ color: grey[600], fontSize: 12 }}>
                            {range.value}
                          </span>
                        </div>
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Grid>
            {selectedDateRangePeriod == DatePeriods.CUSTOM && (
              <Grid>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <div
                    style={{
                      display: 'flex',
                      gap: 10,
                      justifyContent: 'start',
                      alignItems: 'center',
                    }}>
                    <DatePicker
                      data-cy={'select-date'}
                      label="From"
                      value={customStartDate}
                      maxDate={new Date()}
                      onChange={(newValue) => {
                        setCustomStartDate(new Date(newValue).toISOString());
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          onKeyDown={(e) => {
                            e.preventDefault();
                          }}
                          error={
                            new Date(customStartDate).setHours(0, 0, 0, 0) >
                            new Date(customEndDate).setHours(0, 0, 0, 0)
                          }
                          helperText={
                            new Date(customStartDate).setHours(0, 0, 0, 0) >
                              new Date(customEndDate).setHours(0, 0, 0, 0) &&
                            `Date should be less than ${new Date(
                              customEndDate
                            ).toLocaleDateString()}`
                          }
                        />
                      )}
                    />
                    <DatePicker
                      label="To"
                      value={customEndDate}
                      maxDate={new Date()}
                      onChange={(newValue) => {
                        setCustomEndDate(new Date(newValue).toISOString());
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          onKeyDown={(e) => {
                            e.preventDefault();
                          }}
                          error={
                            new Date(customStartDate).setHours(0, 0, 0, 0) >
                            new Date(customEndDate).setHours(0, 0, 0, 0)
                          }
                          helperText={
                            new Date(customStartDate).setHours(0, 0, 0, 0) >
                              new Date(customEndDate).setHours(0, 0, 0, 0) &&
                            `Date should be greater than ${new Date(
                              customStartDate
                            ).toLocaleDateString()}`
                          }
                        />
                      )}
                    />
                    <Button
                      variant="contained"
                      sx={{ minWidth: 120 }}
                      onClick={() => {
                        if (
                          new Date(customStartDate).setHours(0, 0, 0, 0) <=
                          new Date(customEndDate).setHours(0, 0, 0, 0)
                        ) {
                          setStartDate(
                            startOfDay(new Date(customStartDate)).toISOString()
                          );
                          setEndDate(
                            endOfDay(new Date(customEndDate)).toISOString()
                          );
                        }
                      }}>
                      Apply
                    </Button>
                  </div>
                </LocalizationProvider>
              </Grid>
            )}
          </div>
          <div
            style={{ marginBottom: 10, marginTop: 5, display: 'flex', gap: 5 }}>
            {selectedStatus?.map((st) => {
              return (
                <Chip
                  key={st.label}
                  onDelete={(e) => handleDelete(e, st.label)}
                  deleteIcon={
                    <CancelIcon
                      onMouseDown={(event) => event.stopPropagation()}
                    />
                  }
                  icon={
                    <FiberManualRecordIcon
                      style={{
                        color: allStatus
                          .filter((obj) =>
                            Object.values(obj).some((val) =>
                              val.includes(st.label)
                            )
                          )
                          .map((ob) => ob.color)
                          .toString(),
                      }}
                    />
                  }
                  label={st.label}
                />
              );
            })}
          </div>
        </div>
        <Divider />
        {!isFetching ? (
          <ResponsiveBar
            data={selectedCoordinator ?? []}
            keys={[...selectedStatus?.map((ob) => ob.label)]}
            indexBy={(d) => `${d.firstName} ${d.lastName}`}
            layout="vertical"
            margin={chartMargins}
            groupMode="stacked"
            innerPadding={5}
            colors={selectedStatus?.map((ob) => ob.color)}
            valueScale={{ type: 'linear' }}
            indexScale={{ type: 'band', round: true }}
            borderRadius={4}
            borderColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
            axisTop={null}
            axisRight={null}
            axisLeft={{
              format: (e) => Math.floor(Number(e)) === e && e,
              legend: 'number of jobs',
              legendPosition: 'middle',
              legendOffset: -30,
            }}
            axisBottom={{
              tickSize: 10,
              legend: 'project coordinator',
              legendPosition: 'middle',
              legendOffset: 40,
              format: (v) => {
                return `${v?.toString().split(' ')[0][0]?.toUpperCase()}${v
                  ?.toString()
                  .split(' ')[1][0]
                  ?.toUpperCase()}`;
              },
            }}
            enableGridY={true}
            enableGridX={!isMobile}
            labelSkipWidth={12}
            labelSkipHeight={12}
            labelTextColor="white"
            // markers={markers}
            animate={true}
            motionStiffness={30}
            motionDamping={5}
            theme={{
              axis: {
                ticks: {
                  text: {
                    fontSize: 12,
                  },
                },
              },
            }}
          />
        ) : (
          <LinearProgress />
        )}
      </CardContent>
    </Card>
  );
};

export default JobsByCoordinatorChart;
