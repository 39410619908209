import { Role, userHasAccess } from 'models/Role';
import React, { useContext } from 'react';
import UserContext from 'state/UserContext';

interface Props {
  restrictTo?: Role[];
  restrictToUserId?: string;
  noAccessComponent?: React.ComponentType;
  children: React.ReactNode;
}

const AuthContainer: React.FC<Props> = ({
  restrictTo,
  restrictToUserId,
  children,
  noAccessComponent: NoAccessComponent,
}: Props) => {
  const { user } = useContext(UserContext);

  const NoAccess = NoAccessComponent ? <NoAccessComponent /> : null;

  if (restrictToUserId && restrictToUserId === user.id) {
    return <>{children}</>;
  }

  if (!restrictTo) {
    return NoAccess;
  }

  return userHasAccess(user, restrictTo) ? <>{children}</> : NoAccess;
};

export default AuthContainer;
