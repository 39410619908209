import React from 'react';
import {
  Grid,
  CardContent,
  Typography,
  Card,
  Button,
  Box,
  LinearProgress,
} from '@material-ui/core';
import { useNavigate } from 'react-router';
import Calendar from 'components/Calendar/Calendar';
import SingleMetricCard from 'components/common/SingleMetricCard';
import AssignmentIcon from '@material-ui/icons/Assignment';
import { useIsFetching } from '@tanstack/react-query';
import MasonryGrid from 'components/Masonry/MasonryGrid';
import TaskList from 'components/ScheduledTasks/ScheduledTaskList';
import { endOfWeek, startOfWeek } from 'date-fns';

interface Props {
  overviewData?: {
    userJobCount?: any; // @todo
  };
}

const TechnicianGridItems = ({ overviewData }: Props) => {
  const navigate = useNavigate();
  const isFetchingCalendar = useIsFetching(['calendar-events', 'today']);
  const screenWidthToColumns = {
    xs: [12],
    sm: [6, 6],
    md: [6, 6],
    lg: [4, 8],
    xl: [4, 8],
  };

  return (
    <>
      <MasonryGrid screenWidthToColumns={screenWidthToColumns}>
        <Grid item>
          <SingleMetricCard
            metric={overviewData?.userJobCount}
            label="CURRENTLY ASSIGNED JOBS"
            description="The number of jobs with tasks that you have been assigned to."
            icon={<AssignmentIcon />}
          />
        </Grid>
        <Grid item>
          <TaskList
            filters={{
              tasksForUser: true,
              start: startOfWeek(new Date()).toISOString(),
              end: endOfWeek(new Date()).toISOString(),
            }}
            label="All Scheduled Tasks"
            userIsTechnician={true}
            renderForDashboard={true}
          />
        </Grid>
        <Grid item>
          <Card>
            {isFetchingCalendar > 0 && <LinearProgress />}
            <CardContent>
              <Box mb={2}>
                <Typography variant="overline">Your day at a glance</Typography>
                <Typography variant="h4" gutterBottom>
                  Scheduled tasks for Today
                </Typography>
              </Box>
              <Calendar
                showToolbar={false}
                defaultView={'day'}
                step={30}
                // min={new Date('January 1, 2020 8:00:00')}
                // max={new Date('January 1, 2020 17:00:00')}
              />
              <Button
                variant="outlined"
                style={{ marginTop: '2rem' }}
                size="small"
                color="primary"
                onClick={() => navigate('/calendar')}>
                Open calendar
              </Button>
            </CardContent>
          </Card>
        </Grid>
      </MasonryGrid>
    </>
  );
};

export default TechnicianGridItems;
