import { nextDay } from 'date-fns';

export function hourMinuteTimeToISO(hourMinute: string, date: string): string {
  console.log('hourMinute', hourMinute, 'date', date);
  const newDate = new Date(date);
  const [hours, minutes] = hourMinute.split(':').map(Number);
  const newTime = new Date(newDate.setHours(hours, minutes));

  return newTime.toISOString();
}

export function generateSampleDatesForByWeekDay(
  byWeekDay: string[],
  baseDate: Date
) {
  if (byWeekDay.length === 0) return [];
  const sampleDates = byWeekDay.map((day) => {
    return nextDay(baseDate, daysOfWeek[day]);
  });
  return sampleDates;
}

export function extractByWeekDaysFromSampleDates(sampleDates: Date[]) {
  if (!sampleDates) return [];
  const byWeekDay = sampleDates?.map((date) => {
    const day = new Date(date).getDay();
    for (const [key, value] of Object.entries(daysOfWeek)) {
      if (value === day) {
        return key;
      }
    }
    return '';
  });
  return byWeekDay;
}

export const daysOfWeek = {
  Sunday: 0,
  Monday: 1,
  Tuesday: 2,
  Wednesday: 3,
  Thursday: 4,
  Friday: 5,
  Saturday: 6,
};
