import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import queryClient from 'state/queryClient';
import { QueryClientProvider } from '@tanstack/react-query';
import ServiceWorkerWrapper from './ServiceWorkerWrapper';
import './assets/scss/index.scss';
// import * as Sentry from '@sentry/react';
// import config from 'util/config-loader';
import OfflineWarning from 'OfflineWarning';

// Sentry.init({
//   integrations: [new Sentry.BrowserTracing(), new Sentry.Replay()],
//   ...config.SENTRY_CONFIG,
// });

ReactDOM.render(
  <>
    <QueryClientProvider client={queryClient}>
      <App />
    </QueryClientProvider>
    <ServiceWorkerWrapper />
    <OfflineWarning />
  </>,
  document.getElementById('root')
);
