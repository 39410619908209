import Axios from 'axios';
import { User } from './userApi';
import { Job } from './jobApi';
interface JobKPIData {
  id: string;
  organizationId: number;
  jobId: string;
  job: Job;
  checkpoint_JobCreation: string;
  checkpoint_PropertyOwnerContacted: string | null;
  propertyOwnerContacted_UpdatedOn: string | null;
  propertyOwnerContacted_UpdateByUserId: string | null;
  propertyOwnerContacted_UpdateByUser: User | null;
  checkpoint_TimeOfAttendance: string | null;
  timeOfAttendance_UpdatedOn: string | null;
  timeOfAttendance_UpdateByUserId: string | null;
  timeOfAttendance_UpdateByUser: User | null;
  checkpoint_ReportSubmitted: string | null;
  reportSubmitted_UpdatedOn: string | null;
  reportSubmitted_UpdateByUserId: string | null;
  reportSubmitted_UpdateByUser: User | null;
  checkpoint_JobCompletion: string | null;
  jobCompletion_UpdatedOn: string | null;
  jobCompleted_UpdateByUserId: string | null;
  jobCompleted_UpdateByUser: User | null;
  isOrdered?: boolean;
  isCheckPointMissing?: boolean;
  isJobCancelled?: boolean;
}

export enum Checkpoints {
  JobCreation = "Job Creation",
  PropertyOwnerContacted = "Property Owner Contacted",
  TimeOfAttendance = "Time of Attendance",
  ReportSubmitted = "Report Submitted",
  JobCompletion = "Job Completion",
}

export async function getJobKPIData(jobId: string): Promise<JobKPIData> {
  const res = await Axios.get(`/api/v1/job-kpi/${jobId}`);
  return res.data;
}

export async function updateJobKPIData({
  id,
  data,
}: {
  id: string;
  data: {
    checkpoint_Timestamp: string;
    checkpoint: Checkpoints;
  };
}): Promise<JobKPIData> {
  const res = await Axios.put(`/api/v1/job-kpi/${id}`, data);
  return res.data;
}

export async function removeKPIData({
  id,
  checkpoint,
}: {
  id: string;
  checkpoint: Checkpoints;
}): Promise<JobKPIData> {
  const res = await Axios.put(`/api/v1/job-kpi/remove/${id}`, {
    checkpoint: checkpoint,
  });
  return res.data;
}
