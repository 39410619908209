import React, { useContext, useState } from 'react';
import {
  makeStyles,
  TextField,
  Button,
  CircularProgress,
  Typography,
  useTheme,
  useMediaQuery,
  InputAdornment,
  IconButton,
} from '@material-ui/core';
import { useForm } from 'react-hook-form';
import { currentUserDetails, login } from 'api/userApi';
import { useNavigate, useLocation } from 'react-router';
import { Link } from 'react-router-dom';
import Alert from '@material-ui/lab/Alert';
import AuthPageContainer from './AuthPageContainer';
import UserContext from 'state/UserContext';
import { Visibility, VisibilityOff } from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
  input: {
    marginTop: theme.spacing(3),
  },
  submitBtn: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(2),
  },
  spinner: {
    color: 'inherit',
    marginLeft: theme.spacing(2),
  },
  errorAlert: {
    marginBottom: theme.spacing(3),
  },
}));

const LoginPage = () => {
  const classes = useStyles();
  const { register, handleSubmit, errors } = useForm({
    mode: 'all',
  });
  const [submitting, setSubmitting] = useState(false);
  const [submitErr, setSubmitErr] = useState<string>();
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();
  const { setUser } = useContext(UserContext);
  const { state } = useLocation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const redirectAfterLogin = (state as any)?.redirect || '/';

  async function onLoginSubmit(formData: { email: string; password: string }) {
    setSubmitting(true);
    setSubmitErr(null);
    try {
      const { email, password } = formData;
      const { token } = await login(email, password);
      localStorage.setItem('token', token);
      const user = await currentUserDetails();
      setUser({ status: 'success', ...user });
      setSubmitting(false);
      navigate(redirectAfterLogin, { replace: true });
    } catch (err) {
      setSubmitting(false);
      setSubmitErr('Something went wrong');
    }
  }

  const ErrorAlert = () => (
    <Alert severity="error" className={classes.errorAlert}>
      <Typography variant="h5">Invalid Email or Password</Typography>
      <Typography variant="body2">
        Please re-check your credentials and try again.
        <br />
        Forgot your password?{' '}
        <Link to="/forgot-password">Click here to reset your password</Link>
      </Typography>
    </Alert>
  );

  return (
    <AuthPageContainer
      title="Log in to your account"
      subheader=""
      isLoading={submitting}>
      {submitErr && <ErrorAlert />}
      <form onSubmit={handleSubmit(onLoginSubmit)}>
        <TextField
          className={classes.input}
          name="email"
          variant="outlined"
          label="Email"
          type="email"
          autoComplete="email"
          fullWidth
          placeholder="Your Email Address"
          disabled={submitting}
          error={!!errors.email}
          helperText={errors.email && errors.email?.message}
          inputRef={register({ required: '*Required' })}
          inputProps={{ 'data-cy': 'email' }}
        />

        <TextField
          className={classes.input}
          name="password"
          autoComplete="current-password"
          variant="outlined"
          label="Password"
          type={showPassword ? 'text' : 'password'}
          fullWidth
          placeholder="Password"
          disabled={submitting}
          error={!!errors.password}
          helperText={errors.password && errors.password?.message}
          inputRef={register({
            required: '*Required',
          })}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={() => setShowPassword((t) => !t)}
                  onMouseDown={(e) => {
                    e.preventDefault();
                  }}>
                  {showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            ),
          }}
          inputProps={{ 'data-cy': 'password' }}
        />
        <span data-cy="fogot-password">
          <Link to="/forgot-password">
            <Typography variant="body2" style={{ marginTop: '1rem' }}>
              Forgot your password?
            </Typography>
          </Link>
        </span>
        <Button
          type="submit"
          variant="contained"
          color="primary"
          fullWidth
          size="large"
          disabled={submitting}
          className={classes.submitBtn}
          data-cy={'loginButton'}>
          {submitting ? 'Logging in... Please wait' : 'Log In'}
          {isMobile && submitting && (
            <CircularProgress size={16} className={classes.spinner} />
          )}
        </Button>
      </form>
      <Typography variant="body2" style={{ marginTop: '1rem' }}>
        Don't have an account?{' '}
        <span data-cy="user-register">
          <Link to="/register">
            <>Register here</>
          </Link>
        </span>
      </Typography>
    </AuthPageContainer>
  );
};

export default LoginPage;
