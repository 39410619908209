import React, { useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  makeStyles,
  Typography,
  Snackbar,
  CircularProgress,
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import Alert from '@material-ui/lab/Alert';
import DeleteButton from 'components/common/DeleteButton';
import queryClient from 'state/queryClient';

const useStyles = makeStyles((theme) => ({
  deleteBtn: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
}));

interface Props {
  open: boolean;
  title: string;
  content: any;
  deleteFunc: () => Promise<void>;
  onSuccess?: () => void;
  handleReject: () => void;
  handleClose: () => void;
  deleteButtonDisabled?: boolean;
}

const ConfirmDeleteDialog = ({
  open,
  title,
  content,
  handleClose,
  deleteFunc,
  handleReject,
  onSuccess = () => {},
  deleteButtonDisabled,
}: Props) => {
  const classes = useStyles();
  const [submitting, setSubmitting] = useState(false);
  const [snackbarState, setSnackbarState] = useState({
    open: false,
    message: '',
  });

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      open={open}
      onClose={handleClose}
      aria-labelledby={title + '-dialog'}>
      <DialogTitle id={title + '-dialog'}>
        <Typography variant="h4">{title}</Typography>
      </DialogTitle>
      <DialogContent dividers>
        <DialogContentText>{content}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          disabled={submitting}
          variant="outlined"
          onClick={() => {
            handleReject();
            handleClose();
          }}
          color="secondary">
          Cancel
        </Button>
        <DeleteButton
          endIcon={
            submitting ? (
              <CircularProgress size={14} color="inherit" />
            ) : (
              <DeleteIcon />
            )
          }
          disabled={submitting || deleteButtonDisabled}
          onClick={async () => {
            try {
              setSubmitting(true);
              await deleteFunc();
              await queryClient.refetchQueries(['org-storage-usage']);
              handleClose();
              onSuccess();
            } catch (err) {
              setSnackbarState({
                open: true,
                message: err?.response?.data?.toString(),
              });
            } finally {
              setSubmitting(false);
            }
          }}
          className={classes.deleteBtn}>
          {submitting ? 'Deleting..' : 'Delete'}
        </DeleteButton>
      </DialogActions>
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        open={snackbarState.open}
        onClose={() => setSnackbarState({ open: false, message: '' })}>
        <Alert severity="error" variant="filled">
          <Typography variant="body1" gutterBottom color="inherit">
            Error: Failed to delete resource.
          </Typography>
          {snackbarState.message}
        </Alert>
      </Snackbar>
    </Dialog>
  );
};

export default ConfirmDeleteDialog;
