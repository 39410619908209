import { Box, Button, IconButton } from '@material-ui/core';
import { getTaskAttachmentPreviewLink } from 'api/calendarApi';
import DownloadIcon from '@mui/icons-material/Download';
import CloseIcon from '@mui/icons-material/Close';

import React from 'react';

const FileChip = (props: { fileData; handleClose?: () => void }) => {
  const maxLength = 30;

  function truncateText(text, maxLength) {
    if (text.length <= maxLength) {
      return text;
    }
    return text.slice(0, maxLength) + '...';
  }

  async function downloadUploadedFile() {
    const signedFile = await getTaskAttachmentPreviewLink(
      props.fileData.taskId,
      props.fileData.id
    );

    const link = document.createElement('a');
    link.href = signedFile.url;
    link.download = props.fileData.name;
    link.target = '_blank'; // Open in a new tab/window
    link.style.display = 'none';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  return (
    <Box
      style={{
        borderRadius: '2em',
        padding: '0.3em 1em',
        borderWidth: '1px',
        margin: '0.5em',
        borderColor: '#bbb',
        borderStyle: 'solid',
        width: 'fit-content',
      }}>
      <Button
        size="small"
        style={{ fontSize: '0.9em' }}
        variant="text"
        onClick={async () => {
          await downloadUploadedFile();
        }}
        startIcon={<DownloadIcon />}>
        {truncateText(props.fileData?.name, maxLength)}
      </Button>
      {props.handleClose && (
        <IconButton size="small" onClick={() => props.handleClose()}>
          <CloseIcon />
        </IconButton>
      )}
    </Box>
  );
};

export default FileChip;
