import { createMuiTheme } from '@material-ui/core/styles';
import palette from './palette';
import typography from './typography';
import overrides from './overrides';
import { Shadows } from '@material-ui/core/styles/shadows';

const theme = createMuiTheme({
  palette,
  typography,
  overrides,
  zIndex: {
    appBar: 1200,
    drawer: 1100,
  },
  shadows: [
    'none',
    '0px 1px 5px rgba(193, 193, 193, 0.3)',
    '0px 2px 8px rgba(193, 193, 193, 0.3)',
    '0px 3px 20px rgba(193, 193, 193, 0.3)', //used in elevation 3
    '0px 4px 30px rgba(193, 193, 193, 0.3)', // use theme.shadows[4] to apply this shadow
    '0px -4px 30px rgba(193, 193, 193, 0.3)', // use theme.shadows[5] to apply this shadow
    ...Array(19).fill('none'),
  ] as Shadows,
});

export default theme;
