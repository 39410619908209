import { z } from 'zod';

const ClientConfiguration = z.object({
  GOOGLE_MAP_API_KEY: z.string(),
  API_BASE_URL: z.string(),
  SENTRY_CONFIG: z.object({
    dsn: z.string(),
    // Performance Monitoring
    tracesSampleRate: z.number(), // Capture 100% of the transactions, reduce in production!
    // Session Replay
    replaysSessionSampleRate: z.number(), // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: z.number(), // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  }),
});

export type ClientConfiguration = z.infer<typeof ClientConfiguration>;

const config = ClientConfiguration.parse(window.RESTORIZE_CONFIG);

export default config;
