import {
  startOfWeek,
  endOfMonth,
  endOfWeek,
  startOfDay,
  endOfDay,
  sub,
  add,
} from 'date-fns';
import queryClient from 'state/queryClient';
import { CalendarAPI } from 'api';

/** Note: Does not prefetch current month */
export function prefetchAndCacheMonthEvents(
  year: number,
  month: number,
  numberOfMonthsToPrefetch: number
) {
  for (let i = 1; i < numberOfMonthsToPrefetch; i++) {
    const prevMonth = new Date(year, month - i, 1);
    const prevMonthStart = startOfWeek(prevMonth);
    const prevMonthEnd = endOfWeek(endOfMonth(prevMonth));
    queryClient.prefetchQuery(
      ['calendar-events', prevMonthStart, prevMonthEnd],
      () => CalendarAPI.listEvents(prevMonthStart, prevMonthEnd)
    );

    const nextMonth = new Date(year, month + i, 1);
    const nextMonthStart = startOfWeek(nextMonth);
    const nextMonthEnd = endOfWeek(endOfMonth(nextMonth));
    queryClient.prefetchQuery(
      ['calendar-events', nextMonthStart, nextMonthEnd],
      () => CalendarAPI.listEvents(nextMonthStart, nextMonthEnd)
    );
  }
}

/** Note: Does not prefetch current days events */
export function prefetchAndCacheDayEvents(
  date: Date,
  numberOfDaysToPrefetch: number
) {
  for (let i = 1; i <= numberOfDaysToPrefetch; i++) {
    const prevDay = sub(date, { days: i });
    const prevDayStart = startOfDay(prevDay);
    const prevDayEnd = endOfDay(prevDay);
    queryClient.prefetchQuery(
      ['calendar-events', prevDayStart, prevDayEnd],
      () => CalendarAPI.listEvents(prevDayStart, prevDayEnd)
    );

    const nextDay = add(date, { days: i });
    const nextDayStart = startOfDay(nextDay);
    const nextDayEnd = endOfDay(nextDay);
    queryClient.prefetchQuery(
      ['calendar-events', nextDayStart, nextDayEnd],
      () => CalendarAPI.listEvents(nextDayStart, nextDayEnd)
    );
  }
}

/** Note: Does not prefetch current week's events */
export function prefetchAndCacheWeekEvents(
  date: Date,
  numberOfDaysToPrefetch: number
) {
  for (let i = 1; i <= numberOfDaysToPrefetch; i++) {
    const prevWeek = sub(date, { weeks: i });
    const prevWeekStart = startOfWeek(prevWeek);
    const prevWeekEnd = endOfDay(endOfWeek(prevWeek));
    queryClient.prefetchQuery(
      ['calendar-events', prevWeekStart, prevWeekEnd],
      () => CalendarAPI.listEvents(prevWeekStart, prevWeekEnd)
    );

    const nextWeek = add(date, { weeks: i });
    const nextWeekStart = startOfWeek(nextWeek);
    const nextWeekEnd = endOfDay(endOfWeek(nextWeek));
    queryClient.prefetchQuery(
      ['calendar-events', nextWeekStart, nextWeekEnd],
      () => CalendarAPI.listEvents(nextWeekStart, nextWeekEnd)
    );
  }
}
