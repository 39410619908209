import React, { MouseEventHandler } from 'react';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import IconButton from '@material-ui/core/IconButton';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { ListItemIcon, ListItemText } from '@material-ui/core';

export interface MenuOption {
    label: string;
    setDialogOpen: any;
    icon?: React.ReactNode;
}

export interface MenuProps {
    options?: MenuOption[];
}

const ExpandMenuButton = ({ options }: MenuProps) => {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <div>
            <IconButton
                style={{ padding: 5 }}
                aria-label="more"
                aria-controls="long-menu"
                aria-haspopup="true"
                onClick={handleClick}>
                <MoreVertIcon />
            </IconButton>
            <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}>
                {options.map((option, i) => {
                    return (
                        <>
                            <MenuItem
                                key={i}
                                onClick={() => {
                                    option.setDialogOpen(true);
                                }}
                                style={{ paddingLeft: 20, paddingRight: 20 }}>
                                {option.icon && (
                                    <ListItemIcon
                                        style={{
                                            minWidth: 0,
                                            paddingRight: 8,
                                        }}>
                                        {option.icon}
                                    </ListItemIcon>
                                )}
                                <ListItemText>{option.label}</ListItemText>
                            </MenuItem>
                        </>
                    );
                })}
            </Menu>
        </div>
    );
};

export default ExpandMenuButton;
