import React, { useContext, useState } from 'react';
import {
  makeStyles,
  TextField,
  Button,
  CircularProgress,
  Typography,
  FormControlLabel,
  Checkbox,
  InputAdornment,
} from '@material-ui/core';
import { useForm } from 'react-hook-form';
import {
  NewRegistration,
  newRegistration,
  currentUserDetails,
} from 'api/userApi';
import { useNavigate } from 'react-router';
import { Link } from 'react-router-dom';
import Alert from '@material-ui/lab/Alert';
import AuthPageContainer from './AuthPageContainer';
import UserContext from 'state/UserContext';
import { IconButton } from '@mui/material';
import { Visibility, VisibilityOff } from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
  input: {
    marginTop: theme.spacing(3),
  },
  submitBtn: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
  },
  spinner: {
    color: 'inherit',
    marginLeft: theme.spacing(2),
  },
  errorAlert: {
    marginBottom: theme.spacing(3),
  },
  agreeTerms: {
    marginTop: theme.spacing(4),
  },
}));

const RegisterPage = () => {
  const classes = useStyles();
  const { register, handleSubmit, errors } = useForm({
    mode: 'all',
  });
  const [submitting, setSubmitting] = useState(false);
  const [submitErr, setSubmitErr] = useState<string>();
  const [showPassword, setShowPassword] = useState(false);
  const [agreeTerms, setAgreeTerms] = useState(false);

  const { setUser } = useContext(UserContext);
  const navigate = useNavigate();

  async function onLoginSubmit(data: NewRegistration) {
    setSubmitting(true);
    setSubmitErr(null);
    try {
      const { token } = await newRegistration(data);
      localStorage.setItem('token', token);
      const user = await currentUserDetails();
      setUser({ ...user, status: 'success' });
      navigate('/');
    } catch (err) {
      setSubmitErr('Something went wrong');
    } finally {
      setSubmitting(false);
    }
  }

  const ErrorAlert = () => (
    <Alert severity="error" className={classes.errorAlert}>
      <Typography variant="h5">
        Sorry, we couldn't create your account
      </Typography>
      <Typography variant="body2">
        Please re-check your details and try again.
        <br />
        Already have an account with this Email?{' '}
        <Link to="/login">Log in here</Link>
      </Typography>
    </Alert>
  );

  return (
    <AuthPageContainer
      title="Create a new account"
      subheader="Create your free Restorize Account"
      isLoading={submitting}>
      {submitErr && <ErrorAlert />}
      <form onSubmit={handleSubmit(onLoginSubmit)}>
        <TextField
          name="firstName"
          variant="outlined"
          label="First Name"
          fullWidth
          placeholder="Your First or Given Name"
          autoComplete="given-name"
          disabled={submitting}
          error={!!errors.firstName}
          helperText={errors.firstName && errors.firstName?.message}
          inputRef={register({
            required: '* Your first name is required',
            maxLength: {
              value: 50,
              message: 'Cannot exceed 50 characters',
            },
          })}
          inputProps={{ 'data-cy': 'register-form-first-name' }}
        />
        <TextField
          className={classes.input}
          name="lastName"
          variant="outlined"
          label="Last Name"
          fullWidth
          placeholder="Your Last Name"
          autoComplete="family-name"
          disabled={submitting}
          error={!!errors.lastName}
          helperText={errors.lastName && errors.lastName?.message}
          inputRef={register({
            required: '* Your last name/surname is required',
            maxLength: {
              value: 50,
              message: 'Cannot exceed 50 characters',
            },
          })}
          inputProps={{ 'data-cy': 'register-form-last-name' }}
        />
        <TextField
          className={classes.input}
          name="organizationName"
          variant="outlined"
          label="Organization"
          fullWidth
          placeholder="Your Organization's Name"
          autoComplete="organization"
          disabled={submitting}
          error={!!errors.organizationName}
          helperText={
            errors.organizationName && errors.organizationName?.message
          }
          inputRef={register({
            required: '* The Organization name is required',
            maxLength: {
              value: 100,
              message: 'Cannot exceed 100 characters',
            },
          })}
          inputProps={{ 'data-cy': 'register-form-organization-name' }}
        />
        <TextField
          className={classes.input}
          inputProps={{ 'data-cy': 'register-form-email' }}
          name="email"
          variant="outlined"
          label="Email"
          type="email"
          autoComplete="email"
          fullWidth
          placeholder="Your Email Address"
          disabled={submitting}
          error={!!errors.email}
          helperText={errors.email && errors.email?.message}
          inputRef={register({ required: 'Your email is required' })}
        />
        <TextField
          className={classes.input}
          name="password"
          autoComplete="new-password"
          variant="outlined"
          label="Password"
          type={showPassword ? 'text' : 'password'}
          fullWidth
          placeholder="Password"
          disabled={submitting}
          error={!!errors.password}
          helperText={errors.password && errors.password?.message}
          inputRef={register({
            required: '* A password is required',
            minLength: {
              value: 8,
              message: 'Must be atleast 8 characters',
            },
          })}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={() => setShowPassword((t) => !t)}
                  onMouseDown={(e) => {
                    e.preventDefault();
                  }}>
                  {showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            ),
          }}
          inputProps={{ 'data-cy': 'register-form-password' }}
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={agreeTerms}
              onChange={() => setAgreeTerms((t) => !t)}
              size="small"
            />
          }
          label={
            <Typography variant="caption">
              I agree to the Restorize{' '}
              <a
                href="https://restorize.io/terms"
                target="_blank"
                rel="noreferrer">
                Terms of Service
              </a>{' '}
              and{' '}
              <a
                href="https://restorize.io/privacy-policy"
                target="_blank"
                rel="noreferrer">
                Privacy Policy
              </a>
            </Typography>
          }
          className={classes.agreeTerms}
        />
        <Button
          type="submit"
          variant="contained"
          color="primary"
          fullWidth
          size="large"
          disabled={submitting || !agreeTerms}
          className={classes.submitBtn}
          data-cy={'register-submit-btn'}>
          {submitting
            ? 'Creating your Account... Please wait'
            : 'Create Account'}
          {submitting && (
            <CircularProgress size={16} className={classes.spinner} />
          )}
        </Button>
      </form>
      <Typography variant="body2" style={{ marginTop: '1rem' }}>
        Already have an account?{' '}
        <span data-cy="register-page-login">
          <Link to="/login">Log in here</Link>
        </span>
      </Typography>
    </AuthPageContainer>
  );
};

export default RegisterPage;
