import { createMuiTheme } from '@material-ui/core';
const theme = createMuiTheme();
export default {
  MUIRichTextEditor: {
    root: {},
    editor: {
      padding: '10px',
      border: `1px solid ${theme.palette.divider}`,
      borderRadius: theme.shape.borderRadius,
      minHeight: '200px',
    },
    placeHolder: {
      padding: theme.spacing(2),
    },
  },
} as const;
