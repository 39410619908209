import Axios from 'axios';
import { User } from './userApi';

export interface Client {
  id?: string;
  createdOn: string;
  updatedOn?: string;
  createdByUserId: string;
  createdByUser?: User;
  updatedByUserId?: string;
  updatedByUser?: User;
  organizationId: number;
  companyName: string;
  legalName: string;
  address: string;
  primaryContact: string;
  contactNumber?: string;
  contactEmail: string;
  logo?: string;
}

export interface Contact {
  id: string;
  client: Client;
  clientId: string;
  name: string;
  email: string;
  contactNumber?: string;
  jobTitle?: string;
  isPrimaryContact: boolean;
}

export interface ClientWithDetails extends Client {
  createdByUser: User;
  updatedByUser?: User;
}

export async function listClients(): Promise<Client[]> {
  const res = await Axios.get('/api/v1/client');
  return res.data;
}

export async function getClientByID(id: string): Promise<ClientWithDetails> {
  const res = await Axios.get(`/api/v1/client/${id}`);
  return res.data;
}

export async function createClient(data: {
  companyName: string;
  legalName: string;
  address: string;
  primaryContact: string;
  contactEmail: string;
  contactNumber?: string;
  logoFile?: File;
}): Promise<Client> {
  const formData = new FormData();
  Object.keys(data).forEach(
    (key) => Boolean(data[key]) && formData.append(key, data[key])
  );
  const res = await Axios.post('/api/v1/client', formData);
  return res.data;
}

export async function updateClient(data: {
  id: string;
  companyName?: string;
  legalName?: string;
  address?: string;
  primaryContact?: string;
  contactEmail?: string;
  contactNumber?: string;
  logoFile?: File;
}): Promise<Client> {
  const { id, ...updateData } = data;
  const formData = new FormData();
  Object.keys(updateData).forEach(
    (key) => Boolean(data[key]) && formData.append(key, data[key])
  );
  const res = await Axios.put(`/api/v1/client/${id}`, formData);
  return res.data;
}

export async function deleteClient(id: string): Promise<void> {
  await Axios.delete(`/api/v1/client/${id}`);
}

export async function listClientContacts(id: string): Promise<Contact[]> {
  const res = await Axios.get(`/api/v1/client/${id}/contacts`);
  return res.data;
}

export async function listClientJobs(id: string): Promise<Contact[]> {
  const res = await Axios.get(`/api/v1/client/${id}/jobs`);
  return res.data;
}

export async function createNewContact(clientId: string, data: Contact) {
  const res = await Axios.post(`/api/v1/client/${clientId}/contacts`, data);
  return res.data;
}

export async function updateContact(
  clientId: string,
  contactId: string,
  data: Partial<Contact>
) {
  const res = await Axios.put(
    `/api/v1/client/${clientId}/contacts/${contactId}`,
    data
  );
  return res.data;
}

export async function deleteContact(clientId: string, contactId: string) {
  const res = await Axios.delete(
    `/api/v1/client/${clientId}/contacts/${contactId}`
  );
  return res.data;
}
