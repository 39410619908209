import { useQuery } from '@tanstack/react-query';
import { OrganizationPricing, getOrgStorageUsage } from 'api/organizationApi';
import { useMemo } from 'react';

function useOrgStorage() {
  const { data: orgStorageUsageData } = useQuery(
    ['org-storage-usage'],
    getOrgStorageUsage,
    { staleTime: 0 }
  );

  const isFreePlanExceeded = useMemo(() => {
    return (
      orgStorageUsageData?.pricingPlan === OrganizationPricing.Enum.FREE &&
      orgStorageUsageData?.remainingStorage <= 0
    );
  }, [orgStorageUsageData]);

  return { orgStorageUsageData, isFreePlanExceeded };
}

export default useOrgStorage;
