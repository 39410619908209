import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import Topbar from './Topbar';

const useStyles = makeStyles(() => ({
  root: {
    paddingTop: 50,
    height: '100%',
  },
  content: {
    height: '100%',
  },
}));

interface Props {
  children: JSX.Element | JSX.Element[];
  className?: string;
}

const MinimalLayout = ({ children, className }: Props) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Topbar />
      <main className={clsx(classes.content, className)}>{children}</main>
    </div>
  );
};

export default MinimalLayout;
