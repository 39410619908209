import Axios from 'axios';
import { User } from './userApi';

export interface EquipmentCategory {
  id?: string;
  equipmentItems?: EquipmentItem[];
  name: string;
  description?: string;
  totalStock?: number;
  numCheckedOut?: number;
}

export interface EquipmentItem {
  id?: string;
  categoryId: string;
  referenceId: string;
  model?: string;
  make?: string;
  equipmentHistoryLogs?: EquipmentItemHistory[];
  location?: string;
  state: string;
  country: string;
  tagId: string;
}

export interface EquipmentItemGroup {
  categoryId: string;
  referenceIdList: string[];
  model?: string;
  make?: string;
}

export interface EquipmentItemHistory {
  id?: string;
  equipmentItem: EquipmentItem;
  userId: string;
  jobId: string;
  updatedOn: string;
  IsACheckOut: boolean;
  createdByUser: User;
  createdByUserId: string;
}

export async function createNewEquipmentCategory({
  data,
}: {
  data: EquipmentCategory;
}) {
  const res = await Axios.post(`/api/v1/equipment/category`, { ...data });
  return res.data;
}

export async function updateEquipmentCategory(
  id: string,
  data: Partial<EquipmentCategory>
) {
  console.log('Updating item: ', id);
  const res = await Axios.put(`/api/v1/equipment/category/${id}`, data);
  return res.data;
}

export async function deleteEquipmentCategory(categoryId: string) {
  await Axios.delete(`/api/v1/equipment/${categoryId}`);
}

export async function createNewEquipmentItem({
  data,
}: {
  data: EquipmentItem;
}) {
  const res = await Axios.post(`/api/v1/equipment/item`, { ...data });
  return res.data;
}

export async function updateEquipmentItem(
  id: string,
  data: Partial<EquipmentItem>
) {
  const res = await Axios.put(`/api/v1/equipment/item/${id}`, data);
  return res.data;
}

export async function deleteEquipmentItem(categoryId: string, itemId: string) {
  await Axios.delete(`/api/v1/equipment/category/${categoryId}/item/${itemId}`);
}

export async function listCategoryData(): Promise<EquipmentCategory[]> {
  const res = await Axios.get('/api/v1/equipment/category-list');
  return res.data;
}

export async function getCategoryById(id: string): Promise<any> {
  const res = await Axios.get(`/api/v1/equipment/category/${id}`);
  return res.data;
}

export async function saveEquipmentByCSV({
  file,
  country,
  state,
}: {
  file: File;
  country: string;
  state: string;
}): Promise<any> {
  const formData = new FormData();
  formData.append('file', file);
  formData.append('country', country);
  formData.append('state', state);
  const res = await Axios.post(`/api/v1/equipment/import`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });

  return res.data;
}
