import React, { useState } from 'react';
import {
  Typography,
  Divider,
  Button,
  makeStyles,
  Box,
  LinearProgress,
  Card,
  CardContent,
  List,
  ListItem,
  ListItemText,
  Chip,
  Tooltip,
  FormControlLabel,
  Switch,
  IconButton,
  ListItemSecondaryAction,
  Menu,
  MenuItem,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '@material-ui/core';
import { useQuery } from '@tanstack/react-query';
import { CalendarAPI } from 'api';
import { format, parseISO, isBefore } from 'date-fns';
import { ScheduledTask } from 'api/jobApi';
import PopupState, { bindMenu, bindTrigger } from 'material-ui-popup-state';
import AddOrEditTask from './AddOrEditScheduledTask';
import AddIcon from '@material-ui/icons/Add';
import AvatarGroup from '@material-ui/lab/AvatarGroup';
import Alert from '@material-ui/lab/Alert';
import UserAvatar from 'components/Users/UserAvatar';
import RefreshIcon from '@material-ui/icons/Refresh';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import ConfirmTaskDeleteDialog from './ConfirmTaskDeleteDialog';
import AuthContainer from 'components/AuthContainer/AuthContainer';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const useStyles = makeStyles((theme) => ({
  newBtn: {
    marginLeft: 'auto',
  },
  list: {
    width: '100%',
  },
  listItem: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    justifyItems: 'flex-start',
  },
  listItemAction: {
    right: 0,
    top: theme.spacing(3),
  },
  avatarGroup: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  userAvatar: {
    boxShadow: theme.shadows[3],
  },
  pastSwitch: {
    margin: theme.spacing(1),
  },
  dateChip: {
    borderRadius: theme.shape.borderRadius,
    marginRight: theme.spacing(1),
    marginTop: theme.spacing(0.5),
  },
  timeChip: {
    borderRadius: theme.shape.borderRadius,
    marginTop: theme.spacing(0.5),
  },
  noTasksAlert: {
    marginTop: theme.spacing(2),
  },
  refreshBtn: {
    marginRight: theme.spacing(2),
  },
}));

interface Props {
  filters?: CalendarAPI.TaskListFilters;
  label?: string;
  userIsTechnician?: boolean;
  renderForDashboard?: Boolean;
}

const TaskList = ({
  filters = {},
  label,
  userIsTechnician,
  renderForDashboard,
}: Props) => {
  const classes = useStyles();
  const [showPast, setShowPast] = useState(false);

  const [drawerState, setDrawerState] = useState<{
    open: boolean;
    selectedTask?: ScheduledTask;
  }>({ open: false });

  const [deleteDialogState, setDeleteDialogState] = useState<{
    open: boolean;
    selectedTask?: ScheduledTask;
  }>({ open: false });

  const {
    data: tasks,
    isFetching,
    refetch,
  } = useQuery(['scheduled-tasks', filters], () =>
    CalendarAPI.listScheduledTasks(filters)
  );

  const currentDate = new Date();

  return (
    <>
      {isFetching && <LinearProgress />}
      <Card>
        <CardContent>
          <Box display="flex" mb={2}>
            <Typography variant="h5" style={{ flexGrow: 1 }}>
              {label ?? 'Scheduled Tasks'}
            </Typography>
            <IconButton
              onClick={() => refetch()}
              size="small"
              className={classes.refreshBtn}>
              <RefreshIcon fontSize="small" />
            </IconButton>
            {!userIsTechnician && (
              <Button
                startIcon={<AddIcon />}
                variant="contained"
                className={classes.newBtn}
                onClick={() => setDrawerState({ open: true })}
                color="primary"
                size="small">
                new task
              </Button>
            )}
          </Box>
          <Divider />
          {tasks?.length !== 0 && (
            <>
              <FormControlLabel
                className={classes.pastSwitch}
                control={
                  <Switch
                    color="primary"
                    checked={showPast}
                    onChange={() => setShowPast((s) => !s)}
                  />
                }
                label={'Include past tasks'}
              />
              <Divider />
            </>
          )}
          <List className={classes.list}>
            {(!tasks || tasks.length === 0) && (
              <Alert
                icon={false}
                severity="info"
                className={classes.noTasksAlert}>
                No scheduled tasks yet
              </Alert>
            )}
            {tasks &&
              tasks.map((task, i) => {
                let parsedEnd;
                if (task.isRecurring && task?.recurringData?.endsOn) {
                  parsedEnd = parseISO(task.recurringData.endsOn.toString());
                } else {
                  parsedEnd = parseISO(task.end);
                }

                let isPastTask = isBefore(parsedEnd, currentDate);
                if (task.isRecurring && !task?.recurringData?.endsOn) {
                  isPastTask = false;
                }
                if (!showPast && isPastTask) {
                  return null;
                }
                const parsedStart = parseISO(task.start);
                const date = format(parsedStart, 'MMM dd yyyy');
                const startTime = format(parsedStart, 'hh:mm a');
                const endTime = format(parsedEnd, 'hh:mm a');
                const createdByUserName = `${task.createdByUser?.firstName} ${task.createdByUser?.lastName}`;
                const timePeriod = `${startTime} to ${endTime}`;
                return (
                  <div key={i}>
                    <ListItem className={classes.listItem}>
                      <ListItemText
                        primary={
                          <div>
                            <Typography
                              variant="h6"
                              gutterBottom={userIsTechnician}>
                              <strong>{task.name}</strong>
                            </Typography>
                            {renderForDashboard && task?.job && (
                              <div
                                style={{
                                  display: 'flex',
                                  flexDirection: 'column',
                                  gap: 2,
                                  marginBottom: 5,
                                }}>
                                <div
                                  style={{
                                    gap: 10,
                                    display: 'flex',
                                    marginBottom: 5,
                                  }}>
                                  <Chip
                                    size="small"
                                    variant="outlined"
                                    className={classes.timeChip}
                                    label={
                                      task.job?.client
                                        ? task.job?.client?.companyName
                                        : task.job?.contactName
                                    }
                                  />
                                  <Chip
                                    size="small"
                                    variant="outlined"
                                    className={classes.timeChip}
                                    label={
                                      task?.organization?.identifier
                                        ? `${task?.organization?.identifier} - ${task?.job?.jobNo}`
                                        : task?.job?.jobNo
                                    }
                                  />
                                </div>
                                <Typography variant="body2">
                                  {task.job?.siteAddress}
                                </Typography>
                              </div>
                            )}

                            <Typography variant="body2" gutterBottom>
                              {task?.description}
                            </Typography>
                            <Box my={1}>
                              <Chip
                                size="small"
                                variant="default"
                                className={classes.dateChip}
                                label={isPastTask ? <s>{date}</s> : date}
                              />
                              <Chip
                                size="small"
                                variant="outlined"
                                className={classes.timeChip}
                                label={
                                  isPastTask ? <s>{timePeriod}</s> : timePeriod
                                }
                              />
                            </Box>
                            {task.isRecurring && (
                              <Box
                                style={{
                                  marginTop: '1rem',
                                  marginBottom: '1rem',
                                }}>
                                <Accordion
                                  variant="elevation"
                                  style={{ paddingTop: 0 }}>
                                  <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header">
                                    <Chip
                                      size="small"
                                      variant="default"
                                      className={classes.dateChip}
                                      label={
                                        <div>
                                          <strong>
                                            This is a recurring task
                                          </strong>
                                        </div>
                                      }
                                    />
                                  </AccordionSummary>
                                  <AccordionDetails style={{ paddingTop: 0 }}>
                                    <Typography
                                      variant="caption"
                                      style={{ color: '#000' }}>
                                      {`Starts on:
                                   ${new Date(
                                     task?.recurringData?.startsOn
                                   ).toDateString()}`}
                                    </Typography>
                                  </AccordionDetails>
                                  <AccordionDetails style={{ paddingTop: 0 }}>
                                    <Typography
                                      variant="body2"
                                      style={{ color: '#000' }}>
                                      {`Repeat: ${task?.recurringData?.recurringRuleText}`}
                                    </Typography>
                                  </AccordionDetails>
                                  <AccordionDetails style={{ paddingTop: 0 }}>
                                    <Typography
                                      variant="body2"
                                      style={{ color: '#000' }}>
                                      {`Ends on:
                                  ${
                                    task?.recurringData?.endsOn
                                      ? new Date(
                                          task?.recurringData?.endsOn
                                        ).toDateString()
                                      : 'Never'
                                  }`}
                                    </Typography>
                                  </AccordionDetails>
                                </Accordion>
                              </Box>
                            )}
                          </div>
                        }
                        secondary={
                          <div>
                            <AvatarGroup
                              max={5}
                              className={classes.avatarGroup}>
                              {task.taskAssignments?.map(({ user: u }, i) => {
                                return (
                                  <Tooltip
                                    key={i}
                                    title={
                                      <>
                                        <Typography
                                          variant="body1"
                                          color="inherit">
                                          {u.firstName} {u.lastName}
                                        </Typography>
                                        <Typography
                                          variant="caption"
                                          color="inherit">
                                          {u.email}
                                        </Typography>
                                      </>
                                    }>
                                    <UserAvatar
                                      size={30}
                                      user={u}
                                      fontSize={'12px'}
                                      className={classes.userAvatar}
                                    />
                                  </Tooltip>
                                );
                              })}
                            </AvatarGroup>
                            <Typography variant="body1">
                              Assigned to {task.taskAssignments.length} user
                              {task.taskAssignments.length > 1 && 's'}
                            </Typography>
                            <Typography variant="caption">
                              Scheduled by {createdByUserName}
                            </Typography>
                          </div>
                        }
                      />
                      <AuthContainer
                        restrictTo={[
                          'Administrator',
                          'ProjectManager',
                          'Supervisor',
                        ]}>
                        <ListItemSecondaryAction
                          className={classes.listItemAction}>
                          <PopupState variant="popover">
                            {(popupState) => (
                              <>
                                <IconButton
                                  {...bindTrigger(popupState)}
                                  size="small">
                                  <MoreVertIcon color="secondary" />
                                </IconButton>
                                <Menu {...bindMenu(popupState)}>
                                  <MenuItem
                                    onClick={() => {
                                      setDrawerState({
                                        open: true,
                                        selectedTask: task,
                                      });
                                      popupState.close();
                                    }}>
                                    Edit Task
                                  </MenuItem>
                                  <MenuItem
                                    onClick={() => {
                                      setDeleteDialogState({
                                        open: true,
                                        selectedTask: task,
                                      });
                                      popupState.close();
                                    }}>
                                    Delete Task
                                  </MenuItem>
                                </Menu>
                              </>
                            )}
                          </PopupState>
                        </ListItemSecondaryAction>
                      </AuthContainer>
                    </ListItem>
                    <Divider />
                  </div>
                );
              })}
          </List>
        </CardContent>
        {deleteDialogState.open && (
          <ConfirmTaskDeleteDialog
            open={true}
            handleClose={() => setDeleteDialogState({ open: false })}
            task={deleteDialogState.selectedTask}
          />
        )}
        {drawerState.open && (
          <AddOrEditTask
            jobId={filters.job?.id}
            editTaskDefaultValues={drawerState.selectedTask ?? undefined}
            handleClose={() => setDrawerState({ open: false })}
          />
        )}
      </Card>
    </>
  );
};

export default TaskList;
