import React from 'react';
import { ResponsivePie } from '@nivo/pie';
import {
  Card,
  CardContent,
  LinearProgress,
  Typography,
  Divider,
  useTheme,
  makeStyles,
  Grid,
} from '@material-ui/core';
import { StatusValue } from 'api/jobApi';
import useIsMobile from 'theme/useIsMobile';
import {
  FormControl,
  MenuItem,
  Select,
  SelectChangeEvent,
} from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { OverviewAPI } from '../../../api';
import { getStatusColor } from 'util/statusColors';

const useStyles = makeStyles(() => ({
  imageContainer: {
    height: '100%',
    verticalAlign: 'middle',
  },

  image: {
    height: '60%',
    width: '60%',
    marginTop: '20%',
    display: 'block',
    margin: 'auto',
  },

  halfWidth: {
    width: '50%',
  },

  filter: {
    width: '30%',
  },
}));

const CenteredMetric = ({ dataWithArc, centerX, centerY }) => {
  const total = dataWithArc.reduce(
    (acc: number, d: { value: number }) => acc + d.value,
    0
  );
  return (
    <text
      x={centerX}
      y={centerY}
      textAnchor="middle"
      dominantBaseline="central"
      style={{
        fontSize: '48px',
        fontWeight: 'bold',
      }}>
      {total}
    </text>
  );
};

const desktopMargins = { top: 110, right: 40, bottom: 20, left: 40 };
const tabletMargins = { top: 110, right: 40, bottom: 20, left: 40 };
const mobileMargins = { top: 50, right: 40, bottom: -40, left: 40 };

const pieColors = {
  [StatusValue.NOT_STARTED]: getStatusColor(StatusValue.NOT_STARTED, 200),
  [StatusValue.IN_PROGRESS]: getStatusColor(StatusValue.IN_PROGRESS, 700),
  [StatusValue.ON_HOLD]: getStatusColor(StatusValue.ON_HOLD, 300),
  [StatusValue.APPROVAL_PENDING]: getStatusColor(
    StatusValue.APPROVAL_PENDING,
    700
  ),
  [StatusValue.TO_BE_INVOICED]: getStatusColor(StatusValue.TO_BE_INVOICED, 600),
  [StatusValue.COMPLETED]: getStatusColor(StatusValue.COMPLETED, 800),
  [StatusValue.CANCELLED]: getStatusColor(StatusValue.CANCELLED, 700),
};

export enum JobRange {
  ALL = 'ALL',
  LAST_MONTH = 'LAST_MONTH',
  LAST_WEEK = 'LAST_WEEK',
  LAST_QUARTER = 'LAST_QUARTER',
  LAST_YEAR = 'LAST_YEAR',
}

interface Props {
  clientId?: string;
  label?: string;
}

const OverallJobProgressPieChart = ({ clientId, label }: Props) => {
  const classes = useStyles();
  const isMobile = useIsMobile('xs');
  const isTablet = useIsMobile();

  const chartMargins = isMobile
    ? mobileMargins
    : isTablet
    ? tabletMargins
    : desktopMargins;
  const theme = useTheme();

  const [selectedJobRange, setSelectedJobRange] = React.useState<JobRange>(
    JobRange.ALL
  );

  const {
    isFetching,
    data: fetchedData,
    refetch,
  } = useQuery(['job-status-by-period', clientId, selectedJobRange], () =>
    OverviewAPI.fetchJobStatusByPeriod(clientId, selectedJobRange)
  );

  const handleChange = (event: SelectChangeEvent) => {
    const jobRange = event.target.value as JobRange;
    setSelectedJobRange(jobRange);

    refetch();
  };

  return (
    <Card
      style={{
        height: '500px',
        paddingBottom: '2',
        borderRadius: '12px',
      }}>
      {isFetching && <LinearProgress />}
      <CardContent style={{ height: '90%' }}>
        <Grid
          container
          justify="space-between"
          direction="row"
          alignItems="center">
          <Grid item className={classes.halfWidth}>
            <Typography variant="h4" gutterBottom>
              {label ?? 'Jobs by Status'}
            </Typography>
          </Grid>
          <Grid item className={classes.filter}>
            <FormControl variant="standard" fullWidth>
              <Select
                labelId="job-range-select-label"
                id={'job-range-select'}
                value={selectedJobRange}
                onChange={handleChange}
                label={'Job Range'}>
                <MenuItem value={JobRange.ALL}>All Jobs</MenuItem>
                <MenuItem value={JobRange.LAST_WEEK}>1 Week</MenuItem>
                <MenuItem value={JobRange.LAST_MONTH}>1 Month</MenuItem>
                <MenuItem value={JobRange.LAST_QUARTER}>3 Months</MenuItem>
                <MenuItem value={JobRange.LAST_YEAR}>1 Year</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </Grid>

        <Divider />

        {fetchedData?.length == 0 ? (
          <img
            alt="blank canvas"
            src="/images/undraw_blank_canvas_3rbb.svg"
            className={classes.image}
          />
        ) : (
          fetchedData && (
            <ResponsivePie
              layers={[
                'slices',
                'sliceLabels',
                'radialLabels',
                'legends',
                CenteredMetric,
              ]}
              data={fetchedData}
              margin={chartMargins}
              innerRadius={0.85}
              padAngle={2}
              cornerRadius={2}
              colors={(d) => pieColors[d.id]}
              borderWidth={0}
              borderColor={{ from: 'color', modifiers: [['darker', 1]] }}
              enableRadialLabels={false}
              sliceLabelsSkipAngle={10}
              enableSliceLabels={true}
              sliceLabelsTextColor="#ffffff"
              fill={[
                {
                  match: { id: StatusValue.NOT_STARTED },
                  id: 'notStartedLines',
                },
                {
                  match: { id: StatusValue.IN_PROGRESS },
                  id: 'inProgressLines',
                },
                {
                  match: { id: StatusValue.COMPLETED },
                  id: 'completedLines',
                },
                {
                  match: { id: StatusValue.ON_HOLD },
                  id: 'onHoldLines',
                },
                {
                  match: { id: StatusValue.APPROVAL_PENDING },
                  id: 'approvalPendingLines',
                },
                {
                  match: { id: StatusValue.TO_BE_INVOICED },
                  id: 'toBeInvoicedLines',
                },
                {
                  match: { id: StatusValue.CANCELLED },
                  id: 'cancelledLines',
                },
              ]}
              legends={[
                {
                  anchor: 'top-left',
                  direction: 'column',
                  justify: false,
                  translateX: -40,
                  translateY: isMobile ? -30 : -80,
                  itemsSpacing: 0,
                  itemWidth: 100,
                  itemHeight: 18,
                  itemTextColor: '#999',
                  itemDirection: 'left-to-right',
                  itemOpacity: 0.85,
                  symbolSize: isMobile ? 12 : 16,
                  symbolShape: ({ x, y, size, fill }) => (
                    <rect
                      x={x}
                      y={y}
                      width={size}
                      height={size}
                      fill={fill}
                      rx={'2px'}
                      ry={'2px'}
                    />
                  ),
                  effects: [
                    {
                      on: 'hover',
                      style: {
                        itemTextColor: '#000',
                      },
                    },
                  ],
                },
              ]}
            />
          )
        )}
      </CardContent>
    </Card>
  );
};

export default OverallJobProgressPieChart;
