import Axios from 'axios';
import { Role } from 'models/Role';
import { Job, ScheduledTask, ScheduledTaskAttachmentDetails } from './jobApi';
import { User } from './userApi';

export type TaskListFilters = {
  job?: Job;
  tasksForUser?: Boolean;
  start?: string;
  end?: string;
};

export enum ApplyRecurringChangesFor {
  INDIVIDUAL = 'individual',
  THISANDFUTURE = 'thisAndFuture',
  ALL = 'all',
}

export async function listEvents(
  start: Date | string,
  end: Date | string
): Promise<ScheduledTask[]> {
  if (start instanceof Date) start = start.toISOString();
  if (end instanceof Date) end = end.toISOString();
  const res = await Axios.get(`/api/v1/calendar?start=${start}&end=${end}`);
  return res.data;
}

export async function listScheduledTasks(
  filters?: TaskListFilters
): Promise<ScheduledTask[]> {
  if (filters.start && filters.end) {
    const res = await Axios.get(
      `/api/v1/calendar/tasks?start=${filters.start}&end=${filters.end}`
    );
    return res.data;
  }
  if (filters.tasksForUser) {
    const res = await Axios.get(`/api/v1/calendar/tasks`);
    return res.data;
  }

  const res = await Axios.get(`/api/v1/calendar/job/${filters?.job.id}`);
  return res.data;
}

export async function createNewScheduledTask({
  data,
}: {
  data: ScheduledTask;
}) {
  const formData = new FormData();

  const propertiesToAppend = [
    'description',
    'end',
    'jobId',
    'name',
    'start',
    'recurringData',
  ];

  Object.keys(data).forEach((key) => {
    if (key === 'attachments') {
      data.attachments.forEach((file) => formData.append('attachments', file));
    } else if (key === 'assignedUserIds') {
      formData.append('assignedUserIds', JSON.stringify(data.assignedUserIds));
    } else if (key === 'recurringData') {
      formData.append('recurringData', JSON.stringify(data.recurringData));
    } else if (propertiesToAppend.includes(key) && data[key]) {
      formData.append(key, data[key]);
    }
  });
  const res = await Axios.post(`/api/v1/calendar`, formData, {
    headers: { 'content-type': 'multipart/form-data' },
  });
  return res.data;
}

export async function updateScheduledTask({
  taskId,
  data,
  attachmentsToRemove,
  applyRecurringChangesFor,
  duplicateTask,
  isBaseTaskRecurring,
}: {
  taskId: string;
  data: Partial<ScheduledTask>;
  attachmentsToRemove?: ScheduledTaskAttachmentDetails[];
  applyRecurringChangesFor?: ApplyRecurringChangesFor;
  duplicateTask?: boolean;
  isBaseTaskRecurring?: boolean;
}) {
  const formData = new FormData();

  const propertiesToAppend = ['description', 'end', 'jobId', 'name', 'start'];

  Object.keys(data).forEach((key) => {
    if (key === 'attachments') {
      data.attachments.forEach((file) => formData.append('attachments', file));
    } else if (key === 'assignedUserIds') {
      formData.append('assignedUserIds', JSON.stringify(data.assignedUserIds));
    } else if (key === 'recurringData') {
      formData.append('recurringData', JSON.stringify(data.recurringData));
    } else if (propertiesToAppend.includes(key)) {
      //const dataItem = data[key] ?? null;
      formData.append(key, data[key] ?? null);
    }
  });

  if (attachmentsToRemove?.length !== 0) {
    formData.append('attachmentsToRemove', JSON.stringify(attachmentsToRemove));
  }
  if (
    applyRecurringChangesFor === ApplyRecurringChangesFor.INDIVIDUAL ||
    applyRecurringChangesFor === ApplyRecurringChangesFor.THISANDFUTURE
  ) {
    formData.append('applyRecurringChangesFor', applyRecurringChangesFor);
  }
  if (!duplicateTask) {
    const res = await Axios.put(`/api/v1/calendar/${taskId}`, formData, {
      headers: { 'content-type': 'multipart/form-data' },
    });
    return res.data;
  } else {
    if (isBaseTaskRecurring) {
      const res = await Axios.post(
        `/api/v1/calendar/duplicate/recurring/${taskId}`,
        formData,
        {
          headers: { 'content-type': 'multipart/form-data' },
        }
      );
      return res.data;
    } else {
      const res = await Axios.post(
        `/api/v1/calendar/duplicate/${taskId}`,
        formData,
        {
          headers: { 'content-type': 'multipart/form-data' },
        }
      );
      return res.data;
    }
  }
}

export async function copyOrMoveScheduleTask({
  taskId,
  date,
  action,
  excludeDate,
}: {
  taskId: string;
  date: Date;
  action: 'move' | 'copy';
  excludeDate?: string;
}) {
  let res;
  if (excludeDate) {
    res = await Axios.post(`/api/v1/calendar/${action}/${taskId}`, {
      date,
      excludeDate,
    });
    console.log(res.data);
  } else {
    res = await Axios.post(`/api/v1/calendar/${action}/${taskId}`, {
      date,
    });
  }
  return res.data;
}

export async function deleteScheduledTask(
  taskId: string,
  individualRecurringDate?: string,
  deleteType?: string
) {
  let res;
  if (individualRecurringDate) {
    res = await Axios.put(`/api/v1/calendar/${taskId}/handleRecurringDelete`, {
      excludeDate: individualRecurringDate,
      deleteType,
    });
  } else {
    res = await Axios.delete(`/api/v1/calendar/${taskId}`);
  }
  return res.data;
}

export async function listAvailableUsers(
  role: Role,
  start: Date,
  end: Date
): Promise<User[]> {
  const res = await Axios.get('/api/v1/calendar/available-users', {
    params: {
      role,
      start: start.toISOString(),
      end: end.toISOString(),
    },
  });
  return res.data;
}

export async function addOrUpdatePinnedCalendarUsers(
  userIds: string[]
): Promise<User[]> {
  const res = await Axios.post('/api/v1/calendar/pinned-users', userIds);
  return res.data;
}

export async function listPinnedCalendarUsers(): Promise<User[]> {
  const res = await Axios.get('/api/v1/calendar/pinned-users');
  return res.data;
}

export async function getTaskAttachmentPreviewLink(
  taskId: string,
  attachmentId: string
) {
  const res = await Axios.get(`/api/v1/calendar/${taskId}/${attachmentId}/url`);
  return res.data;
}

export function concatTwoDates(baseDate: string, setDate: string) {
  const newDate = new Date(baseDate);
  newDate.setDate(new Date(setDate).getDate());
  newDate.setMonth(new Date(setDate).getMonth());
  newDate.setFullYear(new Date(setDate).getFullYear());
  return newDate.toISOString();
}
