import { Button, IconButton } from '@material-ui/core';
import React from 'react';
import useIsMobile from 'theme/useIsMobile';
import RefreshIcon from '@material-ui/icons/Refresh';

const PageRefreshButton = ({ refresh }: { refresh: () => void }) => {
  const isMobile = useIsMobile();

  if (isMobile) {
    return (
      <IconButton color="secondary" size="small" onClick={refresh}>
        <RefreshIcon />
      </IconButton>
    );
  }
  return (
    <Button
      variant="outlined"
      color="secondary"
      size="small"
      onClick={refresh}
      startIcon={<RefreshIcon />}>
      Refresh
    </Button>
  );
};

export default PageRefreshButton;
