import React, { Component, ErrorInfo, ReactNode } from 'react';
import Button from '@mui/material/Button';
import Logo from '../assets/logos/logo-color.png';
import RefreshIcon from '@material-ui/icons/Refresh';
import HomeIcon from '@material-ui/icons/Home';

interface ErrorBoundaryProps {
  children: ReactNode;
}

interface ErrorBoundaryState {
  hasError: boolean;
}
class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = {
      hasError: false,
    };
  }

  static getDerivedStateFromError(_: Error): ErrorBoundaryState {
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    console.error('Error caught by error boundary:', error, errorInfo);
  }

  handleReload = () => {
    window.location.reload();
  };

  render() {
    if (this.state.hasError) {
      return (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            height: '100vh',
            gap: '1rem',
            fontFamily: 'Arial, sans-serif',
            padding: '1rem',
            textAlign: 'center',
          }}>
          <img
            src={Logo}
            alt="Restorize Logo"
            style={{
              width: '220px',
            }}
          />
          <h1>Whoops, something went wrong!</h1>
          <h4>Please either reload the page or go back home to try again.</h4>
          <h4>
            If the issue persists, please{' '}
            <a href="https://restorize.io/contact">contact support</a>.
          </h4>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              gap: 10,
            }}>
            <Button
              variant="contained"
              onClick={this.handleReload}
              startIcon={<RefreshIcon />}>
              Reload
            </Button>
            {/* <Button
              variant="contained"
              onClick={
                () =>
                  this.setState({
                    redirectToHome: true,
                  })
              }
              startIcon={<HomeIcon />}>
              Go Back Home
            </Button> */}
          </div>
        </div>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
