import Axios from 'axios';
import { Role } from 'models/Role';

export interface User {
  firstName?: string;
  lastName?: string;
  email?: string;
  phoneNumber?: string;
  id?: string;
  token?: string;
  organizationId?: string;
  role?: Role;
  dp?: string;
  isOrganizationOwner?: boolean;
  __organization__?: {
    id: number;
    name: string;
  };
  overlaps?: boolean;
}

export interface Profile {
  address?: string;
  dateOfBirth?: string;
  gender?: string;
  title?: string;
  emergencyContactName?: string;
  emergencyContactRelationship?: string;
  emergencyContactNumber?: string;
  bloodType?: string;
  medicalConditions?: string[];
  certifications?: string[];
  vaccinations?: string[];
}

export interface UserWithProfile extends User {
  profile?: Profile;
}

export async function listUsers(): Promise<User[]> {
  const res = await Axios.get('/api/v1/users');
  return res.data.payload || [];
}

export async function addUser(user: User): Promise<User> {
  const res = await Axios.post('/api/v1/users', user);
  return res.data;
}

export async function updateUser({
  uid,
  isCurrentUser,
  ...rest
}: {
  uid: string;
  isCurrentUser?: boolean;
  basicDetails?: User;
  profileDetails?: Profile;
}): Promise<any> {
  const res = await Axios.put(
    `/api/v1/users/${isCurrentUser ? `me/${uid}` : uid}`,
    rest
  );
  return res.data;
}

export async function deleteUser(userID: string) {
  const res = await Axios.delete(`/api/v1/users/soft-delete/${userID}`);
  return res.data;
}

export interface NewRegistration {
  firstName: string;
  lastName: string;
  email: string;
  organizationName: string;
  password: string;
}
export async function newRegistration(registration: NewRegistration): Promise<{
  success: boolean;
  id: string;
  token: string;
}> {
  const res = await Axios.post('/auth/register', registration);
  return res.data;
}

export async function currentUserDetails(): Promise<User> {
  const res = await Axios.get('/api/v1/users/validate');
  return res.data;
}

export async function login(email: string, password: string): Promise<User> {
  const res = await Axios.post('/auth/login', {
    email,
    password,
  });
  return res.data;
}

export async function forgotPassword(email: string) {
  await Axios.post('/auth/forgot-password', { email });
}

export async function validateResetPasswordToken(token: string) {
  const res = await Axios.get(`/auth/reset-password/${token}`);
  return res.data;
}

export async function resetPassword(token: string, newPassword: string) {
  const res = await Axios.post('/auth/reset-password', {
    token,
    password: newPassword,
  });
  return res.data;
}

export async function getUserWithProfile(
  userId: string,
  isCurrentUser: boolean = false
) {
  const res = await Axios.get(
    `/api/v1/users/${isCurrentUser ? 'my/profile' : userId}`
  );
  return res.data;
}

export async function uploadProfilePicture(userId: string, file: any) {
  const formData = new FormData();
  formData.append('image', file);
  const res = await Axios.post(
    `/api/v1/uploads/profile-picture/${userId}`,
    formData,
    {
      headers: {
        'Content-type': 'multipart/form-data',
      },
    }
  );
  return res.data;
}
