import DateFnsUtils from '@date-io/date-fns';
import {
  Box,
  FormControl,
  FormControlLabel,
  InputAdornment,
  MenuItem,
  Select,
  Switch,
  TextField,
  Typography,
  useTheme,
} from '@material-ui/core';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { Alert, Autocomplete } from '@mui/material';
import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { Control, Controller } from 'react-hook-form';
import { RecurrenceSchema } from './AddOrEditScheduledTask';
import { FormData as ScheduledTaskFormData } from './AddOrEditScheduledTask';
import { extractByWeekDaysFromSampleDates } from 'util/timeConversions';

export enum RecurringFrequency {
  YEARLY = 'year',
  MONTHLY = 'month',
  WEEKLY = 'week',
  DAILY = 'day',
}

export enum RecurringEndOn {
  NEVER = 'never',
  ON = 'on',
  AFTER = 'after',
}

export enum RecurringMonthOption {
  DATENUMBER = 'byDateNumber',
  BYMONTHDAY = 'byMonthDay',
}

export type RecurringOptions = {
  isRecurring: boolean;
  interval: number;
  freq: RecurringFrequency;
  monthOption?: RecurringMonthOption;
  byWeekDay?: string[];
  endOption?: RecurringEndOn;
  count?: number;
  endsOn?: Date;
  startsOn?: Date;
  recurringRuleText?: string;
  sampleByWeekDates?: Date[];
  taskTimezone?: string;
};

export const weekDays = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
];

const RecurringTasksOptions = ({
  formValues,
  setRecurringDataObj,
  recurringDataObj,
  isEditing,
  setIsRecurringDataChanged,
  control,
  setValue,
  setRecurringValidate,
}: {
  formValues: ScheduledTaskFormData;
  setRecurringDataObj: React.Dispatch<React.SetStateAction<RecurringOptions>>;
  recurringDataObj: RecurringOptions;
  isEditing: boolean;
  setIsRecurringDataChanged: React.Dispatch<React.SetStateAction<boolean>>;
  control: Control<ScheduledTaskFormData>;
  setValue: (
    name: string,
    value: unknown,
    config?: Partial<{
      shouldValidate: boolean;
      shouldDirty: boolean;
    }>
  ) => void;
  setRecurringValidate: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const theme = useTheme();
  const [initialValues] = useState(recurringDataObj);
  const [recurringValidateErrors, seRecurringValidateErrors] =
    useState<string[]>();
  const [isRecurring, setIsRecurring] = useState(
    recurringDataObj?.isRecurring ?? false
  );
  const [interval, setInterval] = useState(
    isEditing && recurringDataObj?.interval ? recurringDataObj?.interval : 1
  );
  const [freq, setFreq] = useState(
    isEditing && recurringDataObj?.freq ? recurringDataObj?.freq : 'week'
  );
  const [monthOption, setMonthOption] = useState(
    isEditing && recurringDataObj?.monthOption
      ? recurringDataObj?.monthOption
      : 'byDateNumber'
  );
  const [byWeekDay, setByWeekDay] = useState<string[]>(
    isEditing &&
      extractByWeekDaysFromSampleDates(recurringDataObj.sampleByWeekDates)
        ?.length > 0 &&
      recurringDataObj?.freq === RecurringFrequency.WEEKLY
      ? extractByWeekDaysFromSampleDates(recurringDataObj.sampleByWeekDates)
      : isEditing &&
        recurringDataObj?.byWeekDay?.length > 0 &&
        recurringDataObj?.freq === RecurringFrequency.WEEKLY
      ? recurringDataObj?.byWeekDay
      : [weekDays[formValues?.date?.getDay()]]
  );
  const [endOption, setEndOption] = useState(
    isEditing && recurringDataObj?.endOption
      ? recurringDataObj?.endOption
      : 'never'
  );
  const [count, setCount] = useState(
    isEditing && recurringDataObj?.count ? recurringDataObj?.count : 12
  );
  const [endsOn, setEndsOn] = useState<Date>(
    isEditing ? new Date(recurringDataObj?.endsOn) : formValues?.date
  );

  useEffect(() => {
    const obj = {
      isRecurring,
      interval,
      freq,
      monthOption,
      byWeekDay,
      endOption,
      count,
      endsOn,
    };

    const { sampleByWeekDates, ...newInit } = initialValues;
    newInit.endsOn = new Date(initialValues.endsOn);
    setIsRecurringDataChanged(!_.isEqual(newInit, obj));
    try {
      RecurrenceSchema.parse(obj);
      seRecurringValidateErrors(null);
      setRecurringValidate(true);
    } catch (e) {
      seRecurringValidateErrors(e.errors?.map((err) => err.message));
      setRecurringValidate(false);
    }
  }, [
    initialValues,
    isRecurring,
    interval,
    freq,
    monthOption,
    byWeekDay,
    endOption,
    count,
    endsOn,
    setIsRecurringDataChanged,
    setRecurringValidate,
  ]);

  const findDayOccurrence = (customDate) => {
    const day = new Date(customDate);
    return Math.ceil(day.getDate() / 7);
  };

  function getWeekNumberInMonth(date) {
    const weekNumber = findDayOccurrence(date);
    const ordinals = ['first', 'second', 'third', 'fourth'];
    const ordinal = ordinals[weekNumber - 1] || 'last';
    const result = { key: ordinal, value: weekNumber };
    return result;
  }

  return (
    <Box
      padding={2}
      style={{
        backgroundColor: theme.palette.grey[100],
        marginBottom: 20,
        borderRadius: 5,
      }}>
      <Controller
        name="isRecurring"
        control={control}
        defaultValue={recurringDataObj?.isRecurring ?? false}
        render={({ value }) => (
          <FormControlLabel
            control={
              <Switch
                checked={value}
                onChange={(event) => {
                  setIsRecurring(event.target.checked);
                  setRecurringDataObj({
                    ...recurringDataObj,
                    isRecurring: event.target.checked,
                  });
                  setValue('isRecurring', event.target.checked);
                }}
                name="isRecurring"
              />
            }
            label={'Repeats'}
          />
        )}
      />
      {isRecurring && (
        <Box margin={1}>
          {recurringValidateErrors &&
            recurringValidateErrors?.map((err) => (
              <Alert
                severity="error"
                style={{ marginBottom: 5 }}
                variant="standard">
                <Typography variant="caption" style={{ color: 'red' }}>
                  {err}
                </Typography>
              </Alert>
            ))}
          <Box my={2}>
            <Typography variant="body2" style={{ marginBottom: 5 }}>
              Repeat every
            </Typography>
            <Box display="flex" flex={1}>
              <Box flex={1}>
                <Controller
                  name="interval"
                  control={control}
                  defaultValue={
                    isEditing && recurringDataObj?.interval
                      ? recurringDataObj?.interval
                      : 1
                  }
                  render={({ value }) => (
                    <TextField
                      fullWidth
                      variant="outlined"
                      type="number"
                      onChange={(event) => {
                        setInterval(Number(event.target.value));
                        setValue('interval', Number(event.target.value));
                        setRecurringDataObj({
                          ...recurringDataObj,
                          interval: Number(event.target.value),
                        });
                      }}
                      value={value}
                    />
                  )}
                />
              </Box>
              <Box flex={1}>
                <FormControl style={{ marginLeft: 5 }} fullWidth>
                  <Controller
                    name="freq"
                    control={control}
                    defaultValue={
                      isEditing && recurringDataObj?.freq
                        ? recurringDataObj?.freq
                        : 'week'
                    }
                    render={({ value }) => (
                      <Select
                        id="freq"
                        name="freq"
                        variant="outlined"
                        onChange={(event) => {
                          setFreq(event.target.value.toString());
                          setValue('freq', event.target.value.toString());
                          if (event.target.value.toString() === 'week') {
                            setByWeekDay(
                              isEditing &&
                                recurringDataObj?.byWeekDay?.length > 0 &&
                                recurringDataObj?.freq ===
                                  RecurringFrequency.WEEKLY
                                ? recurringDataObj?.byWeekDay
                                : [weekDays[formValues?.date?.getDay()]]
                            );
                          }
                          setRecurringDataObj({
                            ...recurringDataObj,
                            freq: event.target.value.toString() as RecurringFrequency,
                          });
                        }}
                        value={value}>
                        <MenuItem value={RecurringFrequency.DAILY}>
                          day
                        </MenuItem>
                        <MenuItem value={RecurringFrequency.WEEKLY}>
                          week
                        </MenuItem>
                        <MenuItem value={RecurringFrequency.MONTHLY}>
                          month
                        </MenuItem>
                        <MenuItem value={RecurringFrequency.YEARLY}>
                          year
                        </MenuItem>
                      </Select>
                    )}
                  />
                </FormControl>
              </Box>
            </Box>
          </Box>
          {freq === RecurringFrequency.WEEKLY && (
            <Box my={2}>
              <Typography variant="body2" style={{ marginBottom: 5 }}>
                Repeat on
              </Typography>
              <Box flex={1}>
                <Controller
                  name="byWeekDay"
                  control={control}
                  defaultValue={
                    isEditing &&
                    extractByWeekDaysFromSampleDates(
                      recurringDataObj.sampleByWeekDates
                    )?.length > 0 &&
                    recurringDataObj?.freq === RecurringFrequency.WEEKLY
                      ? extractByWeekDaysFromSampleDates(
                          recurringDataObj.sampleByWeekDates
                        )
                      : isEditing &&
                        recurringDataObj?.byWeekDay?.length > 0 &&
                        recurringDataObj?.freq === RecurringFrequency.WEEKLY
                      ? recurringDataObj?.byWeekDay
                      : [weekDays[formValues?.date?.getDay()]]
                  }
                  render={(props) => (
                    <Autocomplete
                      {...props}
                      multiple
                      id="tags-outlined"
                      options={weekDays}
                      getOptionLabel={(option) => option}
                      onChange={(event, newValue) => {
                        setValue('byWeekDay', newValue);
                        setByWeekDay(newValue);
                        setRecurringDataObj({
                          ...recurringDataObj,
                          byWeekDay: newValue,
                        });
                      }}
                      filterSelectedOptions
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          placeholder=""
                          name="byWeekDay"
                        />
                      )}
                    />
                  )}
                />
              </Box>
            </Box>
          )}
          {freq === RecurringFrequency.MONTHLY && (
            <Box flex={1} my={2}>
              <FormControl fullWidth>
                <Controller
                  name="monthOption"
                  control={control}
                  defaultValue={
                    isEditing && recurringDataObj?.monthOption
                      ? recurringDataObj?.monthOption
                      : 'byDateNumber'
                  }
                  render={({ value }) => (
                    <Select
                      id="monthOption"
                      name="monthOption"
                      variant="outlined"
                      onChange={(event) => {
                        setMonthOption(event.target.value.toString());
                        setValue('monthOption', event.target.value.toString());
                        setRecurringDataObj({
                          ...recurringDataObj,
                          monthOption:
                            event.target.value.toString() as RecurringMonthOption,
                        });
                      }}
                      value={value}>
                      <MenuItem value={RecurringMonthOption.DATENUMBER}>
                        {`Monthly on day ${formValues?.date?.getDate()}`}
                      </MenuItem>
                      <MenuItem value={RecurringMonthOption.BYMONTHDAY}>
                        {`Monthly on the ${
                          getWeekNumberInMonth(formValues?.date)?.key
                          //getWeekOfMonth(formValues?.date)
                        } ${weekDays[formValues?.date?.getDay()]}`}
                      </MenuItem>
                    </Select>
                  )}
                />
              </FormControl>
            </Box>
          )}
          <Box my={2}>
            <Typography variant="body2" style={{ marginBottom: 5 }}>
              Ends
            </Typography>
            <Box display="flex" flex={1}>
              <Box flex={1}>
                <FormControl fullWidth>
                  <Controller
                    name="endOption"
                    control={control}
                    defaultValue={
                      isEditing && recurringDataObj?.endOption
                        ? recurringDataObj?.endOption
                        : 'never'
                    }
                    render={({ value }) => (
                      <Select
                        id="endOption"
                        name="endOption"
                        variant="outlined"
                        onChange={(event) => {
                          setEndOption(event.target.value.toString());
                          setValue('endOption', event.target.value.toString());
                          setRecurringDataObj({
                            ...recurringDataObj,
                            endOption:
                              event.target.value.toString() as RecurringEndOn,
                          });
                          if (event.target.value.toString() === 'after') {
                            setCount(
                              isEditing && recurringDataObj?.count
                                ? recurringDataObj?.count
                                : 12
                            );
                          }
                        }}
                        value={value}>
                        <MenuItem value={RecurringEndOn.NEVER}>Never</MenuItem>
                        <MenuItem value={RecurringEndOn.ON}>On</MenuItem>
                        <MenuItem value={RecurringEndOn.AFTER}>After</MenuItem>
                      </Select>
                    )}
                  />
                </FormControl>
              </Box>
              {endOption === RecurringEndOn.AFTER && (
                <Box flex={1}>
                  <Controller
                    name="count"
                    control={control}
                    defaultValue={
                      isEditing && recurringDataObj?.count
                        ? recurringDataObj?.count
                        : 12
                    }
                    render={({ value }) => (
                      <TextField
                        fullWidth
                        variant="outlined"
                        label=""
                        type="number"
                        onChange={(event) => {
                          setCount(Number(event.target.value));
                          setValue('count', Number(event.target.value));
                          setRecurringDataObj({
                            ...recurringDataObj,
                            count: Number(event.target.value),
                          });
                        }}
                        value={value}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              occurrence
                            </InputAdornment>
                          ),
                        }}
                        style={{ marginLeft: 5 }}
                      />
                    )}
                  />
                </Box>
              )}
            </Box>
            {endOption === RecurringEndOn.ON && (
              <Box flex={1} my={2}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <Controller
                    name="endsOn"
                    control={control}
                    defaultValue={
                      isEditing &&
                      recurringDataObj.endOption === RecurringEndOn.ON
                        ? recurringDataObj?.endsOn
                        : formValues?.date
                    }
                    render={({ value }) => (
                      <DatePicker
                        autoOk
                        fullWidth
                        minDate={formValues?.date}
                        orientation="landscape"
                        variant="static"
                        openTo="date"
                        onChange={(value) => {
                          setEndsOn(value);
                          setValue('endsOn', value);
                          setRecurringDataObj({
                            ...recurringDataObj,
                            endsOn: value,
                          });
                        }}
                        value={value}
                      />
                    )}
                  />
                </MuiPickersUtilsProvider>
              </Box>
            )}
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default RecurringTasksOptions;
