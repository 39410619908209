import React, { useContext, useState } from 'react';
import {
  AppBar,
  Toolbar,
  Hidden,
  IconButton,
  Typography,
  Tooltip,
  Chip,
} from '@material-ui/core';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { useQueryClient } from '@tanstack/react-query';
import MenuIcon from '@material-ui/icons/Menu';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import clsx from 'clsx';
import Logo from 'assets/logos/logo-white.png';
import MobileLogo from 'assets/logos/logo-white-mobile.png';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import UserContext from 'state/UserContext';
import ArrowBack from '@material-ui/icons/ArrowBackIos';
import useIsMobile from 'theme/useIsMobile';
import { Badge } from '@mui/material';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';

const useStyles = makeStyles((theme) => ({
  root: {
    boxShadow: 'none',
  },
  toolbar: {
    // position: 'fixed',
    // backgroundColor: theme.palette.primary.main,
    // zIndex: 10000,
    // width: '100%'
  },
  flexGrow: {
    flexGrow: 1,
  },
  logo: {
    height: '28px',
  },
  orgLabel: {
    marginLeft: theme.spacing(3),
    color: 'white',
    borderRadius: 4,
    borderColor: theme.palette.info.light,
  },
  userName: {
    marginRight: theme.spacing(2),
  },
  roleLabel: {
    borderRadius: 4,
    fontSize: '0.7rem',
    backgroundColor: theme.palette.primary.dark,
    color: 'inherit',
  },
  logoLink: {
    display: 'flex',
    alignItems: 'center',
  },
  backBtn: {
    marginRight: theme.spacing(2),
  },
  desktopMenuBtn: {
    marginLeft: theme.spacing(-2),
    marginRight: theme.spacing(2),
  },
}));

interface Props {
  className?: string;
  sidebarIsOpen: boolean;
  onSidebarOpen: () => void;
  onSidebarClose?: () => void;
}

const Topbar = React.memo((props: Props) => {
  const { user, setUser } = useContext(UserContext);
  const { className, sidebarIsOpen, onSidebarOpen, onSidebarClose, ...rest } =
    props;
  const classes = useStyles();
  const isMobile = useIsMobile();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [offline, setOffline] = useState(!navigator.onLine);

  window.addEventListener('offline', () => setOffline(true));
  window.addEventListener('online', () => setOffline(false));

  if (!user) {
    return null;
  }

  const clearCache = () => {
    queryClient.clear();
    localStorage.removeItem('token');
  };

  const logout = () => {
    clearCache();
    setUser(null);
    navigate('/login');
  };

  const goBack = () => {
    navigate(-1);
  };

  return (
    <AppBar {...rest} className={clsx(classes.root, className)}>
      <Toolbar className={classes.toolbar}>
        {isMobile && (
          <IconButton
            size="small"
            color="inherit"
            className={classes.backBtn}
            onClick={goBack}>
            <ArrowBack color="inherit" fontSize="small" />
          </IconButton>
        )}
        <Hidden mdDown>
          <IconButton
            color="inherit"
            onClick={sidebarIsOpen ? onSidebarClose : onSidebarOpen}
            className={classes.desktopMenuBtn}>
            <MenuIcon />
          </IconButton>
        </Hidden>
        <RouterLink to="/" className={classes.logoLink}>
          <img
            alt="Logo"
            className={classes.logo}
            src={isMobile ? MobileLogo : Logo}
          />
        </RouterLink>
        <Chip
          variant="outlined"
          className={classes.orgLabel}
          label={user.__organization__?.name}
        />
        <div className={classes.flexGrow} />
        <Hidden mdDown>
          <Typography
            variant="button"
            color="inherit"
            className={classes.userName}>
            {user.firstName} {user.lastName}
          </Typography>
          <Chip
            label={
              user.role === 'ProjectManager' ? 'Project Coordinator' : user.role
            }
            size="small"
            className={classes.roleLabel}
          />
          {/* <IconButton color="inherit">
            <Badge
              badgeContent={notifications.length}
              color="primary"
              variant="dot">
              <NotificationsIcon />
            </Badge>
          </IconButton> */}
          <Tooltip title="Your Profile">
            <IconButton
              color="inherit"
              onClick={() => navigate(`/users/profile/${user.id}`)}>
              <Badge
                sx={{
                  '& .MuiBadge-badge': {
                    backgroundColor: !offline ? 'lightgreen' : 'red',
                  },
                }}
                badgeContent=" "
                variant="dot">
                <AccountCircleIcon />
              </Badge>
            </IconButton>
          </Tooltip>
          <Tooltip title="Log out">
            <IconButton
              color="inherit"
              onClick={logout}
              data-cy={'log-out-btn'}>
              <ExitToAppIcon />
            </IconButton>
          </Tooltip>
        </Hidden>
        <Hidden lgUp>
          <IconButton
            color="inherit"
            onClick={onSidebarOpen}
            data-cy={'side-bar-icon'}>
            <MenuIcon />
          </IconButton>
        </Hidden>
      </Toolbar>
    </AppBar>
  );
});

export default Topbar;
