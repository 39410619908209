import React, { ReactNode } from 'react';
import {
  Card,
  CardContent,
  Typography,
  makeStyles,
  Grid,
  Avatar,
  CircularProgress,
  Divider,
  LinearProgress,
} from '@material-ui/core';
import { colors } from '@mui/material';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
    borderRadius: '12px',
  },

  metricPrimary: {
    alignItems: 'center',
    display: 'flex',
    paddingRight: 10,
  },

  title: {
    fontWeight: 600,
    textAlign: 'left',
  },

  subTitle: {
    fontWeight: 500,
    textAlign: 'left',
  },

  subMetricsGrid: {
    paddingBottom: theme.spacing(1),
  },

  subMetric: {
    textAlign: 'center',
    fontWeight: 600,
    color: theme.palette.primary.main,
    marginBottom: theme.spacing(0.5),
    marginRight: theme.spacing(1),
    marginLeft: theme.spacing(2),
  },

  subMetricLabel: {
    fontWeight: 400,
    textAlign: 'center',
    marginBottom: theme.spacing(0.5),
    marginRight: theme.spacing(1),
  },

  details: {
    textAlign: 'left',
  },

  avatar: {
    backgroundColor: theme.palette.primary.main,
    height: 56,
    width: 56,
  },

  metricAvatar: {
    backgroundColor: theme.palette.primary.main,
    height: theme.spacing(3),
    width: theme.spacing(3),
    margin: theme.spacing(1),
  },

  icon: {
    height: 32,
    width: 32,
    color: 'white',
  },

  toolTip: {
    marginTop: theme.spacing(-1.5),
    padding: 10,
  },

  infoIcon: {
    height: 20,
    width: 20,
    color: 'white',
  },

  percentageBar: {
    marginTop: theme.spacing(-1),
    marginBottom: theme.spacing(1.5),
    marginLeft: 'auto',
    marginRight: 'auto',
    width: '90%',
  },

  divider: {
    marginTop: theme.spacing(-1),
    marginBottom: theme.spacing(1.5),
    backgroundColor: colors.grey[300],
  },

  accordion: {
    marginBottom: 0,
    marginLeft: 0,
    marginRight: 0,
  },

  expanded: {
    '&$expanded': {
      margin: '4px -10',
    },
  },

  accordionSummary: {
    marginBottom: 0,
    marginTop: 0,
    paddingBottom: 0,
    paddingTop: 0,
    height: '50%',
  },

  difference: {
    marginTop: theme.spacing(2),
    display: 'flex',
    alignItems: 'center',
  },

  differenceIcon: {
    color: theme.palette.success.dark,
  },

  differenceValue: {
    color: theme.palette.success.dark,
    marginRight: theme.spacing(1),
  },
}));

interface Props {
  metric: number | string;
  percentage?: Boolean;
  label?: string;
  description?: string;
  details?: any;
  icon?: JSX.Element;
  customContent?: ReactNode;
  containerStyle?: React.CSSProperties;
}

export const SubMetricComponent = (subMetric: any, label: any) => {
  const classes = useStyles();

  return (
    <Grid
      item
      container
      direction="row"
      justify="flex-start"
      alignItems="center">
      <Avatar variant="rounded" className={classes.metricAvatar}>
        {subMetric?.toString(10)}
      </Avatar>
      <Typography
        className={classes.title}
        color="textSecondary"
        gutterBottom
        variant="body2">
        {label}
      </Typography>
    </Grid>
  );
};

const DetailedMetricCard = ({
  metric,
  percentage,
  label,
  details,
  icon,
  customContent,
  containerStyle,
}: Props) => {
  const classes = useStyles();
  const displayMetric = percentage
    ? `${(Number(metric) * 100).toFixed(2)}%`
    : metric;

  return (
    <Card className={classes.root} style={containerStyle}>
      <CardContent>
        <Grid container justify="space-between" direction="row">
          <Grid item>
            <Grid container direction="column" justify="space-evenly">
              <Grid container direction="row" justify="flex-start">
                <Grid item>
                  <Typography
                    className={classes.title}
                    color="textSecondary"
                    gutterBottom
                    variant="body2">
                    {label}
                  </Typography>
                </Grid>
              </Grid>
              <Grid item>
                {metric || metric === 0 ? (
                  <Typography variant="h3" className={classes.metricPrimary}>
                    {displayMetric}
                  </Typography>
                ) : (
                  <CircularProgress size={20} />
                )}
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Grid item>
              <Avatar className={classes.avatar}>
                {React.cloneElement(icon, { className: classes.icon })}
              </Avatar>
            </Grid>
          </Grid>
        </Grid>
      </CardContent>
      <Grid>
        {percentage && typeof metric === 'number' ? (
          <LinearProgress
            variant="determinate"
            value={metric * 100}
            className={classes.percentageBar}
          />
        ) : (
          <Divider className={classes.divider} />
        )}
        {details && details?.length > 0 && (
          <Grid
            className={classes.subMetricsGrid}
            container
            item
            direction="row"
            alignItems="flex-start"
            justify="space-between">
            {details.map((m) => {
              return (
                <Grid
                  key={m.label}
                  item
                  container
                  direction="row"
                  justify="flex-start"
                  alignItems="center">
                  {/* <Avatar variant="rounded" className={classes.metricAvatar}>
                      {m.subMetric?.toString(10)}
                    </Avatar> */}
                  {m.subMetric >= 0 ? (
                    <Typography
                      className={classes.subMetric}
                      // color="textPrimary"
                      variant="body1">
                      {m.subMetric?.toString(10)}
                    </Typography>
                  ) : (
                    <CircularProgress size={10} />
                  )}
                  <Typography
                    className={classes.subMetricLabel}
                    color="textPrimary"
                    variant="body1">
                    {m.label}
                  </Typography>
                </Grid>
              );
            })}
            {/* <Divider /> */}
          </Grid>
        )}
        {customContent && customContent}
      </Grid>
    </Card>
  );
};

export default DetailedMetricCard;
