import React, { useState } from 'react';
import Alert from '@material-ui/lab/Alert';
import ConfirmDeleteDialog from 'components/ConfirmDeleteDialog/ConfirmDeleteDialog';
import { CalendarAPI } from 'api';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { ScheduledTask } from 'api/jobApi';
import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
} from '@material-ui/core';
import { ApplyRecurringChangesFor } from 'api/calendarApi';
import { useSnackbar } from 'notistack';

interface Props {
  open: boolean;
  task: ScheduledTask;
  handleClose: () => void;
}

const ConfirmTaskDeleteDialog = ({ open, task, handleClose }: Props) => {
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();
  const [applyRecurringChangesFor, setApplyRecurringChangesFor] = useState(
    ApplyRecurringChangesFor.ALL
  );

  const deleteScheduledTaskMutation = async () => {
    if (
      applyRecurringChangesFor !== ApplyRecurringChangesFor.ALL &&
      task.isRecurring
    ) {
      await CalendarAPI.deleteScheduledTask(
        task.id,
        task.start,
        applyRecurringChangesFor
      );
    } else {
      await CalendarAPI.deleteScheduledTask(task.id);
    }
  };

  const { mutate: mutateDelete } = useMutation(deleteScheduledTaskMutation, {
    onSuccess: async () => {
      await queryClient.invalidateQueries(['calendar-events']);
      await queryClient.invalidateQueries(['scheduled-tasks']);
      await queryClient.invalidateQueries(['scheduled-tasks', task.jobId]);
      enqueueSnackbar('Scheduled task successfully deleted ', {
        variant: 'success',
      });
      handleClose();
    },
    onError: (err) => {
      console.error('Failed to delete task:', err);
      enqueueSnackbar('Failed to update scheduled task', {
        variant: 'error',
      });
    },
  });

  return (
    <ConfirmDeleteDialog
      open={open}
      title="Delete Task"
      content={
        <>
          <Typography variant="subtitle2">
            Are you sure you want to remove the task "<b>{task.name}</b>"?
          </Typography>
          {task.isRecurring && (
            <FormControl style={{ marginTop: '1rem' }}>
              <RadioGroup
                aria-labelledby="demo-controlled-radio-buttons-group"
                name="recurringApplyFor"
                value={applyRecurringChangesFor}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  event.preventDefault();
                  setApplyRecurringChangesFor(
                    (event.target as HTMLInputElement)
                      .value as ApplyRecurringChangesFor
                  );
                }}>
                <FormControlLabel
                  value="individual"
                  control={<Radio />}
                  label="This task only"
                />
                <FormControlLabel
                  value="thisAndFuture"
                  control={<Radio />}
                  label="This and following tasks"
                />
                <FormControlLabel
                  value="all"
                  control={<Radio />}
                  label="For all tasks"
                />
              </RadioGroup>
            </FormControl>
          )}
          <Alert severity="warning" style={{ marginTop: '1rem' }}>
            This action is not reversible.
          </Alert>
        </>
      }
      handleClose={handleClose}
      deleteFunc={async () => {
        await mutateDelete();
      }}
      handleReject={handleClose}
    />
  );
};

export default ConfirmTaskDeleteDialog;
