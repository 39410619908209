import React, { useState, useEffect } from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Controller, Control, RegisterOptions } from 'react-hook-form';
import { CalendarAPI } from 'api';
import { DeepMap } from 'react-hook-form/dist/types/utils';
import { Role } from 'models/Role';
import { Chip, useTheme } from '@material-ui/core';
import { User } from 'api/userApi';
import { alpha } from '@mui/material';

interface Props {
  control: Control;
  name: string;
  rules: RegisterOptions;
  errors: DeepMap<any, any>;
  label: string;
  filterRoles?: Array<Role>;
  className?: string;
  disabled?: boolean;
  startDateTime: Date;
  endDateTime: Date;
  role: Role;
}

/** Selects multiple Users (userIds) */
const AvailableUserAutocomplete = ({
  control,
  name,
  rules,
  errors,
  label,
  className,
  startDateTime,
  endDateTime,
  role,
  disabled = false,
}: Props) => {
  const theme = useTheme();
  const [users, setUsers] = useState<User[]>([]);
  const [isFetching, setIsFetching] = useState(false);
  const [enabled, setEnabled] = useState(
    Boolean(startDateTime) && Boolean(endDateTime) && Boolean(role)
  );

  useEffect(() => {
    if (!(startDateTime && endDateTime)) {
      setEnabled(false);
      setUsers([]);
      return;
    }
    setEnabled(true);
    setIsFetching(true);

    CalendarAPI.listAvailableUsers(role, startDateTime, endDateTime)
      .then((users) => {
        setUsers(users);
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => {
        setIsFetching(false);
      });
  }, [startDateTime, endDateTime, role]);

  const [open, setOpen] = useState(false);

  return (
    <Controller
      render={(props) => (
        <Autocomplete
          {...props}
          multiple
          open={open}
          noOptionsText={
            enabled
              ? 'Looks like all your Technicians are busy for the selected time period... Maybe try a different time (Tip: See the Calendar)'
              : 'Select a time period to find all available Technicians'
          }
          openOnFocus
          loadingText="Looking for available users.. Please wait"
          onOpen={() => setOpen(true)}
          onClose={() => setOpen(false)}
          disabled={disabled}
          options={isFetching ? [] : users}
          loading={open && isFetching}
          getOptionLabel={(option) => `${option.firstName} ${option.lastName}`}
          renderOption={(option) => (
            <span>
              {`${option.firstName} ${option.lastName}`}
              {option.overlaps && (
                <Chip
                  variant="outlined"
                  label={'Conflicts with another task'}
                  // color="warning"
                  size="small"
                  style={{
                    marginLeft: 10,
                    color: theme.palette.warning.main,
                    borderColor: theme.palette.warning.main,
                    backgroundColor: alpha(theme.palette.warning.light, 0.2),
                  }}
                />
              )}
            </span>
          )}
          renderTags={(value: User[], getTagProps) =>
            value.map((option: User, index: number) => (
              <Chip
                variant="outlined"
                label={`${option.firstName} ${option.lastName}`}
                {...getTagProps({ index })}
              />
            ))
          }
          getOptionSelected={(option, value) => option.id === value.id}
          renderInput={(params) => (
            <TextField
              {...params}
              label={
                enabled
                  ? label
                  : 'Select a time period above to find all available Technicians'
              }
              variant="outlined"
              className={className}
              error={!!errors[name]}
              helperText={!!errors[name] && errors[name].message}
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    {open && isFetching && (
                      <CircularProgress color="inherit" size={20} />
                    )}
                    {params.InputProps.endAdornment}
                  </>
                ),
              }}
            />
          )}
          onChange={(_, data) => {
            // Returns [] and this fails the 'required' validation
            props.onChange(Boolean(data) ? data : undefined);
          }}
        />
      )}
      name={name}
      rules={rules}
      control={control}
    />
  );
};

export default AvailableUserAutocomplete;
