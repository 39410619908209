import MuiButton from './MuiButton';
import MuiIconButton from './MuiIconButton';
import MuiPaper from './MuiPaper';
import MuiTableCell from './MuiTableCell';
import MuiTypography from './MuiTypography';
import MUIDataTable from './MUIDataTable';
import MUIRichTextEditor from './MUIRichTextEditor';

export default {
  MuiButton,
  MuiIconButton,
  MuiPaper,
  MuiTableCell,
  MuiTypography,
  ...MUIDataTable,
  ...MUIRichTextEditor,
} as const;
