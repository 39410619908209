import { createMuiTheme } from '@material-ui/core';
import palette from '../palette';
import typography from '../typography';
const theme = createMuiTheme();
export default {
  root: {
    ...typography.body1,
    borderBottom: `1px solid ${palette.divider}`,
    [theme.breakpoints.down('sm')]: {
      borderBottom: 'none',
      padding: theme.spacing(1),
    },
  },
  // head: {
  //   backgroundColor: 'black',
  //   color: 'white',
  // }
} as const;
