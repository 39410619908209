import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Card,
  CardContent,
  Typography,
  Breadcrumbs,
  Box,
} from '@material-ui/core';
import Link from '@material-ui/core/Link';
import { Link as RouterLink, useLocation, useNavigate } from 'react-router-dom';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import useIsMobile from 'theme/useIsMobile';

const useStyles = makeStyles((theme) => ({
  root: {
    // sticky:"top",
    // position: 'sticky',
    boxShadow: 'none',
    padding: theme.spacing(0.75),
    paddingTop: theme.spacing(0),
    paddingBottom: theme.spacing(0),
    borderRadius: 0,
    [theme.breakpoints.down('sm')]: {
      padding: 0,
    },
  },
  body: {
    marginTop: theme.spacing(1),
  },
  content: {
    display: 'flex',
    flexDirection: 'row',
  },
  actionArea: {
    alignSelf: 'flex-center',
    marginLeft: 'auto',
  },
  link: {
    display: 'flex',
    textDecoration: 'none',
  },
  icon: {
    marginRight: theme.spacing(0.5),
    width: 20,
    height: 20,
  },
  breadcrumbs: {
    marginBottom: theme.spacing(3),
  },
}));

export interface IBreadcrumbs {
  name?: string;
  path?: string;
  icon?: any;
}

interface Props {
  title: string;
  overline?: string;
  body?: any;
  actions?: any;
  breadcrumbs: IBreadcrumbs[];
}

const LinkRouter = (props: any) => (
  <Link {...props} component={RouterLink as any} />
);

const TitleBar = ({ title, overline, body, actions, breadcrumbs }: Props) => {
  const classes = useStyles();
  const location = useLocation();
  const isMobile = useIsMobile();

  if (isMobile && location.pathname.slice(0, 6) === '/jobs/') {
    return (
      <Card className={classes.root}>
        <CardContent className={classes.content}>
          <Box width="100%">
            {overline && (
              <Typography variant="overline" color="textSecondary" gutterBottom>
                {overline}
              </Typography>
            )}
            <Typography variant="h3" component="h1">
              {title}
            </Typography>
            {body && (
              <Typography
                variant="body2"
                component="p"
                className={classes.body}>
                {body}
              </Typography>
            )}
          </Box>
        </CardContent>
      </Card>
    );
  }

  return (
    <Card className={classes.root}>
      <CardContent className={classes.content}>
        <Box width="100%">
          <Box display="flex" justifyContent="space-between" width="100%">
            <Breadcrumbs
              className={classes.breadcrumbs}
              aria-label="breadcrumb"
              separator={<NavigateNextIcon fontSize="small" />}>
              {breadcrumbs.map((b, i) => {
                if (!b?.path) {
                  return (
                    <Typography
                      key={i}
                      color="textPrimary"
                      className={classes.link}>
                      {b?.icon &&
                        React.cloneElement(b.icon, {
                          className: classes.icon,
                        })}
                      {b.name}
                    </Typography>
                  );
                }
                return (
                  <LinkRouter
                    key={i}
                    color="primary"
                    to={b.path}
                    className={classes.link}>
                    {b?.icon &&
                      React.cloneElement(b.icon, {
                        className: classes.icon,
                      })}
                    {b.name}
                  </LinkRouter>
                );
              })}
            </Breadcrumbs>
            {actions && <div className={classes.actionArea}>{actions}</div>}
          </Box>
          {overline && (
            <Typography variant="overline" color="textSecondary" gutterBottom>
              {overline}
            </Typography>
          )}
          <Typography variant="h3" component="h1">
            {title}
          </Typography>
          {body && (
            <Typography variant="body2" component="p" className={classes.body}>
              {body}
            </Typography>
          )}
        </Box>
      </CardContent>
    </Card>
  );
};

export default TitleBar;
