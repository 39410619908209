import React, { useState, useEffect } from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Controller, Control, RegisterOptions } from 'react-hook-form';
import { useQuery } from '@tanstack/react-query';
import { CalendarAPI, UserAPI } from 'api';
import { DeepMap } from 'react-hook-form/dist/types/utils';
import { Role } from 'models/Role';
import {
  Chip,
  makeStyles,
  Box,
  Typography,
  Paper,
  FormControlLabel,
  Divider,
  Button,
} from '@material-ui/core';
import { User } from 'api/userApi';
import UserAvatar from 'components/Users/UserAvatar';

interface Props {
  control: Control;
  name: string;
  rules: RegisterOptions;
  errors: DeepMap<any, any>;
  label: string;
  filterRoles?: Array<Role>;
  className?: string;
  disabled?: boolean;
  shrink?: boolean;
  onChange?: (users: any[]) => void;
  selectedUsers?: User[];
}

const useStyles = makeStyles((theme) => ({
  option: {
    display: 'flex',
    alignItems: 'center',
    paddingTop: theme.spacing(0.6),
    paddingBottom: theme.spacing(0.6),
  },
  avatar: {
    marginRight: theme.spacing(2),
  },
}));

/** Selects multiple Users (userIds) */
const CalendarMultiUserAutocomplete = ({
  control,
  name,
  rules,
  errors,
  label,
  filterRoles,
  className,
  disabled = false,
  onChange,
  selectedUsers,
}: Props) => {
  const classes = useStyles();
  const { isFetching, data: users } = useQuery(
    ['list-users'],
    UserAPI.listUsers,
    { staleTime: 30 * 60 * 1000 }
  );

  const { data: pinnedUsers, isLoading: isLoadingPinnedUsers } = useQuery(
    ['list-pinned-calendar-users'],
    CalendarAPI.listPinnedCalendarUsers,
    { staleTime: 30 * 60 * 1000 }
  );

  const [open, setOpen] = useState(false);
  const [filteredUsers, setFilteredUsers] = useState([]);

  useEffect(() => {
    if (!users) {
      return;
    }
    if (!filterRoles) {
      setFilteredUsers(users);
      return;
    }
    setFilteredUsers(users?.filter((u) => filterRoles.includes(u.role)));
  }, [users, filterRoles, selectedUsers]);

  useEffect(() => {
    const nonMatching = users?.filter((user) => {
      return !selectedUsers?.some(
        (selectedUser) => selectedUser.id === user.id
      );
    });

    setFilteredUsers(nonMatching);
  }, [selectedUsers, users]);

  if (!isLoadingPinnedUsers) {
    return (
      <Controller
        render={(props) => (
          <Autocomplete
            {...props}
            multiple
            open={open}
            onOpen={() => setOpen(true)}
            onClose={() => setOpen(false)}
            disabled={disabled}
            options={isFetching ? [] : filteredUsers}
            loading={open && isFetching}
            getOptionLabel={(option) =>
              `${option.firstName} ${option.lastName}`
            }
            defaultValue={
              pinnedUsers?.map((u) =>
                users?.find((user) => user.id === u.id)
              ) || []
            }
            renderOption={(option) => (
              <span className={classes.option}>
                <UserAvatar
                  user={option}
                  size={24}
                  fontSize={12}
                  className={classes.avatar}
                />
                <Box display="block">
                  <Typography variant="body1">
                    {option.firstName} {option.lastName}
                  </Typography>
                  <Typography variant="body2">{option.email}</Typography>
                </Box>
              </span>
            )}
            renderTags={(value: User[], getTagProps) =>
              value.map((option: User, index: number) => (
                <Chip
                  variant="outlined"
                  label={`${option.firstName} ${option.lastName}`}
                  avatar={
                    <UserAvatar
                      user={option}
                      size={24}
                      fontSize={12}
                      className={classes.avatar}
                      style={{ color: 'white' }}
                    />
                  }
                  {...getTagProps({ index })}
                />
              ))
            }
            getOptionSelected={(option, value) => option.id === value.id}
            renderInput={(params) => (
              <TextField
                {...params}
                label={label}
                variant="outlined"
                className={className}
                error={!!errors[name]}
                helperText={!!errors[name] && errors[name].message}
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <>
                      {open && isFetching && (
                        <CircularProgress color="inherit" size={20} />
                      )}
                      {params.InputProps.endAdornment}
                    </>
                  ),
                }}
              />
            )}
            onChange={(_, data) => {
              onChange(data);
              props.onChange(data);
            }}
            PaperComponent={(paperProps) => {
              const { children, ...restPaperProps } = paperProps;
              return (
                <Paper {...restPaperProps}>
                  {filteredUsers?.length > 0 && (
                    <Box
                      onMouseDown={(e) => e.preventDefault()} // prevent blur
                      pl={1.5}
                      py={0.5}>
                      <FormControlLabel
                        onClick={(e) => {
                          e.preventDefault();
                          onChange(users);
                          props.onChange(users);
                          setOpen(false);
                        }}
                        label=""
                        control={
                          <Box m={1}>
                            <Button
                              id="select-all-checkbox"
                              variant="outlined"
                              color="secondary">
                              Select all
                            </Button>
                          </Box>
                        }
                      />
                    </Box>
                  )}
                  <Divider />
                  {children}
                </Paper>
              );
            }}
          />
        )}
        name={name}
        rules={rules}
        control={control}
        defaultValue={pinnedUsers || []}
      />
    );
  } else {
    return null;
  }
};

export default CalendarMultiUserAutocomplete;
