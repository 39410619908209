import React, { useContext, useState } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { Link, useLocation } from 'react-router-dom';
import {
  List,
  ListItem,
  Button,
  colors,
  Collapse,
  Hidden,
  Divider,
  IconButton,
  Grid,
  useMediaQuery,
} from '@material-ui/core';
import ArrowBack from '@material-ui/icons/ArrowBackIos';
import { SidebarItem } from './SidebarItems';
import { userHasAccess } from 'models/Role';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import MobileSection from './MobileSection';
import UserContext from 'state/UserContext';
import { Box } from '@mui/material';
import theme from 'theme';

const useStyles = makeStyles((theme) => ({
  item: {
    display: 'flex',
    paddingTop: 0,
    paddingBottom: 0,
  },
  button: {
    color: colors.blueGrey[800],
    padding: '10px 8px',
    justifyContent: 'flex-start',
    textTransform: 'none',
    letterSpacing: 0,
    width: '100%',
    fontWeight: theme.typography.fontWeightMedium,
  },
  backBtn: {
    marginRight: theme.spacing(2),
  },
  endIcon: {
    marginLeft: 'auto',
  },
  icon: {
    color: theme.palette.secondary.light,
    width: 24,
    height: 24,
    display: 'flex',
    alignItems: 'center',
    marginRight: theme.spacing(1),
  },
  nested: {
    marginLeft: '4px',
    borderLeft: '2px solid #f1f1f1',
    paddingLeft: theme.spacing(2),
  },
  active: {
    color: theme.palette.primary.main,
    fontWeight: theme.typography.fontWeightMedium,
    '& $icon': {
      color: theme.palette.primary.main,
    },
  },
  mobileSectionDivider: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  backBtnContainer: {
    marginTop: theme.spacing(-1),
    display: 'flex',
    justifContent: 'flex-end',
  },
}));

interface LinkButtonProps {
  to: string;
  icon: any;
  title: string;
}

export const LinkButton = React.memo(({ to, icon, title }: LinkButtonProps) => {
  const classes = useStyles();
  const { pathname } = useLocation();

  const isMatch = to === '/' ? pathname === to : pathname.startsWith(to);

  return (
    <Link to={to} style={{ flexGrow: 1 }}>
      <Button
        className={clsx({
          [classes.button]: true,
          [classes.active]: isMatch,
        })}>
        <div className={classes.icon}>{icon}</div>
        {title}
      </Button>
    </Link>
  );
});

interface Props {
  items: SidebarItem[];
  onClose: () => void;
  className?: string;
}

const SidebarNav = React.memo(({ items, onClose, className }: Props) => {
  const classes = useStyles();
  const { user } = useContext(UserContext);
  const isMobile = useMediaQuery(theme.breakpoints.down('md'), {
    defaultMatches: true,
  });

  return (
    <List className={className}>
      <Hidden mdDown>
        <Box className={classes.backBtnContainer}>
          <Grid item>
            <IconButton
              size="medium"
              color="inherit"
              className={classes.backBtn}
              onClick={onClose}>
              <ArrowBack color="inherit" fontSize="small" />
            </IconButton>
          </Grid>
        </Box>
        <Divider />
      </Hidden>
      <Box
        sx={{
          height: `${isMobile ? '70vh' : '58vh'}`,
          overflowY: 'auto',
          paddingRight: '1rem',
        }}>
        {items.map((item, i) => {
          if (!userHasAccess(user, item.restrictTo ?? ['Technician'])) {
            return <React.Fragment key={i} />;
          }
          if (item.nestedItems) {
            return <NestedItem key={i} item={item} className={className} />;
          }
          return (
            <ListItem key={i} className={classes.item} disableGutters>
              <LinkButton to={item.href} icon={item.icon} title={item.title} />
            </ListItem>
          );
        })}
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          height: '20vh',
          justifyContent: 'flex-end',
        }}>
        <MobileSection classes={classes} />
      </Box>
    </List>
  );
});

const NestedItem = React.memo(
  ({ item, className }: { item: SidebarItem; className: string }) => {
    const classes = useStyles();
    const [open, setOpen] = useState(item.open);
    return (
      <>
        <Button
          className={classes.button}
          classes={{
            endIcon: classes.endIcon,
          }}
          onClick={() => setOpen((o) => !o)}
          endIcon={open ? <ExpandLess /> : <ExpandMore />}>
          <div className={classes.icon}>{item.icon}</div>
          {item.title}
        </Button>
        <Collapse in={open} unmountOnExit>
          <List className={clsx(classes.nested, className)}>
            {item.nestedItems.map((item) => (
              <ListItem
                className={classes.item}
                disableGutters
                key={item.title}>
                <LinkButton
                  to={item.href}
                  icon={item.icon}
                  title={item.title}
                />
              </ListItem>
            ))}
          </List>
        </Collapse>
      </>
    );
  }
);

export default SidebarNav;
