import { User } from 'api/userApi';

export type Role =
  | 'Administrator'
  | 'ProjectManager'
  | 'Supervisor'
  | 'Technician';

export function userHasAccess(user: User, requiredRoles: Role[]) {
  return requiredRoles.includes(user.role);
}
