import React, { useEffect } from 'react';
import { Snackbar, Button } from '@material-ui/core';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

const ServiceWorkerWrapper = () => {
  const [showReload, setShowReload] = React.useState(false);
  const [waitingWorker, setWaitingWorker] =
    React.useState<ServiceWorker | null>(null);
  const onSWUpdate = (registration: ServiceWorkerRegistration) => {
    console.log('onSWUpdate: called', registration);
    setShowReload(true);
    const waitingServiceWorker = registration.waiting;
    setWaitingWorker(waitingServiceWorker);
  };

  useEffect(() => {
    serviceWorkerRegistration.register({ onUpdate: onSWUpdate });
  }, []);

  const reloadPage = () => {
    waitingWorker?.postMessage({ type: 'SKIP_WAITING' });
    setShowReload(false);
    window.location.reload();
  };

  window.addEventListener('online', (e) => {
    console.log('online --------------------- ', e);
    if (navigator.serviceWorker && navigator.serviceWorker.controller) {
      navigator.serviceWorker.controller.postMessage({
        type: 'Sync-Queue',
      });
    }
  });

  return (
    <Snackbar
      open={showReload}
      message="A new version is available!"
      onClick={reloadPage}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      action={
        <Button color="inherit" size="small" onClick={reloadPage}>
          Update
        </Button>
      }
    />
  );
};

export default ServiceWorkerWrapper;
