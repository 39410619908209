import Axios from 'axios';
import { JobRange } from '../components/Jobs/Graphs/StatusPieChart';
import { StatusValue } from './jobApi';

export interface JobsByCoordinator {
  firstName: string;
  lastName: string;
  [StatusValue.NOT_STARTED]: number;
  [StatusValue.IN_PROGRESS]: number;
  [StatusValue.ON_HOLD]: number;
  [StatusValue.APPROVAL_PENDING]: number;
  [StatusValue.TO_BE_INVOICED]: number;
  [StatusValue.COMPLETED]: number;
  [StatusValue.CANCELLED]: number;
}

export async function fetchOverview(): Promise<any> {
  const res = await Axios.get('/api/v1/overview/dashboard-data');
  return res.data;
}

export async function fetchJobTypesClient(clientId: string): Promise<any> {
  const res = await Axios.get(
    `/api/v1/overview/dashboard-data?clientId=${clientId}`
  );

  return res.data;
}

export async function fetchJobStatusByPeriod(
  clientId: string,
  period: JobRange
) {
  const res = await Axios.get(
    clientId
      ? `/api/v1/overview/dashboard-data?period=${period}&clientId=${clientId}`
      : `/api/v1/overview/dashboard-data?period=${period}`
  );
  console.log(clientId);
  console.log(res.data);

  const jobArray: [] = res.data;
  const formattedJobArray: { id: string; value: number }[] = [];

  jobArray.map((job) => {
    // @ts-ignore
    formattedJobArray.push({ id: job?.id, value: parseInt(job?.value) });
  });
  return formattedJobArray;
}

export async function fetchJobCountByCoordinator(
  startDate: string,
  endDate: string
) {
  const res = await Axios.get(
    `/api/v1/overview/dashboard-data?startDate=${startDate}&endDate=${endDate}`
  );

  return res.data as JobsByCoordinator[]
}
